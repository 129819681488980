import styled from 'styled-components';

import {
  basicDefaults,
  variantDefault,
  variantDestructive,
  variantPrimary,
  disabledOverrides,
} from 'Permafrost/components/ui-foundations/buttons/commonStyles';

import { ControlButtonType } from './ControlButton';

type ControlButtonProps = {
  $buttonType: ControlButtonType;
  $iconSize: string;
};

const buttonStyles = {
  reject: variantDestructive,
  submit: variantPrimary,
  restore: variantPrimary,
  'step-forward': variantDefault,
};

export const iconSizeDefault = '16px';

export const StyledControlButton = styled.button<ControlButtonProps>`
  ${basicDefaults};

  height: calc(${iconSizeDefault} * 2);
  width: ${(props) => `calc(${props.$iconSize} * 2)`};

  // todo: default override
  &[type='button'] {
    padding: 0;
    ${(props) => buttonStyles[props.$buttonType]}
  }

  ${disabledOverrides};
`;
