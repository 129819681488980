import React, { useEffect, useRef } from 'react';
import { withRouter, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import isNull from 'lodash/isNull';
import IdleTimer from 'react-idle-timer';
import * as H from 'history';

import * as ROUTES from 'root/constants/router';

import LoginFailed from 'Auth/pages/LoginFailed/LoginFailed';
import { PageContainer } from 'root/components/PageContainer';
import { useAppStore, StateAndActions as AppStoreStateAndActions } from 'root/store/appStore';
import { useCurrentUser, useAuthLoading } from 'root/hooks/authentication';

type Props = {
  component: any;
  loginError: boolean;
  redirect: { pathname: string; search: string };
  resetRedirect: (location: H.Location) => void;
} & RouteComponentProps;

const appStoreSelector = (state: AppStoreStateAndActions) => ({
  previousLocation: state.previousLocation,
  currentLocation: state.currentLocation,
});

const PrivateRoute = (props: Props) => {
  const { redirect, component: Component, loginError, ...rest } = props;
  const { previousLocation, currentLocation } = useAppStore(appStoreSelector);
  const idleTimer = useRef<any>();
  const currentUser = useCurrentUser();
  const authLoading = useAuthLoading();

  useEffect(() => {
    if (isNull(currentUser)) {
      props.resetRedirect(props.location);
    }
  }, []);

  const handleOnIdle = () => {
    props.history.push(ROUTES.AUTH_LOGOUT);
  };

  const scope = currentUser && currentUser.scopes.map((s) => s.scope.toUpperCase());
  const appAccess = scope && scope.includes('APP_ACCESS');
  if (
    currentLocation?.pathname &&
    previousLocation?.pathname &&
    currentLocation?.pathname !== previousLocation?.pathname &&
    window.indico?.appcuesAccountId
  ) {
    window.Appcues?.page();
    if (currentUser) {
      window.Appcues?.identify(`${currentUser.uuid}`, {
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.name,
      });
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loginError) {
          return (
            <PageContainer module="">
              <LoginFailed />
            </PageContainer>
          );
        } else if (redirect.pathname && isNull(currentUser)) {
          return <Redirect to="/" />;
        } else if (authLoading || !currentUser) {
          return <PageContainer module="" />;
        } else if (!isNull(currentUser) && !appAccess) {
          return <Redirect to={ROUTES.BASE_NO_ACCESS} />;
        } else {
          return (
            <>
              {window.indico.features.inactivityTimeout && currentUser && (
                <IdleTimer
                  ref={idleTimer}
                  timeout={window.indico.featuresConfig.inactivityTimeout || 1000 * 60 * 10}
                  onIdle={handleOnIdle}
                  debounce={250}
                />
              )}
              <Component {...props} />
            </>
          );
        }
      }}
    />
  );
};

export default withRouter(PrivateRoute);
