import styled from 'styled-components';

import { MEDIA_QUERIES } from 'Permafrost/index';

export const StyledCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;

  .tooltip {
    position: absolute;
  }

  .CardContainer__Card {
    height: 100%;
  }

  .gallery-icon--base64ai,
  .gallery-icon--huggingface-logo,
  .gallery-icon--privateai-logo {
    width: 100%;
  }

  .gallery-icon--img {
    max-height: 60px;
  }

  @media ${MEDIA_QUERIES.mediumScreen} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${MEDIA_QUERIES.largeScreen} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
