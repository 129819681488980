import React from 'react';

/**
 * @filename useCombobox.ts
 * Allows access to the internals of a MultiCombobox component. For example, a discrete
 * button to clear all selected values.
 *
 * In your component’s file, import this hook and create a `ref` to the component.
 * Then, pass the ref into this hook.
 *
 * @example
 * const comboboxEl = useRef(null);
 * const { clearValue } = useCombobox(comboboxEl);
 *
 * <MultiCombobox ref={comboboxEl} ... />
 *
 * const handleSubmit = () => {
 *   clearValue();
 * };
 *
 * @see {@link https://react-select.com/props}
 * @see {@link https://github.com/JedWatson/react-select/discussions/4352}
 */
export function useCombobox(comboboxEl: React.RefObject<any>) {
  const clearValue = () => {
    if (comboboxEl?.current) {
      comboboxEl.current.clearValue();
    }
  };

  return { clearValue };
}
