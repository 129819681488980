export function storyTitle(base: any) {
  const capitalize = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1);

  return base
    .split('/')
    .filter(
      (str: string) => str.length > 0 && str !== 'src' && str !== 'packages' && str !== 'components'
    )
    .map((str: string) => {
      if (str.includes('-')) {
        return str
          .split('-')
          .map((s) => capitalize(s))
          .join(' ');
      }

      return capitalize(str);
    })
    .join('/');
}
