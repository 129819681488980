import React, { useState } from 'react';

import classNames from 'classnames';
import { interpolateYlGnBu } from 'd3-scale-chromatic';

import { Tooltip } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledMatrix } from './Matrix.styles';

type MatrixComponentProps = PermafrostComponent & {
  data: {
    matrix: any[];
    labels: string[];
  };
  xAxisLabel: string;
  yAxisLabel: string;
  selectionHandler: (row: any, col: any) => void;
  tooltipPlacement: any;
};

export function Matrix(props: MatrixComponentProps) {
  const [selectedPos, setSelectedPos] = useState<number[]>([-1, -1]);
  const [hovered, setHovered] = useState<number[]>([-1, -1]);

  const handleCellClick = (row: number, col: number) => {
    setSelectedPos([row, col]);

    props.selectionHandler(row, col);
  };

  const onCellHover = (row: number, col: number) => {
    setHovered([row, col]);
  };

  const { className, data, id, tooltipPlacement, xAxisLabel, yAxisLabel } = props;

  return (
    <StyledMatrix className={className} data-cy={props['data-cy']} id={id}>
      <table className="Matrix-Frame" onMouseLeave={() => setHovered([-1, -1])}>
        <tbody>
          <tr className="Matrix-Row">
            <th className="Matrix-Label-Row">&nbsp;</th>
            <th className="Matrix-Axis-X" colSpan={data.labels.length}>
              {xAxisLabel}
            </th>

            <td />
          </tr>

          {data.matrix.map((row, index: number) => {
            const ttKey = `rowLabel${index}`;

            return (
              <tr className="Matrix-Row" key={ttKey}>
                <th className="Matrix-Label-Row">
                  <span
                    data-tip
                    data-for={ttKey}
                    style={{
                      color: `${hovered[0] === index ? '#fff' : ''}`,
                    }}
                  >
                    {data.labels[index]}
                  </span>

                  <Tooltip for={ttKey} place={tooltipPlacement}>
                    <p>{data.labels[index]}</p>
                  </Tooltip>
                </th>

                {row.map((cell: any, rowIndex: number) => {
                  const cellStyles = {
                    backgroundColor: interpolateYlGnBu(cell),
                  };

                  return (
                    <td
                      className={classNames('Matrix-Cell', {
                        selected: index === selectedPos[0] && rowIndex === selectedPos[1],
                      })}
                      style={cellStyles}
                      key={rowIndex}
                      onClick={() => handleCellClick(index, rowIndex)}
                      title={cell}
                      onMouseEnter={() => onCellHover(index, rowIndex)}
                    >
                      <div className="Contents">{cell}</div>
                    </td>
                  );
                })}

                {index === 0 && (
                  <th className="Matrix-Axis-Y" rowSpan={data.labels.length}>
                    <span>{yAxisLabel}</span>
                  </th>
                )}
              </tr>
            );
          })}
          <tr className="Matrix-Row">
            <th className="Matrix-Label-Row">&nbsp;</th>

            {data.labels.map((label: string, index: number) => {
              const ttKey = `colLabel${index}`;

              return (
                <th className="Matrix-Label-Col" key={ttKey}>
                  <span
                    className="rotate"
                    data-tip
                    data-for={ttKey}
                    style={{
                      color: `${hovered[1] === index ? '#fff' : ''}`,
                    }}
                  >
                    <span>{label}</span>
                  </span>

                  <Tooltip for={ttKey} place={tooltipPlacement}>
                    <p>{label}</p>
                  </Tooltip>
                </th>
              );
            })}
            <td />
          </tr>
        </tbody>
      </table>
    </StyledMatrix>
  );
}
