import React from 'react';

import { Section, SectionBody, Shrug } from '@indico-data/permafrost';

import { StyledLoginFailed } from './LoginFailed.styles';

export default function LoginFailed() {
  return (
    <StyledLoginFailed>
      <Section>
        <SectionBody>
          <Shrug
            message="Looks like our system failed to verify you."
            buttonAction={() => window.location.reload()}
            buttonText="Refresh"
          />
        </SectionBody>
      </Section>
    </StyledLoginFailed>
  );
}
