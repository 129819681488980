/**
 * Copies a value to the clipboard using an offscreen element and the document execCommand() method
 * compatible across all modern desktop browsers. https://caniuse.com/#feat=document-execcommand
 * @param str
 */
export const copy = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  let selected;
  let selection;
  if (document.getSelection()) {
    selection = document.getSelection();
    if (selection) {
      selected = selection.rangeCount > 0 && selection.getRangeAt(0);
    }
  }
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected && selection) {
    selection.removeAllRanges();
    selection.addRange(selected);
  }
};
