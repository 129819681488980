import styled from 'styled-components';

import { TYPOGRAPHY } from 'Permafrost/index';

import { inputCommon } from '../fieldsCommon.styles';

export const StyledSearchField = styled.div`
  position: relative;
  background: transparent;

  label {
    position: absolute;
    top: 4px;
    left: 0;
    font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
  }

  input {
    ${inputCommon};

    padding: 5px 5px 5px 25px;
    font-size: ${TYPOGRAPHY.fontSize.base};
    margin-bottom: 0;
  }
`;
