import React from 'react';

import { useListControls } from '../';

import { useListItem } from '../index';

import { Button, Icon } from 'Permafrost/index';

import { StyledListControls } from './ListControls.styles';

/**
 * This component **must** be used inside your custom list component. It uses methods from the
 * `useListItem` Context hook, found at the bottom of the `ListItem` file.
 *
 * Unless `promptBeforeDelete` has been disabled in the parent `ReorderableList` component (from
 * the `listControls` prop), pressing the initial “delete” button is not a destructive action.
 */
export function ListControls() {
  const { hideArrows, hideDelete, hideEdit, promptBeforeDelete } = useListControls();
  const {
    confirmDelete,
    deleteItem,
    dragging,
    editing,
    isFirstItem,
    isLastItem,
    moveItemDown,
    moveItemUp,
    setConfirmDelete,
    setEditing,
  } = useListItem();

  return (
    <StyledListControls>
      {!confirmDelete ? (
        <div className="list-buttons">
          {!hideDelete && (
            <Button
              variant="no-style"
              aria-label="delete item"
              className="delete"
              onClick={promptBeforeDelete ? () => setConfirmDelete(true) : deleteItem}
            >
              <Icon name="trash" size={[18]} />
            </Button>
          )}

          {!hideEdit && (
            <Button
              variant="no-style"
              aria-label="edit item"
              className="edit"
              onClick={() => setEditing(true)}
              disabled={editing}
            >
              <Icon name="edit" size={[18]} />
            </Button>
          )}

          {!hideArrows && (
            <div className="move-buttons-container">
              <Button
                variant="no-style"
                aria-label="move item up"
                className="button-move-up"
                disabled={isFirstItem}
                onClick={moveItemUp}
              >
                <Icon name="fa-arrow-up" size={[22]} />
              </Button>

              <Button
                variant="no-style"
                aria-label="move item down"
                className="button-move-down"
                disabled={isLastItem}
                onClick={moveItemDown}
              >
                <Icon name="fa-arrow-down" size={[22]} />
              </Button>
            </div>
          )}
        </div>
      ) : (
        // only shows if `promptBeforeDelete` has NOT been disabled
        <div className="confirm-delete-container">
          <p>Are you sure?</p>

          <Button
            className="button-confirm-delete"
            onClick={deleteItem}
            style={{ opacity: dragging ? '0.5' : '' }}
          >
            Yes, Delete It
          </Button>

          <Button
            className="button-cancel-delete"
            onClick={() => setConfirmDelete(false)}
            style={{ opacity: dragging ? '0.5' : '' }}
          >
            No, Keep It
          </Button>
        </div>
      )}
    </StyledListControls>
  );
}
