import styled from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledValidationText = styled.p`
  height: 17px;
  margin-top: 10px;
  font-size: ${TYPOGRAPHY.fontSize.base};

  &.prompt {
    color: ${COLORS.fieldPromptInfo};
  }

  &.error {
    color: ${COLORS.fieldPromptError};
  }
`;
