import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledSectionBody } from './SectionBody.styles';

export type SectionBodyProps = PermafrostComponent & {
  style?: object;
  children: React.ReactNode;
};

export const SectionBody = (props: SectionBodyProps) => {
  const { className, style = {}, children, id, ...restOfProps } = props;

  return (
    <StyledSectionBody
      className={classNames('SectionBody', className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      {...restOfProps}
    >
      {children}
    </StyledSectionBody>
  );
};
