import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';
import { Flag, COLORS } from 'Permafrost/index';

import { StyledSectionBlock } from './SectionBlock.styles';

type Props = PermafrostComponent & {
  title?: string;
  style?: object;
  children: React.ReactNode;
  flagText?: string;
  flagColor?: keyof typeof COLORS;
  flagMessage?: string;
};

export const SectionBlock = (props: Props) => {
  const {
    title,
    className,
    children,
    id,
    style = {},
    flagText,
    flagMessage,
    ...restOfProps
  } = props;

  return (
    <StyledSectionBlock
      className={classNames('SectionBlock', className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      {...restOfProps}
    >
      {title ? (
        <header className="SectionBlock--header" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="header-container">
            <h3>{title}</h3>
            {flagText ? <Flag text={flagText} /> : null}
          </div>
          {flagMessage ? <p className="SectionBlock__flag-message">{flagMessage}</p> : null}
        </header>
      ) : null}
      {children}
      <hr className="section-bottom-border" />
    </StyledSectionBlock>
  );
};
