/**
 * @param obj
 * @param converter
 * @returns The same object with all keys converted with `convert`
 */
export function convertKeys<T, U>(obj: T, converter: (key: string) => string): U {
  if (typeof obj !== 'object' || obj === null) {
    return (obj as unknown) as U;
  }

  if (Array.isArray(obj)) {
    return (obj.map((item) => convertKeys<T, U>(item, converter)) as unknown) as U;
  }

  const convertedObj: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const convertedKey: string = converter(key);
      const value: any = obj[key];
      convertedObj[convertedKey] = convertKeys<T, U>(value, converter);
    }
  }

  return (convertedObj as unknown) as U;
}
/**
 * @param obj
 * @param keyToOmit
 * @returns The same object without the key `keyToOmit`, at any level of nesting
 */
export function deepOmitKey<T, U>(obj: T, keyToOmit: string): U {
  if (typeof obj !== 'object' || obj === null) {
    return (obj as unknown) as U;
  }

  if (Array.isArray(obj)) {
    return (obj.map((item) => deepOmitKey(item, keyToOmit)) as unknown) as U;
  }

  const newObj: any = {};
  for (const k in obj) {
    if (k !== keyToOmit && Object.prototype.hasOwnProperty.call(obj, k)) {
      newObj[k] = deepOmitKey(obj[k], keyToOmit);
    }
  }

  return newObj;
}
