import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledCard = styled.div`
  border: solid 1px ${COLORS.comet};
  border-radius: 4px;
  box-sizing: content-box;

  &,
  .Card--header-container,
  .Card--content-container,
  .Card--header-container .header {
    display: flex;
    flex-direction: column;
  }

  .Card--header-container {
    align-items: center;
    background: ${COLORS.oxfordBlue};
    border-radius: 2px 2px 0 0;
    padding: 0 30px;
    position: relative;
    width: 100%;

    .header {
      color: ${COLORS.midFontColor};
      height: 80px;
      width: 100%;
      max-width: 150px;
      margin: 0;
      justify-content: center;
      align-items: center;
    }

    .kabob {
      position: absolute;
      right: 2px;
      top: -2px;
    }
  }

  .Card--content-container {
    height: 100%;
    justify-content: space-between;
    padding: 10px;

    .title {
      color: ${COLORS.white};
      font-size: 18px;
    }

    .desc {
      margin-top: 10px;
      word-break: break-word;
    }

    .footer {
      color: ${COLORS.midFontColor};
      display: flex;
      font-size: 12px;
      margin-top: 20px;
    }
  }

  &.selectable {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }

  &.selected {
    border: solid 2px ${COLORS.fieldPromptInfo};
    margin: -1px;
  }

  &.disabled {
    opacity: 0.5;
  }
`;
