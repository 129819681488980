import styled from 'styled-components';

export const StyledRadioGroup = styled.div`
  &.horizontal {
    display: flex;

    > * {
      margin-right: 10px;
    }
  }
`;
