import { createGlobalStyle } from 'styled-components';

export const Media = createGlobalStyle`  
  figure {
    margin: 0;
  }

  img,
  picture {
    margin: 0;
    max-width: 100%;
  }
`;
