const DEFAULT_API_HOST = 'https://dev-ci.us-east-2.indico-dev.indico.io';

export const isDevEnv = () => {
  const env = process.env.NODE_ENV;
  return env !== 'production' && env !== 'test';
};

export const isTestEnv = () => {
  const env = process.env.NODE_ENV;
  return env === 'test';
};

export const getApiHost = () => {
  if (isDevEnv()) {
    return process.env.API_HOST || DEFAULT_API_HOST;
  } else {
    return window.location.origin;
  }
};
