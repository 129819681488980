import styled from 'styled-components';

import { colorUtils } from '@indico-data/utils';

import { allColors as COLORS } from 'Permafrost/constants/colors';

const navWidthOpen = '240px';
const navWidthClosed = '50px';

export const StyledToast = styled.div`
  height: 50px;
  width: 100%;
  position: fixed;
  top: 0;
  transition: transform 250ms, background 250ms;
  transform: translateY(-100%);
  background: transparent;
  z-index: 1000;
  text-align: center;
  display: table;

  span {
    display: table-cell;
    vertical-align: middle;
  }

  &.sidebarOpen {
    padding-left: ${navWidthOpen};
  }

  &.sidebarClosed {
    padding-left: ${navWidthClosed};
  }

  &.show {
    transition: transform 250ms, background 250ms;
    transform: translateY(0);

    span {
      transition: opacity 250ms;
      opacity: 1;
    }
  }

  &.general {
    color: ${COLORS.darkFontColor};
    background: ${COLORS.lightGray};
  }

  &.success {
    color: ${COLORS.pakistanGreen};
    background: ${colorUtils.tint(COLORS.green, 75)};
  }

  &.error {
    color: ${COLORS.fireEngineRed};
    background: ${colorUtils.tint(COLORS.red, 75)};
  }

  &.hide {
    background: transparent;

    span {
      transition: opacity 250ms;
      opacity: 0;
    }
  }
`;
