import React from 'react';

const Bar = (props) => {
  const {
    className = '',
    xPos,
    yPos,
    width,
    height,
    id,
    toolTipKey = null,
    mouseEnterHandler = () => null,
    mouseLeaveHandler = () => null,
    mouseMoveHandler = () => null,
    contextMenuHandler = () => null,
  } = props;

  return (
    <rect
      className={`Bar ${className}`}
      fill="currentColor"
      x={xPos}
      y={yPos}
      width={width}
      height={height}
      id={id}
      data-cy={props['data-cy']}
      data-tip={toolTipKey !== null}
      data-for={toolTipKey}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onMouseMove={mouseMoveHandler}
      onContextMenu={contextMenuHandler}
    />
  );
};

export default Bar;
