import React from 'react';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledBarSpinner } from './BarSpinner.styles';

type Props = PermafrostComponent & {
  width?: string;
};

export function BarSpinner(props: Props): React.ReactElement {
  const { className, id, width = '100%' } = props;

  return (
    <StyledBarSpinner className={className} data-cy={props['data-cy']} id={id} style={{ width }}>
      <span />
    </StyledBarSpinner>
  );
}
