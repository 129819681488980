import { css } from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const inputCommon = css`
  background-color: transparent;
  color: ${COLORS.defaultFontColor};

  border-radius: 0;
  border: 0 solid currentColor;
  border-bottom-width: 1px;

  &:focus,
  &:hover,
  &.filled {
    border-color: ${COLORS.lightFontColor};
  }

  &:required {
    border-bottom-color: ${COLORS.curiousBlue};

    + label:after {
      content: '*';
      margin-left: 4px;
    }
  }

  &:disabled {
    border-style: dotted;
    border-bottom-color: ${COLORS.lightGray};
    cursor: not-allowed;

    &:hover {
    }
  }
`;

export const labelCommon = css`
  font-size: ${TYPOGRAPHY.fontSize.base};
  font-weight: bold;
  color: ${COLORS.lightFontColor};
`;

export const fieldInfoCommon = css`
  margin-top: 13px;
  font-size: ${TYPOGRAPHY.fontSize.subheadSmall};

  .prompt {
    color: ${COLORS.curiousBlue};
  }

  .error {
    color: ${COLORS.red};
  }
`;

export const placeholderCommon = css`
  &::placeholder,
  &::-webkit-input-placeholder {
    opacity: 0.42;
  }
`;
