export const breakpoints = {
  mediumScreen: '600px',
  largeScreen: '900px',
  maxScreen: '1300px',
} as const;

export const mediaQueries = {
  mediumScreen: `only screen and (min-width: ${breakpoints.mediumScreen})`,
  largeScreen: `only screen and (min-width: ${breakpoints.largeScreen})`,
  maxScreen: `only screen and (min-width: ${breakpoints.maxScreen})`,
};
