import styled from 'styled-components';

import { ANIMATION, COLORS, SPACING } from 'Permafrost/index';

const boxSize = '17px';

export const StyledCheckbox = styled.label<{ $color: string; $checkmarkColor: string }>`
  color: ${(props) => props.$color};
  display: flex;
  position: relative;
  width: fit-content;

  &.disabled {
    cursor: not-allowed;
  }

  &.inline {
    display: inline-block;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover,
    &.checked .checkboxSquare {
      color: ${(props) => props.$color};
    }
  }

  &.checked .checkboxSquare {
    fill: ${(props) => props.$color};
  }

  transition: color ${ANIMATION.duration} ${ANIMATION.timing};

  .checkbox {
    width: ${boxSize};
    height: ${boxSize};

    margin-top: 2px;
  }

  .checkbox__text {
    margin-left: ${SPACING.sm};
  }
  .checkboxSquare {
    stroke-width: 2.5;
  }

  .checkmark {
    color: ${(props) => props.$checkmarkColor};
    pointer-events: none;
  }

  .focusRing {
    stroke: ${COLORS.curiousBlue};
    stroke-width: 2;
  }
`;
