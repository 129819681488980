import React from 'react';

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggingStyle,
  DragStart,
  Droppable,
  DroppableProvided,
  DropResult,
} from '@hello-pangea/dnd';

import { v4 as uuid } from 'uuid';

import { arrayUtils } from '@indico-data/utils';

import { StyledLi } from './DragDropVerticalList.styles';

type Props = {
  /**
   * Array of item ids, associated with `items`
   */
  itemIdList: string[];
  /**
   * Array of React components
   */
  items: React.ReactNode[];
  /**
   * Visual gap between items in the list
   */
  itemsVerticalGap?: string;
  listStyleClass?: string;
  /**
   * Restricts motion of dragged item to Y axis only
   */
  lockAxis?: boolean;
  /**
   * Handle array of reordered listIds
   */
  onDrop(itemIdList: string[]): void;
  updateDraggedItem?(itemId: string): void;
};

const droppableId = uuid();

/**
 * General-purpose drag-and-drop list component. Pass in an array of React components along with
 * an array of id strings associated with each component, and an `<ul>` containing re-sortable
 * `<li>`s is created.
 *
 * Avoid margin styling in the components passed into `items`, otherwise the drag/drop motion can
 * get janky. Instead, use the `itemsVerticalGap` prop to add a margin-bottom to each item.
 *
 * Want to target your item while it’s being dragged? Pass a SetStateAction down from the parent;
 * see Storybook stories for an example implementation.
 */
export function DragDropVerticalList(props: Props) {
  const {
    itemIdList,
    items,
    itemsVerticalGap,
    listStyleClass,
    lockAxis,
    onDrop,
    updateDraggedItem,
  } = props;

  const handleDragEnd = (result: DropResult) => {
    if (result.destination) {
      updateDraggedItem && updateDraggedItem('');

      const items = arrayUtils.reorderItem(
        itemIdList,
        result.source.index,
        result.destination.index
      );

      onDrop(items);
    }
  };

  const handleDragStart = (initial: DragStart) => {
    updateDraggedItem && updateDraggedItem(itemIdList[initial.source.index]);
  };

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided: DroppableProvided) => (
          <ul
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={listStyleClass}
            style={{ marginBottom: itemsVerticalGap && `-${itemsVerticalGap}` }}
          >
            {items.map((item, i) => {
              const id = `${droppableId}-${i}`;

              return (
                <Draggable key={id} draggableId={id} index={i}>
                  {(provided: DraggableProvided) => (
                    <StyledLi
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getStyle(provided.draggableProps.style, itemsVerticalGap, lockAxis)}
                      $lockAxis={lockAxis}
                    >
                      {item}
                    </StyledLi>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
}

/**
 * Patches `react-beautiful-dnd` styles to optionally incorporate a horizontal axis lock for the
 * dragged element, and/or add a bottom margin to list items (if specified)
 *
 * ref: https://github.com/atlassian/react-beautiful-dnd/issues/538#issuecomment-741848961
 */
function getStyle(
  style: Partial<DraggingStyle> | undefined,
  itemsMargin?: string,
  lockAxis?: boolean
) {
  const styleProps = { ...style, marginBottom: itemsMargin };

  if (lockAxis && styleProps?.transform) {
    const axisLock = `translate(0px, ${styleProps.transform.split(',').pop()}`;

    return {
      ...styleProps,
      transform: axisLock,
    };
  }

  return styleProps;
}
