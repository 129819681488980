import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

const paddingRight = '30px';

export const StyledPagination = styled.div`
  width: 100%;
  position: relative;

  .Pagination--stats {
    display: inline-block;
    padding: 10px 0;
    line-height: 1.6;
  }

  .Pagination--button-group {
    display: flex;
    justify-content: flex-end;
    width: calc(25% + ${paddingRight});
    padding: 10px ${paddingRight} 10px 0;
    position: absolute;
    top: 0;
    right: 0;

    > * {
      display: inline-block;
      font-size: 18px;
      vertical-align: middle;
      margin-right: 8px;
    }

    button {
      width: auto;
      padding: 0;
      display: inline-block;
      background: none;
      box-shadow: none;
      text-decoration: underline;
      color: ${COLORS.midFontColor};

      &:hover {
        color: ${COLORS.defaultFontColor};
      }

      &.Pagination--non-click {
        text-decoration: none;
      }
    }
  }
`;
