import { createGlobalStyle } from 'styled-components';
import { MARGINS, PADDINGS } from '../../constants';

export const MarginPadding = createGlobalStyle`  
  .pt-md {
    padding-top: ${PADDINGS.md};
  }
  .pt-sm {
    padding-top: ${PADDINGS.sm};
  }
  .pb-md {
    padding-bottom: ${PADDINGS.md};
  }
  .pb-sm {
    padding-bottom: ${PADDINGS.sm};
  }
  .mt-md {
    margin-top: ${MARGINS.md};
  }
  .mt-sm {
    margin-top: ${MARGINS.sm};
  }
  .mb-md {
    margin-bottom: ${MARGINS.md};
  }
  .mb-sm {
    margin-bottom: ${MARGINS.sm};
  }
  .my-md {
    margin-top: ${MARGINS.md};
    margin-bottom: ${MARGINS.md};
  }
`;
