import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledTag = styled.div`
  align-items: center;
  border: 1px solid ${COLORS.comet};
  border-radius: 4px;
  display: inline-flex;
  padding: 0 10px;

  &.selected {
    background: ${COLORS.blueBayoux};
  }

  &,
  .value-select,
  .remove-btn,
  .value-select:hover,
  .remove-btn:hover,
  .value-select:focus,
  .remove-btn:focus {
    color: ${COLORS.defaultFontColor};
    background: transparent;
    box-shadow: none;
    font-size: 16px;
  }

  .value-select,
  .remove-btn {
    padding: 0 !important;
  }

  .remove-btn svg {
    height: 12px;
    margin-left: 15px;
    width: 12px;
  }
`;
