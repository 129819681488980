import 'whatwg-fetch';
import merge from 'lodash/merge';
import get from 'lodash/get';

const headers = () => {
  return Object.assign({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

export const fetchAPI = (endpoint, options = {}, domain, formMultiPart = false) => {
  const fetchConfig = merge(
    {},
    {
      method: 'GET',
      headers: headers(),
      credentials: 'include',
    },
    options
  );

  // In order for multipart form boundries to work, we must let the browser set this header
  if (formMultiPart) {
    delete fetchConfig.headers['Content-Type'];
  }

  return fetch(`${domain}${endpoint}`, fetchConfig)
    .catch((error) => Promise.reject('Server Unavailable:', error))
    .then((response) => {
      if (response.status === 204) {
        // Do not attempt to parse the response for
        // no content responses
        return response;
      }
      const contentType = response.headers.get('Content-Type') || '';
      const isJSON = contentType.indexOf('application/json') > -1;
      const promise = isJSON ? response.json() : response.blob();

      return promise.then((payload) => {
        // Need to pass in url when a 401 is returned mainly because there is app logic
        // around redirecting the user back to where they were originally trying to go
        // after they login
        if (response.status === 401) {
          return Promise.reject({
            status: response.status,
            url: window.location.href,
            error_type: payload.error_type,
          });
        } else if ([400, 403, 404, 500, 504, 413].indexOf(response.status) > -1) {
          /*
            400: bad request
            403: forbidden (we know who you are, but you don't have permission)
            401: bad authentication or missing auth
          */
          // eslint-disable-next-line no-console
          console.log('payload:', payload);
          // eslint-disable-next-line no-console
          console.log('response', response);
          return Promise.reject({
            status: response.status,
            error_type: payload.error_type,
            message: payload.message,
          });
        }

        if (response.ok) {
          return payload;
        } else {
          return Promise.reject('Error:', payload);
        }
      });
    });
};

// Auth API
const authAPI = (endpoint, options) => {
  return fetchAPI(endpoint, options, get(window, 'indico.appURLs.AuthURL'));
};
export const getAuthJSON = authAPI;
export const postAuthJSON = (endpoint, XSRFToken, body = {}) => {
  return authAPI(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'X-XSRFToken': XSRFToken,
    },
  });
};
export const putAuthJSON = (endpoint, body = {}) => {
  return authAPI(endpoint, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
};
export const deleteAuthJSON = (endpoint, XSRFToken) => {
  return authAPI(endpoint, {
    method: 'DELETE',
    headers: {
      'X-XSRFToken': XSRFToken,
    },
  });
};
export const getCurrentUser = () => {
  return authAPI('/users/details');
};

// Sharknado
const cycloneAPI = (endpoint, options) => {
  return fetchAPI(endpoint, options, get(window, 'indico.appURLs.CycloneURL'));
};

const cycloneMultiPartAPI = (endpoint, options) => {
  return fetchAPI(endpoint, options, get(window, 'indico.appURLs.StorageURL'), true);
};

export const getCycloneJSON = cycloneAPI;

export const postCycloneJSON = (endpoint, body = {}) => {
  return cycloneAPI(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export const postCycloneFile = (endpoint, fileObj) => {
  return Promise.all(
    fileObj.map((file) => {
      const formData = new FormData();
      formData.append('file0', file);
      return cycloneMultiPartAPI(endpoint, {
        method: 'POST',
        body: formData,
        fileFailureMeta: file,
      });
    })
  ).then((r) => {
    return r.reduce((arr, fileArr) => {
      arr.push(...fileArr);
      return arr;
    }, []);
  });
};

export const putCycloneJSON = (endpoint, body = {}) => {
  return cycloneAPI(endpoint, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
};

export const deleteCycloneJSON = (endpoint) => {
  return cycloneAPI(endpoint, {
    method: 'DELETE',
  });
};

// Moonbow
const moonbowAPI = (endpoint, options) => {
  return fetchAPI(endpoint, options, get(window, 'indico.appURLs.MoonbowURL'));
};
export const getMoonbowJSON = moonbowAPI;

export const postMoonbowJSON = (endpoint, body = {}) => {
  return moonbowAPI(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};
export const putMoonbowJSON = (endpoint, body = {}) => {
  return moonbowAPI(endpoint, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
};
export const deleteMoonbowJSON = (endpoint) => {
  return moonbowAPI(endpoint, {
    method: 'DELETE',
  });
};

// Crowdlabel
const crowdlabelAPI = (endpoint, options) => {
  return fetchAPI(endpoint, options, get(window, 'indico.appURLs.CrowdlabelURL'));
};

export const getCrowdlabelJSON = crowdlabelAPI;

export const postCrowdlabelJSON = (endpoint, body = {}) => {
  return crowdlabelAPI(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export const putCrowdlabelJSON = (endpoint, body = {}) => {
  return crowdlabelAPI(endpoint, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
};

export const deleteCrowdlabelJSON = (endpoint) => {
  return crowdlabelAPI(endpoint, {
    method: 'DELETE',
  });
};
