import { useState } from 'react';

/**
 * Stores item heights, and returns necessary values for swap animations. When two items of
 * different heights are exchanging position (i.e. “swapping”), each item needs to move the distance
 * equal to the height of the item it is replacing.
 */
export function useItemSwap() {
  const [itemHeights, setItemHeights] = useState<any>(new Map());
  const [swapAnimations, setSwapAnimations] = useState<any>(new Map());

  const handleSetSwapAnimations = (
    sourceId: string,
    destinationId: string,
    direction: 'up' | 'down'
  ) => {
    const destinationHeight = itemHeights.get(destinationId);
    const sourceHeight = itemHeights.get(sourceId);
    const destinationDirection = direction === 'up' ? 'down' : 'up';

    setSwapAnimations(
      new Map([
        [sourceId, { distance: destinationHeight, direction: direction }],
        [destinationId, { distance: sourceHeight, direction: destinationDirection }],
      ])
    );
  };

  const clearSwapAnimations = () => {
    setSwapAnimations(new Map());
  };

  const addItemHeight = (height: number, id: string) => {
    if (height && !Number.isNaN(height)) {
      setItemHeights((prevState: any) => new Map(prevState.set(id, height)));
    }
  };

  return {
    itemHeights,
    addItemHeight,
    clearSwapAnimations,
    setSwapAnimations: handleSetSwapAnimations,
    swapAnimations,
  };
}
