import { createGlobalStyle } from 'styled-components';

import { colorUtils } from '@indico-data/utils';

import { COLORS, TYPOGRAPHY } from '../../constants';

export const Tables = createGlobalStyle`
  table {
    border-collapse: collapse;
    margin: ${TYPOGRAPHY.spacing.half} 0;
    table-layout: fixed;
    width: 100%;
  }

  th {
    font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
    border-bottom: 0.5px solid ${colorUtils.hexToRgb(COLORS.defaultFontColor, 0.1)};
    padding: 20px 15px 5px;
    font-weight: 400;
    text-align: left;
  }

  td {
    border-bottom: 0.5px solid ${colorUtils.hexToRgb(COLORS.defaultFontColor, 0.1)};
    padding: 20px 15px;
    font-size: ${TYPOGRAPHY.fontSize.base};
  }

  tr,
  td,
  th {
    vertical-align: middle;
  }
`;
