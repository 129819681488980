import styled from 'styled-components';
import { COLORS, SPACING, TYPOGRAPHY } from 'Permafrost/constants';

export const StyledFilter = styled.div<{ $placement?: 'left' | 'right' }>`
  display: inline-block;
  position: relative;
  margin-right: 10px;

  .filter__toggle {
    &.filters-on {
      background-color: ${COLORS.midFontColor};
      color: ${COLORS.lightFontColor};

      &:hover {
        background-color: ${COLORS.midFontColor};
        color: ${COLORS.lightFontColor};
      }
    }

    .swatch-circle {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${COLORS.lightFontColor};
      top: -3px;
      right: -3px;
    }
  }

  .filter__container {
    width: 275px;
    background-color: ${COLORS.ebony};
    border: 1px solid ${COLORS.midFontColor};
    color: ${COLORS.midFontColor};
    position: absolute;
    top: 45px;
    ${({ $placement }) => ($placement === 'left' ? 'right: 0;' : 'left: 0;')}
    display: none;
    z-index: 1;

    &:before {
      content: '';
      border-bottom: 10px solid ${COLORS.midFontColor};
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      top: -11px;
      ${({ $placement }) => ($placement === 'left' ? 'right: 7px;' : 'left: 7px;')}
    }

    &:after {
      content: '';
      border-bottom: 9px solid ${COLORS.ebony};
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      position: absolute;
      top: -9px;
      ${({ $placement }) => ($placement === 'left' ? 'right: 8px;' : 'left: 8px;')}
    }
  }

  .visible {
    display: block;
  }

  .filter__options {
    padding: 10px 0 10px 15px;
  }

  .filter__title,
  .filter__option:not(:last-child) {
    margin-bottom: 8px;
  }

  .filter__option {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.selected {
      color: ${COLORS.lightFontColor};
    }
  }

  .filter__clear-filter {
    border-bottom: 1px solid ${COLORS.midFontColor};
    padding: 10px 10px 10px 15px;
    font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
    margin-right: ${SPACING.xs};

    &:hover {
      cursor: pointer;
    }
  }

  .filter__icon {
    margin-left: ${SPACING.xs};
  }

  .filter__check {
    margin-right: ${SPACING.xs};
  }
`;
