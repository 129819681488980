export const mix = function (color_1: string, color_2: string, weight: number): string {
  function d2h(d: number) {
    return d.toString(16);
  } // convert a decimal value to hex
  function h2d(h: string) {
    return parseInt(h, 16);
  } // convert a hex value to decimal
  function s2l(hex: string) {
    return `${hex}${hex}`;
  }

  weight = typeof weight !== 'undefined' ? weight : 50; // set the weight to 50%, if that argument is omitted
  color_1 = color_1.replace(/#/g, '');
  color_2 = color_2.replace(/#/g, '');

  // check if colors are shorthand or longhand - covert to longhand
  color_1 = color_1.length === 3 ? s2l(color_1) : color_1;
  color_2 = color_2.length === 3 ? s2l(color_2) : color_2;

  let color = '#';

  for (let i = 0; i <= 5; i += 2) {
    // loop through each of the 3 hex pairs—red, green, and blue
    const v1 = h2d(color_1.substr(i, 2)); // extract the current pairs
    const v2 = h2d(color_2.substr(i, 2));
    // combine the current pairs from each source color, according to the specified weight
    let val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

    while (val.length < 2) {
      val = '0' + val;
    } // prepend a '0' if val results in a single digit

    color += val; // concatenate val to our new color string
  }

  return color; // PROFIT!
};

export const shade = (color: string, percentage: number): string => {
  return mix('#000000', color, percentage);
};

export const tint = (color: string, percentage: number): string => {
  return mix('#ffffff', color, percentage);
};

/**
 * Converts hex color values to rgb or rgba values if a opacity is supplied
 * @param hex
 * @returns {*}
 */
export const hexToRgb = (hex: string, opacity?: number): string | undefined => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result && opacity) {
    return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
      result[3],
      16
    )}, ${opacity})`;
  } else if (result) {
    return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
      result[3],
      16
    )})`;
  }
  return undefined;
};

/**
 * Takes a 3- or 6-character hex color value, and returns an object containing
 * its equivalent HSL values.
 *
 * @see {@link https://css-tricks.com/converting-color-spaces-in-javascript/}
 * @see {@link https://gist.github.com/mjackson/5311256}
 */
export function hexToHsl(hexColor: string): { hue: number; saturation: number; lightness: number } {
  // convert to 6-character hex string, if necessary
  const fullHexColor = (() => {
    if (hexColor.length === 4) {
      return (
        '#' +
        hexColor
          .replace('#', '')
          .split('')
          .reduce((acc, char) => (acc += char + char), '')
      );
    }

    return hexColor;
  })();

  const rgbValues = {
    red: parseInt(fullHexColor.slice(1, 3), 16) / 255,
    green: parseInt(fullHexColor.slice(3, 5), 16) / 255,
    blue: parseInt(fullHexColor.slice(-2), 16) / 255,
  };

  const allValues = Object.values(rgbValues);

  const channelMax = Math.max(...allValues);
  const channelMin = Math.min(...allValues);
  const channelDelta = channelMax - channelMin;

  const hslValues = {
    hue: 0,
    saturation: 0,
    lightness: (channelMax + channelMin) / 2,
  };

  if (channelDelta !== 0) {
    // calculate hue
    if (channelMax === rgbValues.red) {
      hslValues.hue = ((rgbValues.green - rgbValues.blue) / channelDelta) % 6;
    } else if (channelMax === rgbValues.green) {
      hslValues.hue = (rgbValues.blue - rgbValues.red) / channelDelta + 2;
    } else {
      hslValues.hue = (rgbValues.red - rgbValues.green) / channelDelta + 4;
    }

    // calculate saturation
    hslValues.saturation = channelDelta / (1 - Math.abs(2 * hslValues.lightness - 1));
  }

  hslValues.hue = Math.round(hslValues.hue * 60);
  hslValues.saturation = +(hslValues.saturation * 100).toFixed(0);
  hslValues.lightness = +(hslValues.lightness * 100).toFixed(0);

  // make negative hues positive
  if (hslValues.hue < 0) {
    hslValues.hue += 360;
  }

  return hslValues;
}
