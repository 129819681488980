import { useContext, useState, useEffect } from 'react';

import { getXSRFToken } from 'Auth/actions/auth-actions';

import UserContext from 'root/store/userContext';

export type Scope =
  | 'celery_flower'
  | 'manage_users'
  | 'graphiql'
  | 'app_access'
  | 'manage_dataset'
  | 'manage_all_data'
  | 'base'
  | 'refresh_token'
  | 'change_password'
  | 'confirm_account'
  | 'user_information'
  | 'metrics';

// This is the basic User object found in gql
export type CurrentUser = {
  account_locked_at: number | null;
  active: boolean;
  confirmed_at: number;
  email: string;
  id: number;
  name: string;
  registered_at: number;
  scopes: { active: boolean; id: number; scope: Scope }[];
  uuid: string;
};

export const useCurrentUser = (): CurrentUser => {
  return useContext(UserContext)?.[0].user || null;
};

export const useAuthLoading = (): boolean => {
  return useContext(UserContext)?.[0].loading;
};

export const useXSRFToken = (callback?: (token: string) => void) => {
  const [token, setToken] = useState<string>('');

  // On component mount, a request is made for the XSRFToken
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    getXSRFToken(signal).then((token: string) => {
      setToken(token);
      if (callback) {
        callback(token);
      }
    });

    return () => {
      abortController.abort();
    };
  }, []);

  return token;
};
