import React from 'react';
import classNames from 'classnames';

import { isFunction } from 'lodash';

import { CardProps, KabobMenu, Tooltip } from 'Permafrost/index';

import { StyledCard } from './Card.styles';

export const Card = (card: CardProps) => {
  const {
    id,
    className,
    description,
    disabled,
    disabledTooltip,
    footer,
    header,
    kabobMenuOptions,
    selectable,
    style,
    title,
  } = card;
  return (
    <StyledCard
      id={id}
      className={classNames(className, {
        disabled: disabled === true,
        selectable: isFunction(selectable?.onSelect) === true, // TODO: confirm this check is sufficient here.
        selected: selectable?.selected === true,
      })}
      data-tip
      data-for={id}
      data-tip-disable={!disabled}
      onClick={() => {
        if (!disabled) selectable?.onSelect();
      }}
      style={style}
      data-testid={card['data-testid']}
    >
      {disabledTooltip ? <Tooltip for={id}>{disabledTooltip}</Tooltip> : null}
      <header className="Card--header-container">
        <div className="header">{header}</div>
        {kabobMenuOptions ? (
          <KabobMenu
            id={`${id}-kabob-menu`}
            className="kabob"
            disabled={disabled}
            menuOptions={kabobMenuOptions}
          />
        ) : null}
      </header>
      <div className="Card--content-container">
        <div className="body-container">
          <header>
            <span className="title">{title}</span>
          </header>
          {description ? <p className="desc">{description}</p> : null}
        </div>
        {footer ? <div className="footer">{footer}</div> : null}
      </div>
    </StyledCard>
  );
};
