import React, { useState, useEffect } from 'react';
import MESSAGES from './random-messages';

export const RandomLoadingMessage = () => {
  const [randomMessage, changeRandomMessage] = useState<string>(
    MESSAGES[Math.floor(Math.random() * MESSAGES.length)]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      changeRandomMessage(MESSAGES[Math.floor(Math.random() * MESSAGES.length)]);
    }, 3000);

    return () => clearTimeout(timer);
  });

  return <span className="message">{randomMessage}...</span>;
};
