import React from 'react';

/**
 * Adds custom props to a react-select component
 *
 * @example
 * type Props = ComboboxProps & {
 *   multiValueProps: {
 *     'data-cy': string;
 *   };
 *
 * <StyledMultiCombobox
 *   ...
 *   components={{
 *    MultiValue: addCustomProps(MultiValue, { ...multiValueProps }),
 *    ...
 *
 * @see {@link https://gist.github.com/MikaelCarpenter/1fe226967b00eea82e6a4760b244ada1}
 */
export const addCustomProps = (Component: any, customProps: { [key: string]: string }) => (
  props: any
) => {
  return (
    <Component {...props} innerProps={Object.assign({}, props.innerProps, { ...customProps })} />
  );
};
