import React from 'react';
import classNames from 'classnames';

import { Button, IconButton, PermafrostComponent } from 'Permafrost/index';

import { StyledTag } from './Tag.styles';

type Props = {
  disabled?: boolean;
  selected?: boolean;
  value: string;
  onClick?: () => void;
  onRemove: () => void;
} & PermafrostComponent;

export const Tag = (props: Props) => {
  const { disabled, selected, value, onClick, onRemove } = props;

  return (
    <StyledTag
      className={classNames('Tag--container', { selected })}
      data-testid={props['data-testid']}
    >
      {onClick ? (
        <Button className="value-select" onClick={onClick}>
          {value}
        </Button>
      ) : (
        <>{value}</>
      )}
      <IconButton
        isDisabled={disabled}
        className="remove-btn"
        iconName="x-close"
        onPress={onRemove}
      />
    </StyledTag>
  );
};
