import React from 'react';

import { Button } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledSaveCancel } from './SaveCancel.styles';

type Props = PermafrostComponent & {
  disableSave?: boolean;
  handleSave(): void;
  handleCancel(): void;
};

export function SaveCancel(props: Props) {
  const { className, id, disableSave, handleCancel, handleSave } = props;

  return (
    <StyledSaveCancel className={className} data-cy={props['data-cy']} id={id}>
      <hr />

      <div>
        <Button onClick={handleSave} variant="cta" className="saveButton" disabled={disableSave}>
          Save
        </Button>

        <Button onClick={handleCancel} variant="link-style" className="cancelButton">
          Cancel
        </Button>
      </div>
    </StyledSaveCancel>
  );
}
