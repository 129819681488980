import React from 'react';

import { CardProps, Tooltip } from 'Permafrost/index';

import { Card } from '../Card/Card';

import { StyledCardContainer } from './CardContainer.styles';

type Props = {
  cards: CardProps[];
};

export const CardContainer = (props: Props) => {
  const { cards } = props;
  return (
    <StyledCardContainer>
      {cards?.map((card) => (
        <div key={card.id}>
          {card.disabledTooltip ? (
            <Tooltip className="tooltip" for={card.id}>
              {card.disabledTooltip}
            </Tooltip>
          ) : null}
          <Card
            id={card.id}
            className={`CardContainer__Card ${card?.className}`}
            description={card?.description || ''}
            disabled={card?.disabled}
            footer={card?.footer}
            header={card?.header}
            kabobMenuOptions={card?.kabobMenuOptions}
            selectable={card?.selectable}
            style={card?.style}
            title={card.title}
            data-testid={`card-${card.id}`}
          />
        </div>
      ))}
    </StyledCardContainer>
  );
};
