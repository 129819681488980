import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

import { colorUtils } from '@indico-data/utils';

// see: https://github.com/reactjs/react-modal/issues/603
function ModalAdapter(props: any): React.ReactElement {
  useEffect(() => {
    ReactModal.setAppElement(props.node || '#root');
  }, [props.node]);

  const { className } = props;
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ReactModal
      {...props}
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      closeTimeoutMS={120}
    >
      {props.children}
    </ReactModal>
  );
}

export const StyledModalBase = styled(ModalAdapter)`
  .${(props) => props.className}__content {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);

    overflow: visible;
    padding: 0;

    border-radius: 4px;
    background-color: ${COLORS.clay};
    border: solid 1px ${COLORS.oxfordBlue};
    outline: none;

    width: ${(props) => (props.maxWidth ? '100%' : 'auto')};
    max-width: ${(props) => props.maxWidth || 'none'};
  }

  .${(props) => props.className}__overlay {
    z-index: 900;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: ${colorUtils.hexToRgb(COLORS.mirage, 0.95)};
  }

  .ReactModal__Overlay {
    opacity: 0;

    transition: opacity 120ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    position: fixed;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;
