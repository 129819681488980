import React from 'react';

import { numberUtils } from '@indico-data/utils';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledPagination } from './Pagination.styles';

type Props = PermafrostComponent & {
  currentPage: number;
  limit: number;
  pageInfo: {
    aggregateCount: number;
    endCursor: number;
    hasNextPage: boolean;
    startCursor: number;
  };
  quantity: number;
  skip?: number;
  getPage(startCursor: number, endCursor: number): void;
  getNextPage(cursor: number): void;
  getPreviousPage(cursor: number): void;
};

export function Pagination(props: Props) {
  //   renderPreviousPages = (currentPage, lastPage) => {
  //     if (currentPage === lastPage - 2) {
  //       return null
  //     }
  //     // this.props.getPage(endCursor, 1)
  //     if (currentPage === lastPage - 1) {
  //       return <>{lastPage - 2 >= 1 && <button onClick={() => this.props.getBeforePage(startCursor, )}>{lastPage - 2}</button>}</>
  //     }

  //     if (currentPage === lastPage) {
  //       return (
  //         <>
  //           {lastPage - 2 >= 1 && <button>{lastPage - 2}</button>}
  //           {lastPage - 1 >= 1 && <button>{lastPage - 1}</button>}
  //         </>
  //       )
  //     }
  //   }

  const { aggregateCount, hasNextPage, endCursor, startCursor } = props.pageInfo;
  const { currentPage, limit, quantity } = props;
  const lastPage = Math.ceil(aggregateCount / limit);
  const currentItemNumber = (currentPage - 1) * limit + 1;

  return (
    <StyledPagination className={props.className} data-cy={props['data-cy']} id={props.id}>
      <div className="Pagination--stats">
        <p>
          Showing{' '}
          {aggregateCount === 0 ? (
            <></>
          ) : (
            <>
              {currentItemNumber} - {currentItemNumber + quantity - 1} of{' '}
            </>
          )}
          {numberUtils.numberWithCommas(aggregateCount)} entries
        </p>
      </div>
      {aggregateCount > limit && (
        <div className="Pagination--button-group" data-cy="Pagination--button-group">
          <p data-cy="pagination--label">Page:&nbsp;</p>
          {currentPage > 1 && (
            <button onClick={() => props.getPreviousPage(startCursor)}>Prev</button>
          )}
          {/* {currentPage >= lastPage - 2 && this.renderPreviousPages(currentPage, lastPage)} */}
          <p data-cy="pagination--current-page">{currentPage}</p>
          {lastPage > currentPage + 1 && (
            <button
              onClick={() => props.getPage(endCursor, 1)}
              data-cy="pagination--second-page-number"
            >
              {currentPage + 1}
            </button>
          )}
          {lastPage > currentPage + 2 && (
            <button
              onClick={() => props.getPage(endCursor, 2)}
              data-cy="pagination--third-page-number"
            >
              {currentPage + 2}
            </button>
          )}
          {lastPage > currentPage + 3 && (
            <button onClick={() => props.getPage(endCursor, 3)}>{currentPage + 3}</button>
          )}
          {lastPage - (currentPage + 2) === 3 && (
            <button onClick={() => props.getPage(endCursor, 4)}>{currentPage + 4}</button>
          )}
          {lastPage - (currentPage + 2) > 2 && lastPage - (currentPage + 2) !== 3 && <p>...</p>}
          {lastPage !== currentPage && (
            <button
              onClick={() => props.getPage(endCursor, lastPage - currentPage)}
              data-cy="pagination--last-page"
            >
              {lastPage}
            </button>
          )}
          {hasNextPage && (
            <button
              onClick={() => props.getNextPage(endCursor)}
              data-cy="pagination--next-page-number"
            >
              Next
            </button>
          )}
        </div>
      )}
    </StyledPagination>
  );
}
