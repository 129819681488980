import styled from 'styled-components';

import { ANIMATION, COLORS, SPACING, AbstractRadioGroup } from 'Permafrost/index';

const radioSize = '20px';
const strokeWidth = 2;

export const StyledRadioGroup = styled(AbstractRadioGroup)`
  .groupLabel {
    margin-bottom: ${SPACING.sm};
    font-size: 16px;
    color: ${COLORS.lightGray};
  }

  // this is the Radio component; something gets cranky and styles end up
  // not applied if we create a separate StyledComponent for Radio.
  label {
    display: flex;
    align-items: center;

    margin-bottom: ${SPACING.sm};

    &.disabled {
      cursor: not-allowed;
    }

    &:not(.disabled) {
      cursor: pointer;

      &.selected,
      &:hover {
        color: ${COLORS.white};
      }
    }

    transition: color ${ANIMATION.duration} ${ANIMATION.timing};

    svg {
      width: ${radioSize};
      height: ${radioSize};

      margin-top: 1px;
      margin-right: ${SPACING.sm};
    }

    .radioCircle {
      stroke-width: ${strokeWidth};
    }

    .radioDot {
      opacity: 0;
    }

    .focusRing {
      opacity: 0;

      stroke: ${COLORS.curiousBlue};
      stroke-width: ${strokeWidth};
    }

    &.selected {
      .radioDot {
        opacity: 1;
      }
    }

    &.focused {
      .focusRing {
        opacity: 1;
      }
    }
  }
`;
