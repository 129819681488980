import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import * as KeyCode from 'keycode-js';

import { Icon, LoadingSpinner } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';
import { ButtonSize } from 'Permafrost/components/ui-foundations/buttons/types';

import { StyledButton, StyledMenu, StyledMenuButton } from './MenuButton.styles';

/** `value` is what is returned to the handler method; `text` is what the user sees */
type Option = { value: string; text: string };

type Props = PermafrostComponent & {
  handleSelection(option: string): void;
  disabled?: boolean;
  open?: boolean;
  options: Option[];
  processing?: string | boolean;
  size?: ButtonSize;
  title: string;
};

/**
 * Technically a Menu and Menu Button, this component offers a list
 * of actions for the user to choose from.
 *
 * This component should not be used for navigation.
 *
 * https://www.w3.org/TR/wai-aria-practices-1.1/#menubutton
 */
export function MenuButton(props: Props): React.ReactElement {
  const { className, disabled, id, open, options, processing, size = 'normal', title } = props;

  const [isOpen, setIsOpen] = useState<boolean>(open || false);

  const buttonEl = useRef<HTMLButtonElement>(null);
  const menuEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let menuItems: NodeListOf<HTMLButtonElement>;

    const handleKeyPress = (ev: KeyboardEvent) => {
      if (!menuItems) return null;

      const activeElement = document.activeElement as HTMLElement;
      const firstItem = menuItems[0];
      const lastItem = menuItems[menuItems.length - 1];
      const nextSibling = activeElement.nextElementSibling as HTMLElement;
      const previousSibling = activeElement.previousElementSibling as HTMLElement;

      // up and down arrow keys will cycle through the different options
      if (ev.key === KeyCode.VALUE_DOWN) {
        if (activeElement !== lastItem) {
          nextSibling.focus();
        } else {
          firstItem.focus();
        }
      } else if (ev.key === KeyCode.VALUE_UP) {
        if (activeElement !== firstItem) {
          previousSibling.focus();
        } else {
          lastItem.focus();
        }
      }

      if (ev.key === KeyCode.VALUE_ESCAPE) {
        closeMenu();
      }
    };

    // once the menu opens, any click should close it
    const handleMenuOpenClick = () => {
      closeMenu();
    };

    if (isOpen) {
      // add click event listener after the current event loop completes to avoid closing the menu immediately
      setTimeout(() => {
        window.addEventListener('click', handleMenuOpenClick);
      }, 0);

      window.addEventListener('keydown', handleKeyPress);

      if (menuEl.current) {
        menuItems = menuEl.current.querySelectorAll('button');
        menuItems[0].focus();
      }
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('click', handleMenuOpenClick);

      buttonEl.current?.focus();
    };
  }, [isOpen]);

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    isOpen ? closeMenu() : openMenu();
  };

  const handleOptionClick = (option: string) => {
    props.handleSelection(option);
  };

  const processingText = typeof processing === 'string' ? processing : 'Processing';

  return (
    <StyledMenuButton className={className} data-cy={props['data-cy']} id={id}>
      <StyledButton
        ref={buttonEl}
        type="button"
        aria-haspopup={true}
        aria-expanded={isOpen}
        className={classNames(processing, size)}
        disabled={disabled}
        onClick={toggleMenu}
      >
        {processing && <LoadingSpinner />}

        <span data-cy="menuTitleSpan" className="menuButtonTitle" aria-live="polite">
          {!!processing ? processingText : title}
        </span>

        {!processing && (
          <span className="caret">
            <Icon name="fa-caret-down" size={['1.2em']} />
          </span>
        )}
      </StyledButton>

      <StyledMenu ref={menuEl} role="menu" className={isOpen ? 'open' : ''}>
        {options.map((option) => (
          <button
            data-cy="menuItemBtn"
            key={option.value}
            type="button"
            role="menuitem"
            tabIndex={-1} // arrow keys are handling focus instead
            onClick={() => handleOptionClick(option.value)}
          >
            {option.text}
          </button>
        ))}
      </StyledMenu>
    </StyledMenuButton>
  );
}
