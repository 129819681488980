import styled from 'styled-components';

import { colorProperties } from 'Permafrost/index';

export const StyledLogo = styled.svg`
  .indico,
  .logomark {
    transition: fill 0.2s;
  }

  .indico {
    fill: var(${colorProperties.logoPrimary});
  }

  .logomark,
  .data {
    fill: var(${colorProperties.logoSecondary});
  }

  &:hover {
    //  .indico {
    //    fill: var(--logo-color-secondary);
    //  }
    //
    .logomark {
      fill: var(${colorProperties.logoPrimary});
    }
  }
`;
