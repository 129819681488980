import React from 'react';

import { dateUtils } from '@indico-data/utils';

type Props = {
  date: Date;
};

export const DateTimeDisplay = ({ date }: Props) => {
  const dateParts = dateUtils.getDateParts(date);

  if (!dateParts) return null;

  return (
    <div>
      {dateParts.month}-{dateParts.day}-{dateParts.year}&nbsp;
      <wbr />
      {dateParts.hour}:{dateParts.minute}
      {dateParts.dayPeriod}
    </div>
  );
};
