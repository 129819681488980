import React from 'react';

import { components } from 'react-select';

import { Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';
import { addCustomProps } from 'Permafrost/components/ui-foundations/dropdowns/utils';

import { StyledSingleCombobox } from './SingleCombobox.styles';

import type { ComboboxProps, ComboboxOption } from '../types';

type Props = PermafrostComponent &
  ComboboxProps & {
    dropdownIndicatorProps?: {
      'data-cy': string;
    };
    optionProps?: {
      'data-cy': string;
    };
    validationErrors?: string[];
    value?: ComboboxOption;
    onChange: (selectedOption: ComboboxOption) => void;
  };

/**
 * Combobox component to select a single option. If selections are not bound to
 * outside state via the `value` prop, you will need to import and use the
 * `useCombobox` hook in order to clear its selected value.
 *
 * @see [useCombobox]{@link import('useCombobox').useCombobox}
 */
export const SingleCombobox = React.forwardRef((props: Props, ref: any) => {
  const {
    className,
    closeMenuOnSelect,
    customOptionLabel,
    customOptionValue,
    defaultValue,
    disabled,
    dropdownIndicatorProps = {},
    id,
    menuIsOpen,
    noOptionsMessage,
    onChange,
    optionProps = {},
    options,
    placeholder,
    validationErrors,
    value,
  } = props;

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon name="fa-caret-down" ariaLabel="open dropdown" size={[14]} />
      </components.DropdownIndicator>
    );
  };

  const Option = (props: any) => {
    return <components.Option {...props} {...optionProps} />;
  };

  const ComboboxComponent = () => (
    <StyledSingleCombobox
      aria-label={props['aria-label']}
      aria-labelledby={props['aria-labelledby']}
      ref={ref}
      className={className}
      classNamePrefix="combobox"
      closeMenuOnSelect={closeMenuOnSelect}
      components={{
        DropdownIndicator: addCustomProps(DropdownIndicator, dropdownIndicatorProps),
        Option: addCustomProps(Option, optionProps),
      }}
      defaultValue={defaultValue}
      getOptionLabel={customOptionLabel}
      getOptionValue={customOptionValue}
      id={id}
      isClearable={false}
      isDisabled={disabled}
      isMulti={false}
      menuIsOpen={menuIsOpen}
      noOptionsMessage={() => noOptionsMessage}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      validationErrors={validationErrors}
      value={value}
    />
  );

  if (props['data-cy']) {
    return (
      <div data-cy={props['data-cy']}>
        <ComboboxComponent />
      </div>
    );
  }

  return <ComboboxComponent />;
});
