import React, { useState } from 'react';

import { PermafrostComponent } from 'Permafrost/types';

type Props = PermafrostComponent & {
  fill?: string;
  hoverEffect?: boolean;
  path: string;
  stroke?: string;
  strokeWidth?: number;
  onClick?(event: React.MouseEvent): void;
};

export function Line(props: Props) {
  const {
    className,
    fill = 'none',
    hoverEffect = true,
    id,
    onClick,
    path,
    stroke = 'white',
    strokeWidth = 1,
  } = props;

  const [hovered, setHovered] = useState<boolean>(false);

  const mouseOverHandler = () => {
    setHovered(true);
  };

  const mouseOutHandler = () => {
    setHovered(false);
  };

  return (
    <path
      d={path}
      fill={fill}
      stroke={stroke}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      strokeWidth={hovered && hoverEffect && onClick ? strokeWidth + 2 : strokeWidth}
      className={className}
      data-cy={props['data-cy']}
      id={id}
      onClick={onClick}
      onMouseEnter={mouseOverHandler}
      onMouseLeave={mouseOutHandler}
    />
  );
}
