import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledSection } from './Section.styles';

type Props = PermafrostComponent & {
  style?: object;
  children: React.ReactNode;
};

export const Section = (props: Props) => {
  const { className, style = {}, children, id, ...restOfProps } = props;

  return (
    <StyledSection
      className={classNames('Section', className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      {...restOfProps}
    >
      {children}
    </StyledSection>
  );
};
