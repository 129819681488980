import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faDotCircle,
  faFile,
  faFileAlt,
  faFilePdf,
  faFileWord,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';

import {
  faAngleDoubleLeft,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckCircle,
  faCircleNotch,
  faClipboard,
  faClock,
  faClone,
  faCloud,
  faCog,
  faCogs,
  faCoins,
  faCube,
  faCut,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEyeDropper,
  faFileDownload,
  faFileExport,
  faFileUpload,
  faFilter,
  faGhost,
  faHatWizard,
  faInfoCircle,
  faKey,
  faKeyboard,
  faLayerGroup,
  faPencilAlt,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faRobot,
  faSearch,
  faSignOutAlt,
  faSlidersH,
  faSyncAlt,
  faTag,
  faTimes,
  faTimesCircle,
  faTint,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTrashAlt,
  faUndo,
  faUser,
  faUsers,
  faMousePointer,
  faStepForward,
  faSearchMinus,
  faSearchPlus,
  faTable,
  faImage,
  faLink,
  faList,
} from '@fortawesome/free-solid-svg-icons';

const faIcons = {
  'fa-dot-circle': generateSvg(faDotCircle),
  'fa-file': generateSvg(faFile),
  'fa-file-alt': generateSvg(faFileAlt),
  'fa-file-pdf': generateSvg(faFilePdf),
  'fa-file-word': generateSvg(faFileWord),
  'fa-check-square': generateSvg(faCheckSquare),
  'fa-square': generateSvg(faSquare),

  'fa-angle-double-left': generateSvg(faAngleDoubleLeft),
  'fa-arrow-circle-right': generateSvg(faArrowCircleRight),
  'fa-arrow-down': generateSvg(faArrowDown),
  'fa-arrow-left': generateSvg(faArrowLeft),
  'fa-arrow-right': generateSvg(faArrowRight),
  'fa-arrow-up': generateSvg(faArrowUp),
  'fa-ban': generateSvg(faBan),
  'fa-caret-down': generateSvg(faCaretDown),
  'fa-caret-up': generateSvg(faCaretUp),
  'fa-chart-bar': generateSvg(faChartBar),
  'fa-check': generateSvg(faCheck),
  'fa-check-circle': generateSvg(faCheckCircle),
  'fa-circle-notch': generateSvg(faCircleNotch),
  'fa-clipboard': generateSvg(faClipboard),
  'fa-clock': generateSvg(faClock),
  'fa-clone': generateSvg(faClone),
  'fa-cloud': generateSvg(faCloud),
  'fa-cog': generateSvg(faCog),
  'fa-cogs': generateSvg(faCogs),
  'fa-coins': generateSvg(faCoins),
  'fa-cube': generateSvg(faCube),
  'fa-cut': generateSvg(faCut),
  'fa-download': generateSvg(faDownload),
  'fa-exclamation-circle': generateSvg(faExclamationCircle),
  'fa-exclamation-triangle': generateSvg(faExclamationTriangle),
  'fa-external-link-alt': generateSvg(faExternalLinkAlt),
  'fa-eye-dropper': generateSvg(faEyeDropper),
  'fa-file-download': generateSvg(faFileDownload),
  'fa-file-export': generateSvg(faFileExport),
  'fa-file-upload': generateSvg(faFileUpload),
  'fa-filter': generateSvg(faFilter),
  'fa-ghost': generateSvg(faGhost),
  'fa-hat-wizard': generateSvg(faHatWizard),
  'fa-info-circle': generateSvg(faInfoCircle),
  'fa-key': generateSvg(faKey),
  'fa-keyboard': generateSvg(faKeyboard),
  'fa-layer-group': generateSvg(faLayerGroup),
  'fa-pencil-alt': generateSvg(faPencilAlt),
  'fa-plus': generateSvg(faPlus),
  'fa-plus-square': generateSvg(faPlusSquare),
  'fa-question-circle': generateSvg(faQuestionCircle),
  'fa-robot': generateSvg(faRobot),
  'fa-search': generateSvg(faSearch),
  'fa-sign-out-alt': generateSvg(faSignOutAlt),
  'fa-sliders-h': generateSvg(faSlidersH),
  'fa-sync-alt': generateSvg(faSyncAlt),
  'fa-tag': generateSvg(faTag),
  'fa-times': generateSvg(faTimes),
  'fa-times-circle': generateSvg(faTimesCircle),
  'fa-tint': generateSvg(faTint),
  'fa-toggle-off': generateSvg(faToggleOff),
  'fa-toggle-on': generateSvg(faToggleOn),
  'fa-trash': generateSvg(faTrash),
  'fa-trash-alt': generateSvg(faTrashAlt),
  'fa-undo': generateSvg(faUndo),
  'fa-user': generateSvg(faUser),
  'fa-users': generateSvg(faUsers),
  'fa-mouse-pointer': generateSvg(faMousePointer),
  'fa-step-forward': generateSvg(faStepForward),
  'fa-search-minus': generateSvg(faSearchMinus),
  'fa-search-plus': generateSvg(faSearchPlus),
  'fa-table': generateSvg(faTable),
  'fa-image': generateSvg(faImage),
  'fa-link': generateSvg(faLink),
  'fa-list': generateSvg(faList),
};

function generateSvg(iconDefinition: IconDefinition): React.ReactElement {
  const { icon } = iconDefinition;

  return (
    <svg fill="currentColor" viewBox={`0 0 ${icon[0]} ${icon[1]}`}>
      <path d={`${icon[4]}`} />
    </svg>
  );
}

export { faIcons };
