import React from 'react';

import { TabbedInterface, NavigationTab } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

type Props = PermafrostComponent & {
  children: React.ReactNode;
  currentTab?: string;
  fullWidthTabs?: boolean;
  tabs: NavigationTab[];
};

/**
 * An a11y-friendly tabbed interface; allows the user to navigate between URLs.
 *
 * If the URL does **not** need to change between sections, the `TabbedContainer` component
 * should be used instead.
 */
export function TabNavigation(props: Props) {
  const { children, className, currentTab, fullWidthTabs, id, tabs } = props;

  return (
    <TabbedInterface
      tabType="navigation"
      tabs={tabs}
      className={className}
      currentTab={currentTab}
      data-cy={props['data-cy']}
      fullWidthTabs={fullWidthTabs}
      id={id}
    >
      {children}
    </TabbedInterface>
  );
}
