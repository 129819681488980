import styled from 'styled-components';

import { PermafrostComponent } from 'Permafrost/types';
import { buttonBorderRadius } from 'Permafrost/styles/values';

import { iconSizeDefault } from '../ControlButton/ControlButton.styles';

/**
 * A styled component which groups two or more icon-only buttons, and displays them as a single entity.
 */
export const SegmentedControlButtons = styled.div.attrs<PermafrostComponent>(() => ({
  role: 'group',
}))`
  display: inline-block;
  border-radius: ${buttonBorderRadius};
  box-shadow: 0 1px 1px 0 #000;

  button {
    // todo: override global
    padding: 0 !important;
    box-shadow: 0 0 1px 0 #000 !important;

    height: calc(${iconSizeDefault} * 2);
    width: calc(${iconSizeDefault} * 2);

    &:first-of-type {
      border-radius: ${buttonBorderRadius} 0 0 ${buttonBorderRadius};
    }

    &:last-of-type {
      border-radius: 0 ${buttonBorderRadius} ${buttonBorderRadius} 0;
    }

    &:not(:first-of-type):not(:last-of-type) {
      border-radius: 0;
    }

    &:disabled {
      opacity: 0.7;
    }

    &:focus {
      // ensures focus ring has no overlap with sibling buttons
      transform: rotate(0);
    }
  }
`;
