import { subDays } from 'date-fns';

// TODO: Standardize date utilities and date format across the app (TICKET #: https://indicodata.atlassian.net/browse/UI-1003)

/**
 * Note: in order to show 30 days’ data, we need to accommodate for today, so technically
 * we need to get 29 days ago...
 */
export function getThirtyDaysAgo(todaysDate?: string): string {
  if (todaysDate) {
    //@ts-ignore
    return getDateString(subDays(`${todaysDate} 00:00:00`, 29));
  }

  return getDateString(subDays(new Date().setHours(0, 0, 0), 29));
}

/**
 * @returns date string for the date provided, or for today.
 */
export function getToday(todaysDate?: string): string {
  if (todaysDate) {
    return todaysDate;
  }

  return getDateString(new Date(new Date().setHours(0, 0, 0)));
}

export function formatDate(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(date);
}

export function formatDateAndTime(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
    .format(date)
    .replace(',', '');
}

export function formatDateAndTimeStandard(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  })
    .format(date)
    .replace(',', '');
}

/**
 * @returns date string in 'yyyy-mm-dd' format
 */
export function getDateString(date: Date): string {
  return date.toISOString().split('T')[0];
}

/**
 * @returns date as DateParts object
 */

type DateParts = {
  month: string;
  day: string;
  dayPeriod: string;
  hour: string;
  minute: string;
  year: string;
};

export const getDateParts = (date: Date): DateParts => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
  })
    .formatToParts(date)
    .reduce((acc, part) => {
      if (part.type !== 'literal') {
        return {
          ...acc,
          [part.type]:
            (part.type === 'month' || part.type === 'day') && part.value.length === 1
              ? `0${part.value}`
              : part.value,
        };
      } else {
        return acc;
      }
    }, {} as DateParts);
};
