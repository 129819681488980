import styled from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledConfirmModal = styled.div`
  padding: 20px;

  .title {
    margin: 30px 16px;
    font-size: ${TYPOGRAPHY.fontSize.display};
    text-align: center;
    color: ${COLORS.lightFontColor};
  }

  .message {
    text-align: center;
    padding-bottom: 16px;
  }

  .actions {
    text-align: center;
    padding-bottom: 40px;

    button {
      min-width: 300px;
      display: block;
      margin: 16px auto 0;
    }
  }
`;
