import styled, { keyframes } from 'styled-components';

import { COLORS } from 'Permafrost/index';

const moveBg = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(46px);
  }
`;

export const StyledBarSpinner = styled.div`
  display: inline-block;
  width: 200px;
  height: 14px; /* Can be anything */
  position: relative;
  border-radius: 25px;

  > span {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    background-color: ${COLORS.curiousBlue};

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -46px;
      bottom: 0;
      right: 0;
      background: repeating-linear-gradient(
        -55deg,
        transparent 1px,
        rgba(255, 255, 255, 0.25) 2px,
        rgba(255, 255, 255, 0.25) 11px,
        transparent 12px,
        transparent 20px
      );
      animation-name: ${moveBg};
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      border-radius: 20px;
      overflow: hidden;
    }
  }
`;
