import styled from 'styled-components';

type LiProps = {
  $lockAxis?: boolean;
};

export const StyledLi = styled.li<LiProps>`
  // note that scale and shadow are being applied to the passed-in child component, not
  // the parent draggable li.
  > * {
    transition: transform 0.2s;
  }

  &:active {
    cursor: grabbing;

    > * {
      transform: scale(${(props) => (props.$lockAxis ? 1.05 : 1)});
      box-shadow: rgba(0, 0, 0, 0.5) 0 4px 8px 0;
    }
  }
`;
