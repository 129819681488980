import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledContextMenu = styled.ul`
  display: block;
  position: fixed;
  top: 0;
  left: 0;

  border: 1px solid ${COLORS.oxfordBlue};
  background-color: ${COLORS.ebony};
  font-size: 12px;

  li {
    padding: 5px 7px;

    a {
      text-decoration: none;
      color: ${COLORS.lightFontColor};
    }

    &.disabled {
      opacity: 0.5;
    }

    &:hover {
      background-color: ${COLORS.oxfordBlue};
    }
  }
`;
