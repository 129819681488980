import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledDragDropList = styled.div``;

export const StyledDragDropListItem = styled.div`
  background: ${COLORS.oxfordBlue};
  border-radius: 4px;
  color: ${COLORS.lightGray};
  display: flex;
  margin: 4px 0;

  &.is-dragging {
    background: ${COLORS.blueBayoux};
  }

  &.drag-disabled {
    padding-left: 20px;
  }

  & .string {
    padding: 20px 20px 20px 0;
  }

  .draggable-icon {
    color: ${COLORS.comet};
    margin: 20px;
    overflow: visible;
  }

  .draggable-content {
    width: 100%;
  }

  .draggable-content.string {
    align-self: center;
  }
`;
