import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

import { inputCommon, labelCommon } from '../fieldsCommon.styles';

export const StyledNumberField = styled.div`
  position: relative;

  min-height: 40px;

  &:not(.hiddenLabel) {
    margin-top: 35px;
  }

  input {
    ${inputCommon};

    padding: 5px 0 10px;
    margin-top: 0;

    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
    }

    &::placeholder {
      opacity: 0.5;
      color: ${COLORS.lightFontColor};
    }

    &:disabled {
      background-color: transparent;
      opacity: 0.7;

      pointer-events: none;

      ~ .spin-buttons {
        opacity: 0.7;

        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  label {
    ${labelCommon};

    position: absolute;
    top: -24px;
  }

  .spin-buttons {
    position: absolute;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;

    margin-bottom: 0.5em;
`;
