import styled from 'styled-components';

import { SPACING } from 'Permafrost/index';

export const StyledPillCheckGroupBase = styled.div`
  .PillCheckGroup__group-label {
    flex: 0 0 100%;
    margin-bottom: ${SPACING.sm};
  }

  > * {
    margin-right: ${SPACING.sm};
    margin-bottom: ${SPACING.sm};
  }

  &.PillCheckGroupBase--horizontal {
    display: flex;
    flex-wrap: wrap;

    .caption {
      display: none;
    }
  }

  &:not(.PillCheckGroupBase--disabled) label {
    cursor: pointer;

    &:hover,
    &.Pill__label--checked .pill-ellipse {
      color: currentColor;
    }
  }

  &.PillCheckGroupBase--disabled label {
    cursor: not-allowed;
  }
`;
