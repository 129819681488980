import { createGlobalStyle } from 'styled-components';

import { colorProperties } from './color-properties';

export const Layout = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    background-color: var(${colorProperties.appBackground});
  }
`;
