import React, { useRef, useState, useEffect, useLayoutEffect, useMemo } from 'react';

import debounce from 'lodash/debounce';

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useResizeBrowser = (debounceTime?: number) => {
  const isClient = typeof window === 'object';

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return () => {};
    }

    const handleResize = debounce(
      () => {
        setWindowSize(getSize());
      },
      debounceTime ? debounceTime : 200
    );

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { size: windowSize, height: windowSize.height, width: windowSize.width };
};

type UseFetchConfig = {
  onCompleted?: (data: any) => void;
};

// Fetches data from url on mount and url updates
// - request is aborted if the component is unmounted
// - data is exposed once the request is completed
export const useFetch = <T>(
  url: string,
  options: UseFetchConfig = {}
): { loading: boolean; error: any; data: any } => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);
    fetch(url, { credentials: 'include', signal })
      .then((r) => r.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        options.onCompleted && options.onCompleted(data);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
    return () => {
      abortController.abort();
    };
  }, [url]);

  return { loading, data, error };
};

type UseFetchAllConfig<T> = {
  batchSize?: number;
  onCompleted?: (data: T[]) => void;
};

// Fetches data from multiple urls in parallel on mount and url content updates
// - returns the responses in the same order as the urls
// - request is aborted if the component is unmounted
// - data is exposed once all requests have completed
// - an error is exposed if any request fails
export const useFetchAll = <T>(
  urls: string[],
  options: UseFetchAllConfig<T> = {}
): { loading: boolean; error: any; data: T[] | null } => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T[] | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const memoizedUrls = useMemo(() => urls, [urls.join(',')]);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    const signal = abortController.signal;

    // If no batchSize is provided, fetch all urls in parallel
    const batchSize = options.batchSize || memoizedUrls.length;

    const batches = Array.from(
      {
        length: Math.ceil(memoizedUrls.length / batchSize),
      },
      (_, index) => memoizedUrls.slice(index * batchSize, (index + 1) * batchSize)
    );

    const fetchBatch = (batchUrls: string[]) => {
      return Promise.all(
        batchUrls.map((url) => fetch(url, { credentials: 'include', signal }).then((r) => r.json()))
      );
    };

    const fetchAllBatches = async () => {
      const accumulatedResults: T[] = [];
      for (const batch of batches) {
        try {
          accumulatedResults.push(...(await fetchBatch(batch)));
        } catch (e) {
          if (e instanceof Error && e.name !== 'AbortError') {
            setError(e);
            setLoading(false);
            return;
          }
        }
      }
      setData(accumulatedResults);
      setLoading(false);
      options.onCompleted && options.onCompleted(accumulatedResults);
    };

    fetchAllBatches();

    return () => {
      abortController.abort();
    };
  }, [memoizedUrls, options.batchSize]);

  return { loading, data, error };
};

export const useClickOutside = (
  ref: React.MutableRefObject<HTMLElement>,
  handler: (e: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }
      handler(e);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

// https://github.com/streamich/react-use/blob/master/src/useMeasure.ts
export type UseMeasureRect = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;
const defaultRect: UseMeasureRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

type Element = HTMLElement | null;

// https://github.com/streamich/react-use/blob/master/src/useMeasure.ts
export const useMeasure = (): [any, UseMeasureRect] => {
  const [element, ref] = useState<Element>(null);
  const [rect, setRect] = useState<UseMeasureRect>(defaultRect);

  const observer = useMemo(
    () =>
      new (window as any).ResizeObserver((entries: any) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect;
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return [ref, rect];
};
