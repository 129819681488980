import styled from 'styled-components';
import { MEDIA_QUERIES } from '@indico-data/permafrost';

export const StyledLoginFailed = styled.div`
  padding: 20px 10px;

  @media ${MEDIA_QUERIES.mediumScreen} {
    padding: 60px;
  }
`;
