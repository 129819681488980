import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

const sectionSidePadding = '19px';

export const StyledSectionBody = styled.div`
  background: ${COLORS.clay};
  border-radius: 4px;
  border: 1px solid #333d4c;
  padding: 0 ${sectionSidePadding};

  .SectionBody:last-of-type {
    .section-bottom-border {
      border-bottom: none;
    }
  }
`;
