import React from 'react';

import { v4 as uuid } from 'uuid';

import { Button, LoadingAwareContainer, ModalBase, ModalBaseProps } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledConfirmModal } from './ConfirmModal.styles';

export type ConfirmModalProps = PermafrostComponent &
  Pick<ModalBaseProps, 'open'> & {
    describedBy?: string;
    clickOutsideHandler(e?: React.SyntheticEvent): void;
    confirmText?: string;
    message?: string | React.ReactNode;
    modalNode?: string;
    processing?: boolean;
    rejectText?: string;
    responseHandler(response: boolean, event: React.SyntheticEvent): void;
    title?: string | React.ReactNode;
    width?: number;
  };

export function ConfirmModal(props: ConfirmModalProps) {
  const id = uuid();
  const {
    className,
    clickOutsideHandler,
    confirmText,
    describedBy,
    message,
    modalNode,
    open,
    processing,
    rejectText,
    responseHandler,
    title,
    width,
  } = props;

  return (
    <ModalBase
      className={className}
      clickToDismiss={clickOutsideHandler}
      data-cy={props['data-cy']}
      describedBy={describedBy}
      id={props.id}
      labelElement={id}
      node={modalNode}
      open={open}
    >
      <StyledConfirmModal style={{ width: width || 555 }}>
        {title && (
          <div id={id} className="title">
            {title || 'Please confirm'}
          </div>
        )}

        {message && <div className="message">{message}</div>}

        <LoadingAwareContainer isLoading={processing} className="actions">
          <Button
            variant="cta"
            className="confirm"
            onClick={(event) => responseHandler(true, event)}
          >
            {confirmText || 'Yes'}
          </Button>

          <Button
            variant="normal"
            className="reject"
            onClick={(event) => responseHandler(false, event)}
          >
            {rejectText || 'No'}
          </Button>
        </LoadingAwareContainer>
      </StyledConfirmModal>
    </ModalBase>
  );
}
