/**
 * Create a type which requires at least one of the given keys.
 *
 * @example
 * type BaseProps = {
 *   onClick(param?: any): void;
 *   text: string;
 *   to: string;
 * };
 *
 * type Props = utilTypes.RequireAtLeastOne<BaseProps, 'onClick' | 'to'>
 *
 * @see {@link https://stackoverflow.com/questions/40510611/typescript-interface-require-one-of-two-properties-to-exist/49725198#comment116238286_49725198}
 */
export type RequireAtLeastOne<T, R extends keyof T = keyof T> = Omit<T, R> &
  { [P in R]: Required<Pick<T, P>> & Partial<Omit<T, P>> }[R];
