import React, { createContext, useContext } from 'react';

import { v4 as uuid } from 'uuid';

import { DragDropContext, Droppable, DroppableProvided, DropResult } from '@hello-pangea/dnd';

import { arrayUtils } from '@indico-data/utils';

import { PermafrostComponent } from 'Permafrost/types';

import { ListItem, ItemPartial } from './index';
import { useItemSwap } from './useItemSwap';

type ListControlsConfig = {
  hideArrows?: boolean;
  hideDelete?: boolean;
  hideEdit?: boolean;
  promptBeforeDelete?: boolean;
};

type Props<T extends ItemPartial> = PermafrostComponent & {
  items: T[];
  listControls?: ListControlsConfig;

  /**
   * Visual gap between items in the list
   */
  itemsVerticalGap?: number;

  /**
   * optional prop in case an action needs to occur after deletion; when the array has been updated,
   * `onReorder()` is always fired via the `handleDelete` method below.
   */
  onDelete?(items: T[]): void;
  onReorder(reorderedItems: T[]): void;

  /**
   * number of milliseconds for an item swap to complete; defaults to 250ms
   */
  swapDuration?: number;

  /**
   * React component for each item
   */
  ListItemComponent(): JSX.Element;
};

export type UpdateItemOrder = {
  destination?: number;
  source: number;
  direction?: 'down' | 'up';
};

type ListControlsType = ListControlsConfig;

const droppableId = uuid();

const ListControlsContext = createContext({} as ListControlsType);
ListControlsContext.displayName = 'ReorderableListControls';

/**
 * Vertical list that may be reordered by drag-and-drop action, or programmatically by either
 * including `<ListControls>` in the custom component to be passed in (`ListItemComponent`), or
 * by using the `useListItem` Context hook directly - see `ListItemContextType` at the bottom
 * of this file for a list of the props available from the hook.
 *
 * @see {@link https://reactjs.org/docs/context.html}
 */
export function ReorderableList<T extends ItemPartial>(props: Props<T>) {
  const {
    className,
    id,
    items,
    itemsVerticalGap = 0,
    listControls,
    onDelete,
    onReorder,
    swapDuration = 250,
    ListItemComponent,
  } = props;

  const { addItemHeight, clearSwapAnimations, setSwapAnimations, swapAnimations } = useItemSwap();

  const handleDelete = (itemId: string) => {
    const itemIndex = items.findIndex((item) => itemId === item.id);

    const updatedItems = arrayUtils.deleteItem(items, itemIndex);

    onReorder(updatedItems);
    onDelete && onDelete(updatedItems);
  };

  // only ever called as a result of a Drop action
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const reorderedItems = arrayUtils.reorderItem(
      items,
      result.source.index,
      result.destination.index
    );

    onReorder(reorderedItems);
  };

  // called when programmatically changing order, e.g. pressing an up/down arrow button in `ListControls`
  const handleMoveItem = (sourceId: string, direction: any) => {
    const sourceIndex = items.findIndex((item) => item.id === sourceId);

    if (
      (sourceIndex === 0 && direction === 'up') ||
      (sourceIndex === items.length && direction === 'down')
    ) {
      return;
    }

    const destinationIndex = direction === 'up' ? sourceIndex - 1 : sourceIndex + 1;
    const destinationId = items[destinationIndex].id;

    setSwapAnimations(sourceId, destinationId, direction);

    const reorderedItems = arrayUtils.reorderItem(items, sourceIndex, destinationIndex);

    setTimeout(() => {
      onReorder(reorderedItems);

      clearSwapAnimations();
    }, swapDuration + 100); // jank protection: wait an extra 100ms before the css classes are removed
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided: DroppableProvided) => (
          <ul
            ref={provided.innerRef}
            className={className}
            data-cy={props['data-cy']}
            id={id}
            {...provided.droppableProps}
          >
            <ListControlsContext.Provider
              value={{
                ...listControls,
              }}
            >
              {items.map((item, i) => (
                <ListItem
                  animating={swapAnimations.has(item.id) ? swapAnimations.get(item.id) : null}
                  addItemHeight={addItemHeight}
                  key={item.id}
                  deleteItem={handleDelete}
                  item={item}
                  index={i}
                  itemsVerticalGap={itemsVerticalGap}
                  listLength={items.length}
                  moveItem={(direction) => handleMoveItem(item.id, direction)}
                  swapDuration={swapDuration}
                  InnerComponent={ListItemComponent}
                />
              ))}
            </ListControlsContext.Provider>
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export type ListItemContextType<T extends ItemPartial> = {
  active?: boolean;
  confirmDelete?: boolean;
  dragging?: boolean;
  editing?: boolean;
  index: number;
  item: T;
  isFirstItem: boolean;
  isLastItem: boolean;
  deleteItem(id: string): void;
  moveItemDown(): void;
  moveItemUp(): void;
  setConfirmDelete(confirmDelete: boolean): void;
  setEditing(editing: boolean): void;
};

export function useListControls() {
  return useContext(ListControlsContext);
}
