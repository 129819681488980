import styled from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledShrug = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2em;

  font-size: ${TYPOGRAPHY.fontSize.subheadLarge};

  p {
    font-size: inherit;
    margin-top: 2em;
  }

  svg {
    fill: ${COLORS.iron};
    opacity: 0.5;
  }
`;
