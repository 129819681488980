import React from 'react';

import { COLORS } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledPercentageRing } from './PercentageRing.styles';

type Props = PermafrostComponent & {
  backgroundColor?: string;
  foregroundColor?: string;
  ringRadius?: number;
  strokeWidth?: number;
  /** an integer between 0 and 1 */
  value: number;
  valueFontSize?: number;
};

export function PercentageRing(props: Props) {
  const {
    backgroundColor = COLORS.clay,
    className,
    foregroundColor = COLORS.baliHai,
    id,
    ringRadius = 35,
    strokeWidth = 5,
    value = 0,
    valueFontSize = 20,
  } = props;

  const ringCircumference = 2 * Math.PI * (ringRadius - strokeWidth / 2);
  const valueCircumference = ringCircumference - value * ringCircumference;
  const diameter = ringRadius * 2;
  const circleRadius = ringRadius - strokeWidth / 2;

  // ensure we don’t display a value greater than 100% to the user
  const displayValue = Math.round(Math.min(value, 1) * 100);

  return (
    <StyledPercentageRing className={className} data-cy={props['data-cy']} id={id}>
      <div
        style={{
          fontSize: valueFontSize,
          width: diameter,
          height: diameter,
        }}
      >
        {displayValue}
        <span>%</span>
      </div>

      <svg
        height={diameter}
        width={diameter}
        fill="none"
        strokeWidth={strokeWidth}
        aria-hidden="true"
        focusable="false"
      >
        <g>
          <circle r={circleRadius} cx={ringRadius} cy={ringRadius} stroke={backgroundColor} />

          <circle
            r={circleRadius}
            cx={ringRadius}
            cy={ringRadius}
            stroke={foregroundColor}
            strokeDasharray={ringCircumference}
            strokeDashoffset={valueCircumference}
          />
        </g>
      </svg>
    </StyledPercentageRing>
  );
}
