import styled from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledProgressBar = styled.div`
  position: relative;
  height: 9px;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;

  .progress-container {
    display: flex;
    border-radius: 5px;
    border: 1px solid ${COLORS.comet};
    height: 9px;
    width: 100%;
    background: ${COLORS.ebony};
  }

  .primary-progress {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .progress-bar-tooltip {
    white-space: pre-wrap;
    left: -999em;
    top: -999em;
    position: fixed;
    z-index: 800;
    background-color: ${COLORS.ebony};
    color: ${COLORS.defaultFontColor};
    border: ${COLORS.mirage};
    border-radius: 5px;
    transform: translate(-50%, 50%);
    padding: 5px;
    font-size: ${TYPOGRAPHY.fontSize.base};
  }
`;
