import React, { ChangeEventHandler, useRef, useState } from 'react';

import { format, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import { usePopper } from 'react-popper';
import { PermafrostComponent } from 'Permafrost/types';
import { IconButton } from 'Permafrost/index';

import { StyledDatePicker } from './DatePicker.styles';

type Props = PermafrostComponent & {
  ariaLabel?: string;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  disabled?: boolean;
  id: string;
  label?: string;
  onChange: (value: Date | undefined) => void;
  placeholder?: string;
  selected?: Date | undefined;
  value: Date | undefined;
};

export const DatePicker = (props: Props) => {
  const {
    ariaLabel,
    className,
    disabled,
    disableBeforeDate,
    disableAfterDate,
    id,
    label,
    onChange,
    placeholder,
    value,
    selected,
  } = props;

  const [localSelected, setLocalSelected] = useState<Date | undefined>(selected || undefined);
  const [inputValue, setInputValue] = useState<string>(
    isValid(value) ? format(value as Date, 'y-MM-dd') : ''
  );
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  // TODO -- After discussing with product, we determined handling invalid would happen at a later date.
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date());
    if (isValid(date)) {
      setLocalSelected(date);
      onChange(date);
    } else if (e.currentTarget.value === '') {
      setLocalSelected(undefined);
      setInputValue('');
      onChange(undefined);
    } else {
      setLocalSelected(undefined);
    }
  };

  const handleDaySelect: SelectSingleEventHandler = (date) => {
    setLocalSelected(date);
    if (date) {
      setInputValue(format(date, 'y-MM-dd'));
      onChange(date);
      closePopper();
    } else {
      setInputValue('');
      onChange(date);
    }
  };

  return (
    <StyledDatePicker
      className={className}
      aria-label={ariaLabel || 'date select'}
      aria-describedby={`picker-label--${id}`}
      data-cy={props['data-cy']}
      data-testid={props['data-testid']}
      id={id}
    >
      {label ? (
        <div id={`picker-label--${id}`} className="datepicker-label">
          {label}
        </div>
      ) : null}
      <div ref={popperRef} className="datepicker--inputParent">
        <input
          ref={inputRef}
          data-testid={`datepicker-input-${id}`}
          disabled={disabled}
          type="text"
          placeholder={placeholder || format(new Date(), 'y-MM-dd')}
          value={inputValue}
          onChange={handleInputChange}
          className="datepicker--input"
          onClick={() => setIsPopperOpen(!isPopperOpen)}
        />
        <IconButton
          className="remove-btn"
          data-testid="button-remove-date-value"
          iconName="x-close"
          onPress={() => {
            closePopper();
            setInputValue('');
            setLocalSelected(undefined);
            onChange(undefined);
          }}
        />
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: inputRef.current || undefined,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="DayPickerInput-Overlay"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="DayPicker calendar"
          >
            <DayPicker
              disabled={disabled}
              mode="single"
              defaultMonth={localSelected}
              selected={localSelected}
              onSelect={handleDaySelect}
              fromDate={disableBeforeDate}
              toDate={disableAfterDate}
            />
          </div>
        </FocusTrap>
      )}
    </StyledDatePicker>
  );
};
