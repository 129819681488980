import React from 'react';

import { StyledLogo } from './Logo.styles';

type Props = {
  logomark?: boolean;
  width?: number;
};

/**
 * Displays the Indico logo treatment, either the logotype or logomark.
 *
 * The logotype is shown by default, unless the `logomark` property is true.
 */
export function Logo(props: Props) {
  const { logomark, width } = props;

  const logoWidth = width ? width : logomark ? 25 : 120;

  return (
    <StyledLogo
      viewBox={logomark ? '0 0 102 102' : '0 0 202 73'}
      height={logomark ? logoWidth : logoWidth * 0.35833333}
      width={logoWidth}
    >
      <title>Indico Data</title>

      {logomark ? (
        <path
          d="M58.5 43.6H43.7v14.8h14.8V43.6zm-36.9 0C25 30.6 36.8 20.9 51 20.9s26 9.7 29.4 22.8h20.3C97.1 19.6 76.2 1 51 1 25.8 1 4.9 19.6 1.3 43.6h20.3zm58.7 14.8C77 71.4 65.1 81.1 51 81.1c-14.1 0-26-9.7-29.3-22.7H1.3C5 82.5 25.8 101 51 101c25.2 0 46-18.5 49.7-42.6H80.3z"
          className="logomark"
        />
      ) : (
        <>
          <path
            d="M115 71.5h-8.7V50.3h8.7c5.9 0 10.6 4.8 10.6 10.6s-4.7 10.6-10.6 10.6zm-3.9-4.8h3.9c3.2 0 5.8-2.6 5.8-5.8s-2.6-5.8-5.8-5.8h-3.9v11.6zM173 50.3h-16.5v4.5h5.8v16.8h4.8V54.7h5.8l.1-4.4zm-34.3 0L131 71.5h5.1l1.3-3.5h8.3l1.3 3.5h5.1l-7.7-21.2h-5.7zm.3 13.2l2.5-7 2.5 7h-5zm46.6-13.2l-7.7 21.2h5.1l1.3-3.5h8.3l1.3 3.5h5.1l-7.7-21.2h-5.7zm.3 13.2l2.5-7 2.5 7h-5z"
            className="data"
          />
          <path
            d="M9.3 1.4v41.3H1V1.4h8.3zm133.2 41.5c5.6 0 10.9-2.2 14.8-6.2l-5.9-5.9c-2.4 2.4-5.6 3.7-9 3.7s-6.5-1.3-8.9-3.7-3.7-5.5-3.7-8.9 1.3-6.6 3.7-9c4.9-4.9 13-5 17.9-.1l5.9-5.9c-8-8-21.3-7.9-29.5.3-4 4-6.2 9.2-6.2 14.8s2.2 10.9 6.1 14.8c3.9 4 9.2 6.1 14.8 6.1zM100.3 22c0-11.4-9.3-20.6-20.7-20.6H62.9v41.2h16.8c11.3 0 20.6-9.3 20.6-20.6zM71.2 9.7h8.4C86.4 9.7 92 15.2 92 22s-5.5 12.3-12.3 12.3h-8.4l-.1-24.6zm112.1 9.2h-6.2v6.2h6.2v-6.2zm-77 6.2v17.5h8.3V25.1h-8.3zm8.4-6.2V1.4h-8.3V19h8.3v-.1zm53.2 0c1.4-5.5 6.4-9.5 12.3-9.5s10.9 4.1 12.3 9.5h8.5C199.5 8.8 190.7 1 180.2 1s-19.3 7.8-20.8 17.9h8.5zm24.6 6.2c-1.4 5.4-6.4 9.5-12.3 9.5s-10.9-4-12.3-9.5h-8.5c1.5 10.1 10.3 17.8 20.8 17.8s19.3-7.8 20.8-17.8h-8.5zM46.3 1.4v28.1L26.7 1.4h-9.2v41.3h8.3v-28l19.5 28h9.3V1.4h-8.3z"
            className="indico"
          />
        </>
      )}
    </StyledLogo>
  );
}
