import styled from 'styled-components';

import { Button, COLORS } from 'Permafrost/index';

export const StyledTooLarge = styled.div`
  margin-left: 15px;

  .too-large-message {
    color: ${COLORS.red};
  }

  .docs-link {
    font-size: 12px;
  }
`;

export const StyledGenerateReport = styled(Button)`
  height: 36px;
  font-size: 14px !important;
  margin-left: 15px;

  svg {
    vertical-align: top;
    margin-right: 5px;
  }

  &:disabled {
    opacity: 0.4;
  }
`;
