import React, { MutableRefObject, useRef } from 'react';

import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';

import { AriaButtonProps } from '@react-types/button';

import { Icon, IconName, LoadingSpinner, Tooltip } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledControlButton, iconSizeDefault } from './ControlButton.styles';

export type ControlButtonType = 'step-forward' | 'reject' | 'submit' | 'restore';

type Props = PermafrostComponent & {
  busy?: boolean;
  buttonType: ControlButtonType;
  tooltipDisabled?: boolean;
  /**
   * Will override default text, if provided
   */
  tooltipText?: string;
} & AriaButtonProps;

export const controlButtonSpacing = {
  normal: '16px',
};

const controlButton = {
  reject: { icon: 'trash', label: 'Reject' },
  submit: { icon: 'check', label: 'Submit' },
  restore: { icon: 'upload', label: 'Restore' },
  'step-forward': { icon: 'step-forward', label: 'Step Forward' },
};

/**
 * Small icon-only buttons used for UI controls.
 */
export function ControlButton(props: Props) {
  const { busy, buttonType, className, id, tooltipDisabled, tooltipText } = props;

  const buttonEl = useRef() as MutableRefObject<HTMLButtonElement>;
  const { buttonProps } = useButton(props, buttonEl);
  const { isFocusVisible, focusProps } = useFocusRing();

  const iconSize = buttonType === 'submit' ? '22px' : iconSizeDefault;

  const tooltipId = uuid();

  const buttonTooltipProps = tooltipDisabled
    ? {
        'aria-label': controlButton[buttonType].label,
      }
    : { 'data-tip': true, 'data-for': tooltipId, 'aria-labelledby': tooltipId };

  return (
    <>
      <StyledControlButton
        $buttonType={buttonType}
        $iconSize={iconSize}
        id={id}
        className={classNames(className, {
          'focus-visible': isFocusVisible,
        })}
        data-cy={props['data-cy']}
        {...buttonProps}
        {...focusProps}
        {...buttonTooltipProps}
      >
        {busy ? (
          <LoadingSpinner size={iconSizeDefault} />
        ) : (
          <Icon name={controlButton[buttonType].icon as IconName} size={[iconSize]} />
        )}
      </StyledControlButton>

      {!tooltipDisabled && (
        <Tooltip for={tooltipId}>{tooltipText || controlButton[buttonType].label}</Tooltip>
      )}
    </>
  );
}
