import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledTooltip = styled.div`
  display: inline-block;

  i:hover {
    cursor: pointer;
  }

  p {
    line-height: 1.4;
    font-size: 13px;
  }

  .tool {
    background: ${COLORS.oxfordBlue} !important;
    border: 1px solid ${COLORS.baliHai} !important;
    border-radius: 2px !important;
    padding: 8px 11px !important;
    opacity: 1 !important;

    &.place-left:after {
      border-left-color: ${COLORS.oxfordBlue} !important;
      border-top: 5px solid transparent !important;
      border-bottom: 5px solid transparent !important;
      right: -6px !important;
      top: 50% !important;
      margin-top: -9px !important;
    }

    &.place-left:before {
      border-left-color: ${COLORS.baliHai} !important;
      border-left-width: 8px !important;
      border-left-style: solid !important;
      margin-top: -10px !important;
    }

    &.place-right:after {
      border-right-color: ${COLORS.oxfordBlue} !important;
      border-top: 5px solid transparent !important;
      border-bottom: 5px solid transparent !important;
      left: -6px !important;
      top: 50% !important;
      margin-top: -9px !important;
    }

    &.place-right:before {
      border-right-color: ${COLORS.baliHai} !important;
      border-right-width: 8px !important;
      border-right-style: solid !important;
      margin-top: -10px !important;
    }

    &.place-top:after {
      border-top-color: ${COLORS.oxfordBlue} !important;
    }

    &.place-top:before {
      border-top-color: ${COLORS.baliHai} !important;
      border-top-width: 8px !important;
      border-top-style: solid !important;
    }

    &.place-bottom:after {
      border-bottom-color: ${COLORS.oxfordBlue} !important;
    }

    &.place-bottom:before {
      border-bottom-color: ${COLORS.baliHai} !important;
      border-bottom-width: 8px !important;
      border-bottom-style: solid !important;
    }
  }
`;
