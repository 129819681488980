import styled, { keyframes } from 'styled-components';

import { COLORS } from 'Permafrost/index';

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
`;

export const StyledLoadingList = styled.div`
  position: relative;

  height: 40vh;

  background-image: repeating-linear-gradient(
    to bottom,
    ${COLORS.ebony} 0px,
    ${COLORS.ebony} 60px,
    ${COLORS.clay} 60px,
    ${COLORS.clay} 65px
  );

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    opacity: 0.75;

    background-image: linear-gradient(to right, transparent, ${COLORS.clay}, transparent);
    background-size: 400% 100%;
    animation: ${loadingAnimation} 2s ease infinite;
  }
`;
