import { createGlobalStyle } from 'styled-components';

import { COLORS, TYPOGRAPHY, ANIMATION } from '../../constants';
import { colorUtils } from '@indico-data/utils';

const formBoxShadow = 'inset 0 0 0 rgba(#000, 0)';
const formBoxShadowFocus = formBoxShadow;

export const Forms = createGlobalStyle`
fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: ${TYPOGRAPHY.spacing.quarter};
  padding: 0;
}

label {
  display: block;
  font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
  margin-bottom: ${TYPOGRAPHY.spacing.quarter};
}

input,
select,
textarea {
  display: block;
  font-family: ${TYPOGRAPHY.fontFamily.base};
  font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
}

[type='color'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
input:not([type]),
textarea {
  appearance: none;
  background-color: ${COLORS.clay};
  border: ${COLORS.clay};
  border-radius: 2px;
  box-shadow: ${formBoxShadow};
  box-sizing: border-box;
  color: ${COLORS.defaultFontColor};
  margin-bottom: ${TYPOGRAPHY.spacing.half};
  padding: ${TYPOGRAPHY.spacing.third};
  transition: border-color ${ANIMATION.duration} ${ANIMATION.timing};
  width: 100%;

  &:hover {
    border-color: ${colorUtils.shade(COLORS.borderColor, 20)};
  }

  &:focus {
    border-color: ${COLORS.actionColor};
    box-shadow: ${formBoxShadowFocus};
    outline: none;
  }

  &:disabled {
    background-color: ${colorUtils.shade(COLORS.backgroundColor, 5)};
    cursor: not-allowed;

    &:hover {
      border: 1px solid ${COLORS.lightGray};
    }
  }

  &::placeholder {
    color: ${colorUtils.tint(COLORS.defaultFontColor, 40)};
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: ${TYPOGRAPHY.spacing.quarter};
}

[type="file"] {
  margin-bottom: ${TYPOGRAPHY.spacing.half};
  width: 100%;
}
`;
