import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledDateRangePicker = styled.div`
  display: flex;

  label {
    padding: 10px 10px 10px 0;
    color: ${COLORS.white};
    font-size: 14px;
  }

  .date-range-picker-inputs {
    display: flex;

    &.vertical {
      &,
      .from-picker,
      .to-picker {
        flex-direction: column;
      }
    }

    .spacer {
      position: relative;
      bottom: 5px;
      width: 12px;
      margin: 10px;
      align-self: center;
      border-bottom: 1px solid ${COLORS.lightGray};
    }
  }

  .DayPicker-Day--selected {
    border-radius: 0;
    opacity: 0.3;
  }

  .from-picker .DayPicker-Day--from,
  .to-picker .DayPicker-Day--to {
    opacity: 1;
  }

  .from-picker .DayPicker-Day--to,
  .to-picker .DayPicker-Day--from {
    opacity: 0.6;
  }

  .to-picker .DayPicker-Day--from,
  .from-picker .DayPicker-Day--from {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .from-picker .DayPicker-Day--to,
  .to-picker .DayPicker-Day--to {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
`;
