import validate from 'validate.js';

const UPPERCASE_ALPHA_MATCH = /[A-Z]+/;
const LOWERCASE_ALPHA_MATCH = /[a-z]+/;
const SPECIAL_CHARACTER_MATCH = /[*@!#%$&()^~{}]+/;
const DIGIT_MATCH = /\d+/;

export const containsUppercase = (inputValue: string) => {
  return UPPERCASE_ALPHA_MATCH.test(inputValue) ? null : 'must contain an uppercase letter';
};

export const containsLowercase = (inputValue: string) => {
  return LOWERCASE_ALPHA_MATCH.test(inputValue) ? null : 'must contain a lowercase letter';
};

export const containsSpecialCharacter = (inputValue: string) => {
  return SPECIAL_CHARACTER_MATCH.test(inputValue)
    ? null
    : 'must contain one of these characters * @ ! $ # % & ( ) ^ ~ { }';
};

export const containsNumber = (inputValue: string) => {
  return DIGIT_MATCH.test(inputValue) ? null : 'must contain at least one number';
};

export const isChecked = (inputValue: boolean) => {
  return validate.isBoolean(inputValue) && inputValue ? null : 'be checked';
};
