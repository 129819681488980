import styled from 'styled-components';

import { COLORS, colorProperties } from 'Permafrost/index';

export const StyledAppTitle = styled.div`
  border-bottom: 1px solid ${COLORS.oxfordBlue};
  padding-bottom: 10px;

  > * {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .appIcon {
    height: 25px;
    width: 25px;
    color: var(${colorProperties.logoPrimary});
  }

  h3 {
    letter-spacing: 2px;
    color: var(${colorProperties.logoSecondary});
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 7px;
  }

  .ActionLinks {
    float: right;

    a {
      color: ${COLORS.lightFontColor};
      text-decoration: none;

      &:hover {
        color: ${COLORS.midFontColor};
      }
    }
  }
`;
