import React, { useState } from 'react';
import { components, createFilter } from 'react-select';
import { Icon, TYPOGRAPHY } from '../../../../index';
import { addCustomProps } from '../../../../components/ui-foundations/dropdowns/utils';
import { StyledCreateableSelect } from './CreateableSelect.styles';

export type Option = { 
  label: string; 
  value: string;
  icon?: string;
  swatchColor?: string;
}
export type CreateableSelectProps = {
  id: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  closeMenuOnSelect?: boolean;
  labelText?: string;
  className: string;
  isClearable?: boolean;
  onChange: (newValue: Option | Option[] | null) => void;
  onCreateOption: (inputValue: string) => void;
  options: Option[];
  defaultValue: Option | Option[] | null;
  value: Option | Option[] | null;
  menuIsOpen?: boolean;
  placeholder?: string;
  hasWordBreak?: boolean;
  hasHiddenLabel?: boolean;
  dropdownIndicatorProps?: {
    'data-cy': string;
  };
}

const DropdownIndicator = (indicatorProps: any) => {
    return (
      <components.DropdownIndicator {...indicatorProps}>
        <Icon name="fa-caret-down" ariaLabel="open dropdown" size={[TYPOGRAPHY.fontSize.base]} />
      </components.DropdownIndicator>
    );
  };
  
const CustomOption = (optionProps: any) => {
  const { children, data, ...rest } = optionProps;
  const iconString = data?.icon || '';
  const swatchColor = data?.swatchColor || '';

  return (
    <components.Option {...rest}>
    {swatchColor ? (
      <span className="swatch" style={{ backgroundColor: swatchColor }} />
    ) : null }
    {iconString ? (
      <Icon
        name={iconString}
        className="inline-icon"
        ariaLabel={`${iconString} icon`}
        size={[TYPOGRAPHY.fontSize.base]}
      />
    ) : null}
    {children}
  </components.Option>
  );
};

const CustomSingleValue = (optionProps: any) => {
  const { children, data, ...rest } = optionProps;
  const iconString = data?.icon || '';
  const swatchColor = data?.swatchColor || '';

  return (
    <components.SingleValue {...rest}>
      {swatchColor ? (
        <span className="swatch" style={{ backgroundColor: swatchColor }} />
      ) : null}
      {iconString ? (
        <Icon
          name={iconString}
          className="inline-icon"
          ariaLabel={`${iconString} icon`}
          size={[TYPOGRAPHY.fontSize.base]}
        />
      ) : null}
      {children}
    </components.SingleValue>
  );
};

export const CreateableSelect = React.forwardRef((props: CreateableSelectProps, ref: any) =>  {
  const {
    id,
    isDisabled,
    className,
    isClearable,
    closeMenuOnSelect,
    isLoading,
    onChange,
    dropdownIndicatorProps = {},
    onCreateOption,
    placeholder,
    value,
    menuIsOpen,
    options,
    hasWordBreak = true,
    defaultValue,
    labelText,
    hasHiddenLabel = false,
  } = props;

  const [localOptions, setLocalOptions] = useState<Option[]>(options);
  const [localValue, setLocalValue] = useState<Option | Option[] | null>(value);

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'start',
  };
  
  const handleCreate = (inputValue: string): void => {
    const newOption = createOption(inputValue);
    setLocalOptions((prev) => [...prev, newOption]);
    setLocalValue(newOption);
    onCreateOption(inputValue);
  };

  const createOption = (label: string): Option => ({
    label,
    value: label.toLowerCase(),
  });

  const handleSelectChange = (newValue: Option | Option[] | null): void => {
    setLocalValue(newValue);
    onChange(newValue);
  };
  
  const breakWord: string = hasWordBreak ? 'has-word-break' : '';

  return (
    <div className={'combobox-container'}>
      <label 
        data-testid="combobox-label"
        className={hasHiddenLabel ? 'aria-invisible' : ''} 
        htmlFor={id}>
          {labelText}
      </label>
      <StyledCreateableSelect
        id={id}
        ref={ref}
        components={{
          DropdownIndicator: addCustomProps(DropdownIndicator, dropdownIndicatorProps),
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        closeMenuOnSelect={closeMenuOnSelect}
        menuIsOpen={menuIsOpen}
        isClearable={isClearable}
        hasWordBreak={hasWordBreak}
        options={localOptions}
        classNamePrefix={'combobox'}
        className={`${breakWord} ${className}`}
        placeholder={placeholder}
        onChange={handleSelectChange as () => void}
        onCreateOption={handleCreate}
        value={localValue}
        defaultValue={defaultValue}
        filterOption={createFilter(filterConfig)}
      />
    </div>
  );
});
