import React, { useEffect, useMemo, useState } from 'react';

import { Button, ConfirmModal, IconButton, ButtonSize } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledWizard } from './Wizard.styles';

export type CancelText = {
  // "confirm" button text
  confirmText: string;
  message?: string;
  // "reject" button text
  rejectText: string;
  title: string;
  // Optional, supporting text
};

type Props = PermafrostComponent & {
  children: React.ReactNode;
  /**
   * text for the cancel confirmation modal
   */
  confirmCancel: CancelText;
  disableNextStep?: boolean;
  disablePrevStep?: boolean;
  disableSubmit?: boolean;
  isLastStep?: boolean;
  /**
   * for testing/mocking purposes
   */
  startingStep?: string;
  steps: string[];
  /**
   * the text to appear on the submit button
   */
  submitButtonLabel: string;
  /**
   * Puts button in "busy" mode, and replaces button label with this text
   */
  submitProcessing?: string;
  wizardTitle: string;
  onCancel?(): void;
  onNextPress?(): boolean;
  /**
   * returns the current step name to the parent component
   */
  onStepChange(stepName: string): void;
  onSubmit(): void;
  /**
   * Allows for any custom attribute to be added directly to "next button" in wizard
   */
  nextButtonProps?: {
    'data-cy': string;
  };
};

const commonButtonProps = {
  adjustAlignment: -2,
  size: 'large' as ButtonSize,
};

export function Wizard(props: Props) {
  const {
    children,
    className,
    confirmCancel,
    disableNextStep,
    disablePrevStep,
    disableSubmit,
    id,
    isLastStep,
    onCancel,
    onNextPress,
    onStepChange,
    onSubmit,
    startingStep,
    steps,
    submitButtonLabel,
    submitProcessing = '',
    wizardTitle,
    nextButtonProps,
  } = props;

  const [currentStep, setCurrentStep] = useState<string>(startingStep || steps[0]);
  const [openCancelConfirm, setOpenCancelConfirm] = useState<boolean>(false);

  const onLastStep: boolean = useMemo(() => {
    if (isLastStep !== undefined) {
      return isLastStep;
    }

    return steps.indexOf(currentStep) === steps.length - 1;
  }, [isLastStep, steps, currentStep]);

  const isBusy = !!(submitProcessing && submitProcessing.length > 0);

  const nextStep = () => {
    if (!onLastStep) {
      const currentIndex = steps.indexOf(currentStep);

      setCurrentStep(steps[currentIndex + 1]);
    }
  };

  const prevStep = () => {
    const currentIndex = steps.indexOf(currentStep);

    if (currentIndex !== 0) {
      setCurrentStep(steps[currentIndex - 1]);
    }
  };

  // handles the user's selection in the cancel confirmation modal
  const handleCancelRequest = (confirmCancel: boolean) => {
    if (confirmCancel && onCancel) {
      onCancel();
    }

    setOpenCancelConfirm(false);
  };

  const handleNextPress = () => {
    const allowNext = onNextPress ? onNextPress() : true;

    if (!allowNext) {
      return;
    }

    if (onLastStep) {
      handleSubmit();
    } else {
      nextStep();
    }
  };

  const handleSubmit = () => {
    onSubmit();
  };

  // report current step to the consuming component
  useEffect(() => {
    onStepChange(currentStep);
  }, [currentStep]);

  // ensures `currentStep` is reset on unmount, just in case
  useEffect(() => {
    return () => setCurrentStep(steps[0] || '');
  }, []);

  return (
    <StyledWizard className={className} data-cy={props['data-cy']} id={id}>
      <nav>
        <h1>
          {wizardTitle}: {currentStep}
        </h1>

        <div className="wizard-buttons">
          <Button onClick={() => setOpenCancelConfirm(true)} variant="link-style">
            Cancel
          </Button>

          {steps.indexOf(currentStep) !== 0 && (
            <IconButton
              {...commonButtonProps}
              iconName="fa-arrow-left"
              label="Previous Step"
              onPress={prevStep}
              isDisabled={disablePrevStep}
            />
          )}

          {onLastStep ? (
            <IconButton
              {...commonButtonProps}
              variant="primary"
              iconName="check"
              busy={isBusy}
              label={isBusy ? submitProcessing : submitButtonLabel}
              onPress={handleNextPress}
              isDisabled={disableSubmit}
            />
          ) : (
            <IconButton
              {...commonButtonProps}
              variant="primary"
              iconSide="end"
              iconName="fa-arrow-right"
              label="Next Step"
              onPress={handleNextPress}
              isDisabled={disableNextStep}
              {...nextButtonProps}
            />
          )}
        </div>
      </nav>

      <>{children}</>

      <ConfirmModal
        title={confirmCancel.title}
        message={confirmCancel.message}
        confirmText={confirmCancel.confirmText}
        rejectText={confirmCancel.rejectText}
        open={openCancelConfirm}
        clickOutsideHandler={() => setOpenCancelConfirm(false)}
        responseHandler={handleCancelRequest}
      />
    </StyledWizard>
  );
}
