import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { Button, Icon, COLORS, Tooltip } from 'Permafrost/index';

import { UpdateItemOrder } from './OldDragDropList';

import { StyledDragDropListItem } from './OldDragDropList.styles';

type Props = {
  arrows: boolean;
  canDelete: boolean;
  className?: string;
  index: number;
  isDragging?: boolean;
  item: { id: number; name: string };
  listLength: number;
  disableDeleteTooltipText?: string;
  onDelete?: (id: number) => void;
  onEdit?: (itemToEdit: string, editedItem: string) => void;
  updateItemOrder: (args: UpdateItemOrder) => void;
};

export const DragDropListItem = ({
  arrows,
  canDelete,
  className,
  index,
  isDragging,
  item,
  listLength,
  disableDeleteTooltipText,
  onDelete,
  // onEdit,
  updateItemOrder,
}: Props) => {
  // tracks whether an arrow has been pressed on this item
  const [active, setActive] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [index]);

  return (
    <StyledDragDropListItem
      className={classNames(className, {
        dragging: isDragging,
        active,
      })}
      style={{
        backgroundColor: confirmDelete ? `${COLORS.oxfordBlue}80` : `${COLORS.oxfordBlue}`,
      }}
    >
      <div
        className="list-item-text"
        style={{
          color: confirmDelete ? `${COLORS.defaultFontColor}80` : `${COLORS.defaultFontColor}`,
        }}
      >
        {item.name}
      </div>

      {!confirmDelete && (
        <div className="list-buttons">
          {onDelete && canDelete ? (
            <>
              {disableDeleteTooltipText ? (
                <Tooltip for={`delete-container-${item.id}`}>
                  <p>{disableDeleteTooltipText}</p>
                </Tooltip>
              ) : null}
              <div
                data-for={`delete-container-${item.id}`}
                data-tip
                data-testid={`div--delete-btn-${item.name}`}
              >
                <Button
                  className="delete"
                  onClick={() => setConfirmDelete(true)}
                  disabled={!!disableDeleteTooltipText}
                >
                  <Icon name="trash" size={[18]} />
                </Button>
              </div>
            </>
          ) : null}

          {/* i didn’t find any usages that included editing capability; commenting out for now - 5/2021 jm */}
          {/*{onEdit && (*/}
          {/*  <Button className="edit" onClick={() => onEdit(item)}>*/}
          {/*    <Icon name="edit" size={[18]} />*/}
          {/*  </Button>*/}
          {/*)}*/}

          {arrows && (
            <>
              <Button
                aria-label="move item up"
                className="arrow-up"
                disabled={index === 0}
                onClick={() => {
                  setActive(true);

                  updateItemOrder({ destination: index--, source: index });
                }}
              >
                <Icon name="fa-arrow-up" size={[22]} />
              </Button>

              <Button
                aria-label="move item down"
                className="arrow-down"
                disabled={index === listLength - 1}
                onClick={() => {
                  setActive(true);

                  updateItemOrder({ destination: index++, source: index });
                }}
              >
                <Icon name="fa-arrow-down" size={[22]} />
              </Button>
            </>
          )}
        </div>
      )}

      {confirmDelete && onDelete && canDelete ? (
        <div className="confirm-delete-container">
          <p>Are you sure?</p>

          <Button
            data-testid={`btn--confirm-delete-label`}
            className="confirm"
            onClick={() => onDelete(item.id)}
            style={{ opacity: isDragging ? '0.5' : '' }}
          >
            Yes, Delete It
          </Button>

          <Button
            data-testid={`btn--decline-delete-label`}
            className="cancel"
            onClick={() => setConfirmDelete(false)}
            style={{ opacity: isDragging ? '0.5' : '' }}
          >
            No, Keep It
          </Button>
        </div>
      ) : null}
    </StyledDragDropListItem>
  );
};
