import React from 'react';

import { AriaRadioProps } from '@react-types/radio';

import { AbstractRadio, RadioGroupProps } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledRadioGroup } from './RadioButtons.styles';

/**
 * Renders a group of radio buttons.
 *
 * A group label must be included: either pass a string or markup into the
 * `label` prop, or include an `aria-label` or `aria-labelledby` attribute.
 */
export function RadioGroup(props: RadioGroupProps & PermafrostComponent) {
  const { children, ...restOfProps } = props;

  return (
    <StyledRadioGroup {...restOfProps} data-cy={props['data-cy']}>
      {children}
    </StyledRadioGroup>
  );
}

/**
 * A single radio button and its label.
 */
export function Radio(props: AriaRadioProps) {
  const { children, ...restOfProps } = props;

  return (
    <AbstractRadio {...restOfProps}>
      <svg viewBox="0 0 20 20" aria-hidden="true" focusable="false" overflow="visible">
        <circle cx={10} cy={10} r={9} fill="none" stroke="currentColor" className="radioCircle" />

        <circle cx={10} cy={10} r={5} fill="currentColor" stroke="none" className="radioDot" />

        <circle cx={10} cy={10} r={12} fill="none" className="focusRing" />
      </svg>

      {children}
    </AbstractRadio>
  );
}
