import React from 'react';

import { Icon, IconName } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledAppTitle } from './AppTitle.styles';

type Props = PermafrostComponent & {
  title: string;
  icon?: string;
  actions?: any;
};

export const AppTitle = (props: Props) => {
  const { actions, className, icon, id, title } = props;

  return (
    <StyledAppTitle className={className} data-cy={props['data-cy']} id={id}>
      {icon && <Icon name={icon as IconName} className="appIcon" />}
      <h3>{title}</h3>
      {actions && <span className="ActionLinks">{actions}</span>}
    </StyledAppTitle>
  );
};
