import React from 'react';
import classNames from 'classnames';

import { AbstractRadio } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledFlipper } from './Flipper.styles';

type Props = PermafrostComponent & {
  currentMode: string;
  groupLabel: string;
  onModeChange(mode: string): void;
  modes: string[];
  small?: boolean;
};

/**
 * Allows a user to “flip” between modes of some sort.
 */
export function Flipper(props: Props) {
  const { className, currentMode, groupLabel, id, onModeChange, modes, small } = props;

  return (
    <StyledFlipper
      aria-label={groupLabel}
      data-cy={props['data-cy']}
      value={currentMode}
      id={id}
      onChange={onModeChange}
      orientation="horizontal"
      className={classNames('switch-pair', className, { small })}
    >
      {modes.map((mode: string) => (
        <AbstractRadio key={mode} value={mode}>
          {mode}
        </AbstractRadio>
      ))}
    </StyledFlipper>
  );
}
