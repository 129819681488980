import { css } from 'styled-components';

import { ANIMATION, COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const defaultHeight = '36px';
export const largeHeight = '50px';

export const basicDefaults = css`
  border: 0;
  display: inline-block;
  cursor: pointer;

  border-radius: 2px;
  box-shadow: 0 1px 1px 0 ${COLORS.black};

  &.busy {
    cursor: wait;
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:not(.focus-visible) {
    outline: none;
  }

  transition-property: opacity, background-color;
  transition-duration: ${ANIMATION.duration};
  transition-timing-function: ${ANIMATION.timing};
`;

export const defaults = css`
  ${basicDefaults};

  height: ${defaultHeight};
  font-size: ${TYPOGRAPHY.fontSize.base};
  font-family: ${TYPOGRAPHY.fontFamily.base};

  padding: 0 1em !important;

  & + button {
    margin-left: 14px;
  }
`;

export const sizeLarge = css`
  height: ${largeHeight};
  font-size: ${TYPOGRAPHY.fontSize.subheadLarge};
`;

export const variantDefault = css`
  color: ${COLORS.darkFontColor};
  background-color: ${COLORS.iron};

  &:hover {
    background-color: #bebebe;
  }
`;

export const variantPrimary = css`
  background-color: ${COLORS.curiousBlue};
  color: ${COLORS.white};

  &:hover {
    background-color: #1777c2;
    color: ${COLORS.white};

    &:disabled {
      background-color: ${COLORS.curiousBlue} !important;
    }
  }
`;

export const variantDestructive = css`
  background-color: ${COLORS.red};
  color: ${COLORS.white};

  &:hover {
    background-color: #d4464b;
    color: ${COLORS.white};
  }
`;

// todo: remove once globals no longer need to be overridden
export const disabledOverrides = css`
  &:disabled,
  &.disabled {
    &:hover {
      // todo: override due to default global styling
      background-color: ${COLORS.iron} !important;

      &.primary {
        // todo: override due to default global styling
        background-color: ${COLORS.curiousBlue} !important;
      }

      &.destructive {
        // todo: override due to default global styling
        background-color: ${COLORS.red} !important;
      }
    }
  }
`;
