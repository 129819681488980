import React from 'react';
import { withRouter, Route, RouteComponentProps } from 'react-router-dom';
import { useAppStore, StateAndActions as AppStoreStateAndActions } from 'root/store/appStore';

type Props = {
  component: any;
} & RouteComponentProps;

const appStoreSelector = (state: AppStoreStateAndActions) => ({
  previousLocation: state.previousLocation,
  currentLocation: state.currentLocation,
});

const AppRoute = (props: Props) => {
  const { component: Component } = props;
  const { previousLocation, currentLocation } = useAppStore(appStoreSelector);

  if (
    (!currentLocation?.pathname && !previousLocation?.pathname) || //initial render of login page
    (currentLocation?.pathname !== previousLocation?.pathname && window.indico?.appcuesAccountId)
  ) {
    window.Appcues?.page();
    window.Appcues?.identify('unknownUser', {
      id: 0,
    });
  }

  return (
    <Route
      {...props}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export default withRouter(AppRoute);
