import styled from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledSaveCancel = styled.div`
  button {
    &:not(.focus-visible) {
      outline: none !important;
    }
  }

  .saveButton {
    padding: 0.75em 2em;
  }

  button.cancelButton {
    font-size: ${TYPOGRAPHY.fontSize.subheadLarge};
    margin-left: 2em;

    padding-top: 0.75em;
    padding-right: 0.75em;
    padding-bottom: 0.75em;
  }

  hr {
    margin: 1.25em 0;
    border: 1px solid transparent;
    border-bottom-color: ${COLORS.ebony};
  }
`;
