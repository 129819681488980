import React from 'react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { v4 as uuid } from 'uuid';

import { DragDropListItem } from './DragDropListItem';

import { StyledDragDropList } from './DragDropList.styles';

export type DragDropItem = {
  id: string;
  content: string | React.ReactNode;
  dragDisabled?: boolean;
};

type Props = {
  items: DragDropItem[];
  onDragDropEnd: (result: DragDropItem[]) => void; //required - function to be fired when item is drag/dropped or moved via up/down arrows
};

const droppableId = uuid();

export type UpdateItemOrder = {
  destination?: number;
  source: number;
};

export const DragDropList = ({ items, onDragDropEnd }: Props) => {
  return (
    <DragDropContext
      onDragEnd={(result: DropResult) => {
        const { destination, source } = result;

        if (!destination || destination.index === source.index) {
          return;
        } else {
          const newItemsOrder = Array.from(items);
          const movedItem = newItemsOrder.splice(source.index, 1);
          newItemsOrder.splice(destination.index, 0, movedItem[0]);
          onDragDropEnd(newItemsOrder);
        }
      }}
    >
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <StyledDragDropList ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => {
              return (
                <DragDropListItem
                  id={item.id}
                  content={item.content}
                  dragDisabled={item.dragDisabled}
                  index={index}
                  key={item.id}
                />
              );
            })}
            {provided.placeholder}
          </StyledDragDropList>
        )}
      </Droppable>
    </DragDropContext>
  );
};
