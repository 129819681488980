import { STORAGE_KEY } from 'root/constants/app';

export const storeValue = (key, value) => {
  return new Promise((resolve, reject) => {
    try {
      const rawContents = localStorage.getItem(STORAGE_KEY);
      const contents = rawContents ? JSON.parse(rawContents) : {};
      contents[key] = value;
      localStorage.setItem(STORAGE_KEY, JSON.stringify(contents));
      resolve(contents);
    } catch (e) {
      reject();
    }
  });
};

export const retrieveValue = (key) => {
  return new Promise((resolve, reject) => {
    try {
      const rawContents = localStorage.getItem(STORAGE_KEY);
      const contents = rawContents ? JSON.parse(rawContents) : {};
      if (contents.hasOwnProperty(key)) {
        resolve(contents[key]);
      } else {
        reject();
      }
    } catch (e) {
      reject();
    }
  });
};

export const clearValue = (key) => {
  return new Promise((resolve, reject) => {
    try {
      const rawContents = localStorage.getItem(STORAGE_KEY);
      const contents = rawContents ? JSON.parse(rawContents) : {};
      if (contents.hasOwnProperty(key)) {
        delete contents[key];
      }
      localStorage.setItem(STORAGE_KEY, JSON.stringify(contents));
      resolve(contents);
    } catch (e) {
      reject();
    }
  });
};

export const clearAll = () => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.removeItem(STORAGE_KEY);
    } catch (e) {
      reject();
    }
  });
};
