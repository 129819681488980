export const UNSPECIFIED = 'Unspecified Error'; // Catch all error type
export const LOGIN_REQUIRED = 'LoginRequired';
export const EXPIRED_TOKEN = 'ExpiredTokenException';
export const INVALID_TOKEN = 'InvalidTokenException';
export const INVALID_REQUEST = 'InvalidRequestException';
export const BLACKLIST = 'InvalidEmail';
export const ACCOUNT_LOCKED = 'AccountLocked';
export const SESSION_LIMIT = 'SessionLimit';
export const CONFIRMATION_REQUIRED = 'ConfirmationRequired';
export const EXPIRED_ACCOUNT = 'ExpiredAccount';
