import styled from 'styled-components';

import { TYPOGRAPHY } from 'Permafrost/index';

export const StyledContentModal = styled.div`
  padding: 20px;

  .title {
    margin: 30px 16px;
    font-size: ${TYPOGRAPHY.fontSize.display};
    text-align: center;
  }
`;
