import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import { Draggable, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';

import { ItemPartial } from '../index';
import { ListItemContextType } from '../ReorderableList';

import { StyledListItem } from './ListItem.styles';

type Props<T extends ItemPartial> = {
  addItemHeight(height: number, id: string): void;
  animating?: { distance: number; direction: string };
  className?: string;
  item: T;
  index: number;
  itemsVerticalGap: number;
  listLength: number;
  swapDuration: number;

  moveItem(direction: 'up' | 'down'): void;

  deleteItem?(id: string): void;
  InnerComponent: React.ComponentType;
};

const ListItemContext = createContext({} as ListItemContextType<any>);
ListItemContext.displayName = 'ReorderableListItem';

/**
 * When this component has rendered it will measure its own height, which will be used by the
 * parent component to determine its animation if this item has been programmatically reordered
 * (e.g. `ListControls` up/down arrow button has been pressed); item heights currently have no
 * other purpose.
 *
 * `itemsVerticalGap` should be used in place of margins to separate the items in the list;
 * it is applied to the container `<div>` below as padding-bottom.
 */
export function ListItem<T extends ItemPartial>(props: Props<T>) {
  const listItemEl = useRef<HTMLDivElement>(null);

  const {
    animating,
    moveItem,
    addItemHeight,
    deleteItem,
    item,
    index,
    itemsVerticalGap,
    listLength,
    swapDuration,
    InnerComponent,
  } = props;

  const { id } = item;

  // tracks whether an arrow has been pressed on this item
  const [active, setActive] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editing, setEditing] = useState(false);

  // handler for a “move up/down” action - this is not used by drag/drop.
  const handleMoveItem = (direction: 'up' | 'down') => {
    setActive(true);
    moveItem(direction);
  };

  const measureSelf = () => {
    if (listItemEl && listItemEl.current) {
      const box = listItemEl.current.getBoundingClientRect();

      addItemHeight(box.height, id);
    }
  };

  // see this component’s style file for what this class is doing
  const animationClassName = animating ? `animate-${animating.direction}` : '';

  // remove “active” state after the item has been reordered
  useEffect(() => {
    setActive(false);
  }, [index]);

  // measure and store the height of this rendered component
  useLayoutEffect(() => {
    measureSelf();
  }, []);

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <StyledListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classNames('list-item-container', animationClassName, {
            active,
            dragging: snapshot.isDragging,
          })}
          $animationDistance={animating ? animating.distance : 0}
          $animationDuration={swapDuration}
          style={{ ...provided.draggableProps.style, paddingBottom: `${itemsVerticalGap}px` }}
        >
          <ListItemContext.Provider
            value={{
              active,
              confirmDelete,
              deleteItem: () => deleteItem && deleteItem(id),
              dragging: snapshot.isDragging,
              editing,
              index,
              item,
              isFirstItem: index === 0,
              isLastItem: index === listLength - 1,
              moveItemDown: () => handleMoveItem('down'),
              moveItemUp: () => handleMoveItem('up'),
              setConfirmDelete,
              setEditing,
            }}
          >
            <div
              ref={listItemEl}
              style={{
                width: '100%',
              }}
            >
              <InnerComponent />
            </div>
          </ListItemContext.Provider>
        </StyledListItem>
      )}
    </Draggable>
  );
}

/**
 * Tracks status for a particular item in the list.
 */
export function useListItem() {
  return useContext(ListItemContext);
}
