export const Linear = {
  None(k: number) {
    return k;
  },
};

export const Quadratic = {
  /**
   * @return {number}
   */
  In(k: number) {
    return k * k;
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    return k * (2 - k);
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    if ((k *= 2) < 1) {
      return 0.5 * k * k;
    }

    return -0.5 * (--k * (k - 2) - 1);
  },
};

export const Cubic = {
  /**
   * @return {number}
   */
  In(k: number) {
    return k * k * k;
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    return --k * k * k + 1;
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    if ((k *= 2) < 1) {
      return 0.5 * k * k * k;
    }

    return 0.5 * ((k -= 2) * k * k + 2);
  },
};

export const Quartic = {
  /**
   * @return {number}
   */
  In(k: number) {
    return k * k * k * k;
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    return 1 - --k * k * k * k;
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    if ((k *= 2) < 1) {
      return 0.5 * k * k * k * k;
    }

    return -0.5 * ((k -= 2) * k * k * k - 2);
  },
};

export const Quintic = {
  /**
   * @return {number}
   */
  In(k: number) {
    return k * k * k * k * k;
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    return --k * k * k * k * k + 1;
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    if ((k *= 2) < 1) {
      return 0.5 * k * k * k * k * k;
    }

    return 0.5 * ((k -= 2) * k * k * k * k + 2);
  },
};

export const Sinusoidal = {
  /**
   * @return {number}
   */
  In(k: number) {
    return 1 - Math.cos((k * Math.PI) / 2);
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    return Math.sin((k * Math.PI) / 2);
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    return 0.5 * (1 - Math.cos(Math.PI * k));
  },
};

export const Exponential = {
  /**
   * @return {number}
   */
  In(k: number) {
    return k === 0 ? 0 : Math.pow(1024, k - 1);
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    return k === 1 ? 1 : 1 - Math.pow(2, -10 * k);
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    if (k === 0) {
      return 0;
    }

    if (k === 1) {
      return 1;
    }

    if ((k *= 2) < 1) {
      return 0.5 * Math.pow(1024, k - 1);
    }

    return 0.5 * (-Math.pow(2, -10 * (k - 1)) + 2);
  },
};

export const Circular = {
  /**
   * @return {number}
   */
  In(k: number) {
    return 1 - Math.sqrt(1 - k * k);
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    return Math.sqrt(1 - --k * k);
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    if ((k *= 2) < 1) {
      return -0.5 * (Math.sqrt(1 - k * k) - 1);
    }

    return 0.5 * (Math.sqrt(1 - (k -= 2) * k) + 1);
  },
};

export const Elastic = {
  /**
   * @return {number}
   */
  In(k: number) {
    if (k === 0) {
      return 0;
    }

    if (k === 1) {
      return 1;
    }

    return -Math.pow(2, 10 * (k - 1)) * Math.sin((k - 1.1) * 5 * Math.PI);
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    if (k === 0) {
      return 0;
    }

    if (k === 1) {
      return 1;
    }

    return Math.pow(2, -10 * k) * Math.sin((k - 0.1) * 5 * Math.PI) + 1;
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    if (k === 0) {
      return 0;
    }

    if (k === 1) {
      return 1;
    }

    k *= 2;

    if (k < 1) {
      return -0.5 * Math.pow(2, 10 * (k - 1)) * Math.sin((k - 1.1) * 5 * Math.PI);
    }

    return 0.5 * Math.pow(2, -10 * (k - 1)) * Math.sin((k - 1.1) * 5 * Math.PI) + 1;
  },
};

export const Back = {
  /**
   * @return {number}
   */
  In(k: number) {
    const s = 1.70158;

    return k * k * ((s + 1) * k - s);
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    const s = 1.70158;

    return --k * k * ((s + 1) * k + s) + 1;
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    const s = 1.70158 * 1.525;

    if ((k *= 2) < 1) {
      return 0.5 * (k * k * ((s + 1) * k - s));
    }

    return 0.5 * ((k -= 2) * k * ((s + 1) * k + s) + 2);
  },
};

export const Bounce = {
  /**
   * @return {number}
   */
  In(k: number) {
    return 1 - this.Out(1 - k);
  },

  /**
   * @return {number}
   */
  Out(k: number) {
    if (k < 1 / 2.75) {
      return 7.5625 * k * k;
    } else if (k < 2 / 2.75) {
      return 7.5625 * (k -= 1.5 / 2.75) * k + 0.75;
    } else if (k < 2.5 / 2.75) {
      return 7.5625 * (k -= 2.25 / 2.75) * k + 0.9375;
    } else {
      return 7.5625 * (k -= 2.625 / 2.75) * k + 0.984375;
    }
  },

  /**
   * @return {number}
   */
  InOut(k: number) {
    if (k < 0.5) {
      return this.In(k * 2) * 0.5;
    }

    return this.Out(k * 2 - 1) * 0.5 + 0.5;
  },
};

export const Stepped = {
  steps: (steps: number) => (k: number) => ((k * steps) | 0) / steps,
};
