// App Deps
import * as StorageAPI from 'root/providers/storage-provider';
import { STORAGE_VALUE_EMAIL } from 'root/constants/app';

// Auth Deps
import * as ERROR_CODES from 'Auth/constants/error-codes';
import * as MESSAGES from 'Auth/constants/messages';
import {
  postUserLoginAPI,
  userLogoutAPI,
  verifyUserAPI,
  getXSRFTokenAPI,
} from 'Auth/providers/auth-provider';
import { AddToast } from 'root/components/messaging/ToastsList/ToastsList';

export const getXSRFToken = (signal: any) => {
  return getXSRFTokenAPI(signal).then((token) => {
    return token;
  });
};

export const verifyUser = () => {
  return verifyUserAPI()
    .then((user) => {
      return user;
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
};

export const authenticateUser = (
  user: {
    email: string;
    password: string;
    rememberMe: boolean;
    xsrfToken?: string;
  },
  dispatch: any,
  addToast: AddToast,
  captchaToken?: string
) => {
  if (user.rememberMe) {
    StorageAPI.storeValue(STORAGE_VALUE_EMAIL, user.email);
  } else {
    StorageAPI.clearValue(STORAGE_VALUE_EMAIL);
  }
  const { email, password } = user;

  dispatch({ type: 'SET_LOADING', loading: true });

  return postUserLoginAPI({ email, password }, user?.xsrfToken || '', captchaToken)
    .then(() => {
      addToast(MESSAGES.LOGIN_SUCCESS, 'success');
      return verifyUser().then((user) => {
        dispatch({ type: 'SET_USER', user, loading: false });
      });
    })
    .catch((err: any) => {
      if (err.error_type === ERROR_CODES.LOGIN_REQUIRED) {
        addToast(MESSAGES.LOGIN_FAILED, 'error');
      } else if (err.error_type === ERROR_CODES.ACCOUNT_LOCKED) {
        addToast(MESSAGES.ACCOUNT_LOCKED, 'error');
      } else if (err.error_type === ERROR_CODES.SESSION_LIMIT) {
        addToast(MESSAGES.SESSION_LIMIT, 'error');
      } else if (err.error_type === ERROR_CODES.EXPIRED_ACCOUNT) {
      } else {
        if (err.error_type === ERROR_CODES.CONFIRMATION_REQUIRED) {
          addToast(MESSAGES.LOGIN_ERROR_CONFIRMATION_REQUIRED, 'error');
        } else {
          addToast(MESSAGES.LOGIN_ERROR, 'error');
        }
      }

      dispatch({ type: 'SET_LOADING', loading: false });

      return Promise.reject({ type: err.error_type });
    });
};

export const logoutUser = (dispatch: any, xsrfToken: string, addToast: AddToast) => {
  return userLogoutAPI(xsrfToken)
    .then(() => {
      addToast(MESSAGES.LOGOUT_SUCCESS, 'success');
      dispatch({ type: 'SET_USER', user: null, loading: false });
    })
    .catch(() => {
      addToast(MESSAGES.LOGOUT_FAILED, 'error');
    });
};
