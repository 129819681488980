import styled from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledFailSnail = styled.div`
  text-align: center;

  svg {
    display: block;
    width: 300px;
    margin: 150px auto 25px;

    opacity: 0.5;
  }

  path,
  ellipse {
    fill: ${COLORS.iron};
  }

  .message {
    margin-bottom: 25px;
    font-size: ${TYPOGRAPHY.fontSize.display};
    color: ${COLORS.defaultFontColor};
  }
`;
