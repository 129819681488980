import React from 'react';
import classNames from 'classnames';

import { LoadingIndicator } from 'Permafrost/index';

import { StyledLoadingAwareContainer, StyledStatusContainer } from './LoadingAwareContainer.styles';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
};

/**
 * A functional container for content that is yet to load or will change; displays a loading indicator and announces
 * to Assistive Technology as needed.
 *
 * todo: this is an extremely simple version of this component; as I get more familiar with LoadingIndicator use cases, this will be refactored. - jm 9/1/2020
 */
export function LoadingAwareContainer(props: Props): React.ReactElement {
  const { children, className, isLoading, style } = props;

  return (
    <StyledLoadingAwareContainer
      className={classNames({loading: isLoading}, className)}
      style={style}
    >
      <StyledStatusContainer role="status">{isLoading && 'Loading'}</StyledStatusContainer>

      {isLoading ? <LoadingIndicator /> : <>{children}</>}
    </StyledLoadingAwareContainer>
  );
}
