export const PARAM_AUTH_REDIRECT_KEY = 'redirect_url';
export const PARAM_TOKEN_KEY = 'token';

/**
 * Strips any leading hash or question mark characters from the string
 * so that we can parse it correctly.
 * @param urlFragment
 * @returns {*}
 */
const cleanUrlFragment = (urlFragment: string) => {
  while (urlFragment[0] === '#' || urlFragment[0] === '?') {
    urlFragment = urlFragment.substr(1);
  }
  return urlFragment;
};

/**
 * Parses the query param string for a redirect URL
 * @param urlFragment
 * @returns {null}
 */
export const parseRedirect = (urlFragment: string) => {
  urlFragment = cleanUrlFragment(urlFragment);
  const queryParams = new URLSearchParams(urlFragment);
  if (queryParams.has(PARAM_AUTH_REDIRECT_KEY)) {
    return queryParams.get(PARAM_AUTH_REDIRECT_KEY);
  } else {
    return null;
  }
};

/**
 * Parses the query param string for a JOT Token
 * @param urlFragment
 * @returns {null}
 */
export const parseToken = (urlFragment: string) => {
  urlFragment = cleanUrlFragment(urlFragment);
  const queryParams = new URLSearchParams(urlFragment);

  if (queryParams.has(PARAM_TOKEN_KEY)) {
    return queryParams.get(PARAM_TOKEN_KEY);
  } else {
    return null;
  }
};
