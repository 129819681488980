import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 42px;
  margin-bottom: 12px;

  .title {
    font-size: 22px;
    margin: 0;
  }

  .count {
    font-size: 18px;
    margin: 4px 0 0 10px;
  }

  .title-container,
  .controls-container,
  .sort-by {
    display: inherit;
  }

  .sort-by {
    margin-left: 10px;

    label,
    .button-sort-direction,
    .button-sort-direction:focus {
      color: ${COLORS.lightFontColor};
    }

    label {
      font-weight: normal;
    }

    .button-sort-direction {
      margin-left: 10px;

      svg {
        position: relative;
        bottom: 3px;
        transition: 0.3s transform ease-in-out;
      }
    }
  }

  .select-container {
    box-sizing: border-box;

    .selectCaret {
      color: ${COLORS.defaultFontColor};
      height: 16px;
      width: 16px;
      margin: 0;
    }
  }

  .Header__flag {
    margin: 3px 0 0 10px;
  }

  &.flag-message {
    margin-bottom: 4px;
  }
`;
