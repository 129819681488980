import React from 'react';
// import PropTypes from 'prop-types';

import { StyledChartToolTip } from './ChartToolTip.styles';

export const ChartToolTip = (props) => {
  const { children, className, id, xPos, yPos } = props;

  return (
    <StyledChartToolTip
      className={className}
      data-cy={props['data-cy']}
      id={id}
      style={{ top: yPos, left: xPos }}
    >
      <div className="Tooltip-Contents">{children}</div>
    </StyledChartToolTip>
  );
};

// ChartToolTip.propTypes = {
//     xPos: PropTypes.number.isRequired,
//     yPos: PropTypes.number.isRequired
// };
