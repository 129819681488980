import { createGlobalStyle } from 'styled-components';

import { currentColors } from 'Permafrost/design-language/colors';
import { allColors } from '../../constants/colors';

/**
 * todo: this file is intended to be a placeholder to demonstrate possibilities for
 *  refactoring our colors management
 */

//  This convenience object provides a list of available color property names.
export const colorProperties = {
  appBackground: '--app-background',
  cardBackground: '--card-background',
  menuBackground: '--menu-background',

  buttonBackgroundPrimary: '--button-background-primary',

  focusRing: '--focus-ring',

  logoPrimary: '--logo-primary',
  logoSecondary: '--logo-secondary',

  textError: '--text-error',
  textHighlight: '--text-highlight',
  textPrompt: '--text-prompt',
} as const;

export const ColorProperties = createGlobalStyle`
  :root {
    --app-background: ${allColors.backgroundColor};
    --card-background: ${allColors.clay};
    --menu-background: ${allColors.ebony};

    --button-background-primary: ${currentColors.indicoBlue};

    --focus-ring: ${currentColors.cyan};

    --logo-primary: ${currentColors.indicoBlue};
    --logo-secondary: ${allColors.white};

    --text-error: ${allColors.fieldPromptError};
    --text-highlight: ${currentColors.orange};
    --text-prompt: ${allColors.fieldPromptInfo};
  }
`;
