import { createGlobalStyle } from 'styled-components';

import { COLORS, TYPOGRAPHY, ANIMATION } from '../../constants';

export const Typography = createGlobalStyle`
  html {
    color: ${COLORS.defaultFontColor};
    font-family: ${TYPOGRAPHY.fontFamily.base};
    font-size: 100%;
    line-height: ${TYPOGRAPHY.lineHeight.base};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-style: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${TYPOGRAPHY.fontFamily.base};
    font-size: ${TYPOGRAPHY.fontSize.display};
    line-height: ${TYPOGRAPHY.lineHeight.heading};
    margin: 0 0 10px;
    color: ${COLORS.lightGray};
    font-weight: 400;
    font-style: normal;
  }

  h3 {
    font-size: ${TYPOGRAPHY.fontSize.subheadLarge};
  }

  h4 {
    font-size: ${TYPOGRAPHY.fontSize.base};
    color: ${COLORS.defaultFontColor};
  }

  h5 {
    font-size: ${TYPOGRAPHY.fontSize.caption};
    color: ${COLORS.defaultFontColor};
  }

  p {
    margin: 0 0;
    font-size: ${TYPOGRAPHY.fontSize.base};
  }

  .t-caption { font-size: ${TYPOGRAPHY.fontSize.caption}; }
  .t-base { font-size: ${TYPOGRAPHY.fontSize.base}; }
  .t-subhead-small { font-size: ${TYPOGRAPHY.fontSize.subheadSmall}; }
  .t-subhead-large { font-size: ${TYPOGRAPHY.fontSize.subheadLarge}; }
  .t-display { font-size: ${TYPOGRAPHY.fontSize.display}; }

  .t-bold { font-weight: bold; }
  .t-italic { font-style: italic; }

  .error {
    color: ${COLORS.red};
  }

  .prompt {
    color: ${COLORS.curiousBlue};
  }

  .warning {
    color: ${COLORS.yellow};
  }

  .success {
    color: ${COLORS.green};
  }

  a {
    color: ${COLORS.actionColor};
    transition: color ${ANIMATION.duration} ${ANIMATION.timing};

    &:active,
    &:focus,
    &:hover {
      color: ${COLORS.defaultFontColor};
      text-decoration: none;
    }
  }

  hr {
    border-bottom: 1px solid ${COLORS.borderColor};
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin: ${TYPOGRAPHY.spacing.base} 0;
  }
`;
