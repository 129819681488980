import React from 'react';
import { createRoot } from 'react-dom/client';

if (module.hot) {
  module.hot.accept();
}

import { Root } from './Root';

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer!);
root.render(<Root />);
