import React from 'react';

import { v4 as uuid } from 'uuid';

import { ModalBaseProps, ModalBase } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledContentModal } from './ContentModal.styles';

export type ContentModalProps = PermafrostComponent &
  Pick<ModalBaseProps, 'children' | 'open'> & {
    describedBy?: string;
    closeModal(): void;
    modalNode?: string;
    width?: number;
  } & ({ title: string | React.ReactNode } | { labelText: string });

export function ContentModal(props: ContentModalProps) {
  const id = uuid();
  const labelText = 'labelText' in props && props.labelText;
  const title = 'title' in props && props.title;
  const { children, className, closeModal, describedBy, modalNode, open, width } = props;

  return (
    <ModalBase
      className={className}
      clickToDismiss={closeModal}
      data-cy={props['data-cy']}
      describedBy={describedBy}
      id={props.id}
      labelElement={title ? id : ''}
      labelText={labelText || ''}
      node={modalNode}
      open={open}
    >
      <StyledContentModal style={{ width: width || 555 }}>
        {title && (
          <div id={id} className="title">
            {title}
          </div>
        )}

        {children}
      </StyledContentModal>
    </ModalBase>
  );
}
