import React, { createContext, useRef } from 'react';

import classNames from 'classnames';

import { AriaCheckboxGroupProps, CheckboxGroupProps } from '@react-types/checkbox';
import { useCheckboxGroup, useCheckboxGroupItem } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import { useVisuallyHidden } from '@react-aria/visually-hidden';

import { PermafrostComponent, PillProps } from 'Permafrost/types';
import { Icon, COLORS, Tooltip } from 'Permafrost/index';

import { StyledPillCheckGroupBase } from './PillCheckGroupBase.styles';
import { StyledPill } from './Pill.styles';
import { CheckboxGroupState, useCheckboxGroupState } from 'react-stately';

export type PillCheckGroupProps = PermafrostComponent & {
  id?: string;
  children: React.ReactNode;
  className?: string;
  disableGroup?: boolean;
  enforceSingleValue?: boolean;
  isReadOnly?: boolean;
  label?: string;
  verticalOrientation?: boolean;
  values: string[];
  onChange: (value: string[]) => void;
} & AriaCheckboxGroupProps;

export const PillCheckGroupContext = createContext<CheckboxGroupState>({} as CheckboxGroupState);

/**
 * Renders a group of 'pill checkboxes'.
 *
 * A group label must be included: either pass a string or markup into the
 * `label` prop, or include an `aria-label` or `aria-labelledby` attribute.
 */
export function PillCheckGroupBase(props: PillCheckGroupProps) {
  const {
    id,
    children,
    className,
    disableGroup = false,
    isReadOnly = false,
    label,
    enforceSingleValue = false,
    onChange,
    verticalOrientation,
    values,
  } = props;

  const checkboxGroupProps: CheckboxGroupProps = {
    value: values,
    isDisabled: disableGroup,
    isReadOnly: isReadOnly,
    onChange: (newValue: string[]) => {
      if (isReadOnly || disableGroup) {
        return;
      }

      if (enforceSingleValue && newValue.length > 1) {
        onChange([newValue[newValue.length - 1]]);
      } else {
        onChange(newValue);
      }
    },
  };

  const state = useCheckboxGroupState(checkboxGroupProps);

  const { groupProps, labelProps } = useCheckboxGroup(props, state);

  return (
    <StyledPillCheckGroupBase
      id={id}
      className={classNames(className, {
        'PillCheckGroupBase--horizontal': verticalOrientation !== true,
        'PillCheckGroupBase--disabled': disableGroup,
      })}
      data-cy={props['data-cy']}
      data-testid={props['data-testid']}
      {...groupProps}
    >
      {label && (
        <div className="PillCheckGroup__group-label" {...labelProps}>
          {label}
        </div>
      )}

      <PillCheckGroupContext.Provider value={state}>{children}</PillCheckGroupContext.Provider>
    </StyledPillCheckGroupBase>
  );
}

export function Pill(props: PillProps) {
  const {
    id,
    caption,
    captionColor,
    children,
    className,
    fixedWidth,
    isDisabled = false,
    isSelected,
    pillTextColor,
    tooltip,
    iconName = 'check-circle',
    iconColor = COLORS.fieldPromptInfo,
  } = props;

  const state = React.useContext(PillCheckGroupContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckboxGroupItem(props, state, inputRef);

  const { isFocusVisible, focusProps } = useFocusRing();
  const { visuallyHiddenProps } = useVisuallyHidden();
  const checkMeasure = 12;

  return (
    <StyledPill
      id={id}
      $captionColor={captionColor || 'inherit'}
      className={classNames({ 'Pill__label--checked': isSelected, isDisabled }, className)}
      data-cy={props['data-cy']}
      data-testid={props['data-testid']}
      data-for={id}
      data-tip
      $fixedWidth={fixedWidth}
      $pillTextColor={pillTextColor || 'inherit'}
    >
      <input
        ref={inputRef}
        type="checkbox"
        {...focusProps}
        {...inputProps}
        {...visuallyHiddenProps}
      />
      <div
        className={classNames(
          className,
          {
            'Pill__pill-ellipse--focusRing': isFocusVisible === true,
            'Pill__pill-ellipse--selected': isSelected === true,
          },
          'pill-ellipse'
        )}
      >
        <span className="pill-text">{children}</span>
        {isSelected && (
          <Icon name={iconName} className="pill-icon" size={[checkMeasure]} fill={iconColor} />
        )}
      </div>
      {caption && <span className="caption">{caption}</span>}
      {tooltip ? <Tooltip for={id}>{tooltip}</Tooltip> : null}
    </StyledPill>
  );
}
