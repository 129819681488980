import React, { useState } from 'react';

import { PermafrostComponent } from 'Permafrost/types';

type Props = PermafrostComponent & {
  cx: number;
  cy: number;
  fill?: string;
  fillOpacity?: number;
  radius?: number;
  showOnHover?: boolean;
  stroke?: string;
  strokeOpacity?: number;
  strokeWidth?: number;
  toolTipKey?: string;
  onClick?(event: React.MouseEvent): void;
};

export function Circle(props: Props) {
  const {
    className,
    cx,
    cy,
    fill = 'white',
    fillOpacity = 1,
    id,
    onClick,
    radius = 10,
    showOnHover,
    stroke = 'white',
    strokeOpacity = 0,
    strokeWidth = 1,
    toolTipKey,
  } = props;

  const [hovered, setHovered] = useState<boolean>(false);

  const effectiveFillOpacity = !showOnHover || hovered ? fillOpacity : 0;
  const effectiveStokeOpacity = !showOnHover || hovered ? strokeOpacity : 0;

  const mouseEnterHandler = () => {
    setHovered(true);
  };

  const mouseLeaveHandler = () => {
    setHovered(false);
  };

  return (
    <circle
      className={className}
      style={{ cursor: 'pointer' }}
      r={radius}
      cx={cx}
      cy={cy}
      fill={fill}
      fillOpacity={effectiveFillOpacity}
      id={id}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeOpacity={effectiveStokeOpacity}
      data-cy={props['data-cy']}
      data-tip={toolTipKey !== null}
      data-for={toolTipKey}
      onClick={onClick}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    />
  );
}
