import React from 'react';

import { Button, FailSnail } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

type Props = PermafrostComponent & {
  message?: string | React.ReactNode;
  style?: React.CSSProperties;
};

export const AppCrash: React.FC<Props> = (props: Props) => {
  const { className, id, style } = props;

  const message = props.message || 'Unfortunately the app has encountered a fatal error.';

  return (
    <FailSnail
      className={className}
      data-cy={props['data-cy']}
      id={id}
      message={message}
      style={style}
    >
      <Button
        variant="cta"
        onClick={() => {
          window.location.reload();
        }}
      >
        Refresh
      </Button>
    </FailSnail>
  );
};
