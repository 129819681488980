import React, { ChangeEventHandler, useState, useRef, useEffect } from 'react';

import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { usePopper } from 'react-popper';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledDateRangePicker } from './DateRangePicker.styles';
import { StyledDatePicker } from '../DatePicker/DatePicker.styles';

type Props = PermafrostComponent & {
  // From Date props
  fromPlaceholder?: string;
  fromValue?: Date;
  fromOnChange: (value: Date | undefined) => void;

  // To Date props
  toPlaceholder?: string;
  toValue?: Date;
  toOnChange: (value: Date | undefined) => void;

  // General props
  id: string;
  dataCy: string;
  disabled?: boolean;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  label?: string;
  vertical?: boolean;
  defaultSelected?: DateRange;
  minRange?: number;
  maxRange?: number;
};

export const DateRangePicker = (props: Props) => {
  const {
    dataCy,
    id,
    className,
    disabled,
    label,
    fromOnChange,
    toOnChange,
    fromValue,
    toValue,
    fromPlaceholder,
    toPlaceholder,
    vertical,
    disableBeforeDate,
    disableAfterDate,
    defaultSelected,
    minRange,
    maxRange,
  } = props;

  const initSelectedRange = () => {
    if (fromValue || toValue) {
      return {
        from: isValid(fromValue) ? fromValue : undefined,
        to: isValid(toValue) ? toValue : undefined,
      };
    }
    return {
      from: isValid(defaultSelected?.from) ? defaultSelected?.from : undefined,
      to: isValid(defaultSelected?.to) ? defaultSelected?.to : undefined,
    };
  };

  const [selectedRange, setSelectedRange] = useState<DateRange>(initSelectedRange());
  const [fromInputValue, setFromInputValue] = useState<string>(
    isValid(fromValue) ? format(fromValue as Date, 'y-MM-dd') : ''
  );
  const [toInputValue, setToInputValue] = useState<string>(
    isValid(toValue) ? format(toValue as Date, 'y-MM-dd') : ''
  );
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  useEffect(() => {
    // todo: this is problematic because it means on mount the onChange handler will always be fired
    // therefore - if the onChange is some heavy operation or call to the BE - that will automatically get called
    // without the user even doing anything
    fromOnChange(selectedRange?.from);
    toOnChange(selectedRange?.to);
  }, [selectedRange]);

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromInputValue(e.target.value);
    const date = parse(e.target.value, 'y-MM-dd', new Date());
    if (!isValid(date)) {
      return setSelectedRange({ from: undefined, to: undefined });
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  };

  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToInputValue(e.target.value);
    const date = parse(e.target.value, 'y-MM-dd', new Date());

    if (!isValid(date)) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  };

  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
    setSelectedRange(range as DateRange);
    if (range?.from) {
      setFromInputValue(format(range.from, 'y-MM-dd'));
    } else {
      setFromInputValue('');
    }
    if (range?.to) {
      setToInputValue(format(range.to, 'y-MM-dd'));
      closePopper();
    } else {
      setToInputValue('');
    }
  };

  return (
    <StyledDateRangePicker id={id} className={className} data-cy={dataCy}>
      {label ? (
        <label className="datepicker-label" htmlFor={`date-range-picker-inputs--${id}`}>
          {label}
        </label>
      ) : null}
      <StyledDatePicker className={classNames({ vertical: vertical })} ref={popperRef}>
        <form
          id={`date-range-picker-inputs--${id}`}
          className="date-range-picker-inputs"
          onClick={() => (disabled ? null : setIsPopperOpen(!isPopperOpen))}
        >
          <input
            disabled={disabled}
            size={10}
            placeholder={fromPlaceholder || 'From Date'}
            value={fromInputValue}
            onChange={handleFromChange}
            className="datepicker--input"
          />
          {!vertical ? <hr className="spacer" /> : null}
          <input
            disabled={disabled}
            size={10}
            placeholder={toPlaceholder || 'To Date'}
            value={toInputValue}
            onChange={handleToChange}
            className="datepicker--input"
          />
        </form>
        {isPopperOpen && (
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
              onDeactivate: closePopper,
            }}
          >
            <div
              tabIndex={-1}
              style={popper.styles.popper}
              className="DayPickerInput-Overlay"
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
            >
              <DayPicker
                disabled={disabled}
                mode="range"
                selected={selectedRange}
                onSelect={handleRangeSelect}
                fromDate={disableBeforeDate}
                toDate={disableAfterDate}
                min={minRange}
                max={maxRange}
              />
            </div>
          </FocusTrap>
        )}
      </StyledDatePicker>
    </StyledDateRangePicker>
  );
};
