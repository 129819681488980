import React, { useContext } from 'react';

import UserContext from './store/userContext';
import { verifyUser } from './modules/auth/actions/auth-actions';

import { MainRouterWrapper } from './MainRouterWrapper';

export const AppContainer = () => {
  const dispatch = useContext(UserContext)[1];
  return <MainRouterWrapper updateUserContext={dispatch} verifyUser={verifyUser} />;
};
