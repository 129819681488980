import styled from 'styled-components';

import { MEDIA_QUERIES, COLORS, TYPOGRAPHY, colorProperties } from '@indico-data/permafrost';

const navWidthOpen = '240px';
const navWidthClosed = '50px';
const navPadding = '30px';
const navTextColor = COLORS.baliHai;
const navActiveTextColor = COLORS.hiliteFontColor;
const navActiveIconColor = colorProperties.logoPrimary;
const hrColor = '#2b3c4c';
const navDuration = '250ms';
const navIconSize = '25px';

export const StyledAppChrome = styled.div`
  width: 100%;

  .AppNavigation {
    background-color: var(${colorProperties.menuBackground});

    width: ${navWidthOpen};
    overflow: hidden;
    transition: width ${navDuration} ease-out, padding ${navDuration} ease-out;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-top: 20px;
    z-index: 500;

    .AppBranding {
      display: flex;
      align-items: start;
      justify-content: space-between;

      padding-left: ${navPadding};
      padding-bottom: ${navPadding};

      .AppChrome--Toggle svg {
        margin-top: 2px;
        color: ${navTextColor};
      }
    }

    .AppBranding,
    nav {
      padding-right: ${navPadding};
      padding-left: ${navPadding};
    }

    hr {
      border-bottom: 1px solid ${hrColor};
    }

    .LinkList {
      li {
        margin-bottom: 20px;
        line-height: 0; // tightens up the space at the bottom of the container
      }

      .LinkList-Icon svg {
        width: ${navIconSize};
        height: ${navIconSize};

        path {
          fill: ${navTextColor};
        }
      }

      .LinkList-Text {
        display: inline-block;
        font-size: 14px;
        margin-left: 10px;
        color: ${navTextColor};
        opacity: 1;
        transition: width ${navDuration} ease-out, opacity ${navDuration} ease-out;
        vertical-align: top;
        padding-top: 5px;
        line-height: 1;
      }

      .AppChrome--link {
        display: inline-block;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .AppChrome--link:hover,
      .AppChrome--link.current {
        .LinkList-Icon {
          path {
            fill: var(${navActiveIconColor});
          }
        }

        .LinkList-Text {
          color: ${navActiveTextColor};
        }
      }
    }
  }

  .AppContents {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: ${navWidthClosed};
    transition: padding ${navDuration} ease-out;

    @media ${MEDIA_QUERIES.largeScreen} {
      padding-left: ${navWidthOpen};
    }
  }

  .ipa-version {
    position: absolute;
    bottom: 20px;
    margin-left: ${navPadding};

    font-size: ${TYPOGRAPHY.fontSize.base};
    text-transform: uppercase;

    .trial {
      margin-right: 6px;

      color: var(${colorProperties.textHighlight});
    }

    .version {
      color: ${COLORS.blueBayoux};
    }
  }

  &.closed {
    .AppNavigation {
      width: ${navWidthClosed};

      .AppLogo {
        margin-left: -1px;
      }

      .AppLogoCollapsed {
        display: inline-block;
      }

      .LinkList .LinkList-Text {
        opacity: 0;
      }
    }

    .AppChrome--Toggle svg {
      opacity: 0;
    }

    .ipa-version {
      margin-left: initial;
      font-size: 11px;
      text-align: center;

      .trial {
        display: block;
        margin-right: initial;
      }
    }

    .AppBranding,
    nav {
      padding-left: 13px;
      padding-right: 13px;
    }

    .AppContents {
      padding-left: ${navWidthClosed} !important;
    }
  }
`;
