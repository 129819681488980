import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

const MatrixXLabelSize = '100px';
const MatrixYLabelSize = '120px';

export const StyledMatrix = styled.div`
  position: relative;

  .Matrix-Frame {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 2px;

    td,
    tr,
    th,
    td {
      border: 0;
      padding: 0;
      margin: 0;
    }

    .Matrix-Cell {
      position: relative;

      &:after {
        content: '';
        display: block;
        margin-top: 100%;
      }

      &:hover {
        border: 2px solid ${COLORS.magenta};
        cursor: pointer;
      }

      &.selected {
        border: 2px solid ${COLORS.magenta};
      }

      .Contents {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
      }
    }

    .Matrix-Label-Row,
    .Matrix-Label-Col {
      font-size: 12px;
      color: ${COLORS.midFontColor};
    }

    .Matrix-Label-Row {
      width: ${MatrixXLabelSize};
      padding-right: 8px;

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
      }
    }

    .Matrix-Label-Col {
      height: ${MatrixYLabelSize};
      position: relative;
      vertical-align: bottom;

      .rotate {
        transform-origin: 0 50%;
        transform: rotate(-90deg);
        white-space: nowrap;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;

        span {
          display: inline-block;
          width: ${MatrixYLabelSize} - 15px;
          overflow: hidden;
          white-space: nowrap;
          text-align: right;
          text-overflow: ellipsis;
        }
      }
    }

    .Matrix-Axis-X,
    .Matrix-Axis-Y {
      font-size: 14px;
      color: ${COLORS.midFontColor};
    }

    .Matrix-Axis-X {
      text-align: center;
      padding-bottom: 10px;
    }

    .Matrix-Axis-Y {
      width: 25px;
      span {
        position: relative;
        display: block;
        top: 50%;
        transform: rotate(90deg) translateX(-50%);
      }
    }
  }
`;
