import React from 'react';

import classNames from 'classnames';

import { stringUtils } from '@indico-data/utils';

import { Button, Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledTeamTable } from './TeamTable.styles';

export type TeamTableMember = {
  // will apply a css class to the entire row for this team member, if included
  rowClassName?: string;
  email: React.ReactNode;
  role: string;
  userId: number | string;
};

type Props = PermafrostComponent & {
  // the id of another html element to use as a label
  labelledby: string;
  owner?: TeamTableMember;
  users: TeamTableMember[];
  removeUser(userId: number | string): void;
};

/**
 * List of users of a Dataset or Workflow. If an owner is specified, they will appear
 * first in the list, and will not be deletable.
 */
export function TeamTable(props: Props) {
  const { className, id, labelledby, owner, removeUser, users } = props;

  const listItems = [];

  if (owner) {
    listItems.push(
      <div key={owner.userId} className="table-row">
        <div>{owner.email}</div>

        <div>{returnFormattedRole(owner.role)}</div>
      </div>
    );
  }

  users.forEach((user) =>
    listItems.push(
      <div key={user.userId} className={classNames('table-row', user.rowClassName)}>
        <div>{user.email}</div>

        <div>{returnFormattedRole(user.role)}</div>

        <div title="remove team member">
          <Button
            onClick={() => removeUser(user.userId)}
            variant="no-style"
            aria-label="remove team member"
          >
            <Icon name="trash" size={['18px']} />
          </Button>
        </div>
      </div>
    )
  );

  return (
    <StyledTeamTable
      aria-labelledby={labelledby}
      className={className}
      data-cy={props['data-cy']}
      id={id}
      title=""
      listHeader={
        <div className="table-row heading">
          <div>Email</div>
          <div>Role</div>
        </div>
      }
      listItems={listItems}
    />
  );
}

// ensures Role is nicely formatted in the UI
function returnFormattedRole(role: string) {
  if (!role) return '';

  return role === 'LABELER_AND_REVIEWER' ? 'Labeler & Reviewer' : stringUtils.capitalize(role);
}
