import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledSectionBlock = styled.div`
  .SectionBlock--header {
    flex-direction: column;
    align-items: flex-start !important;

    .header-container {
      display: flex;
      align-items: center;

      h3 {
        display: flex;
        align-items: center;
        margin: 24px 10px 20px 0;
      }
    }

    .SectionBlock__flag-message {
      color: ${COLORS.yellowOrange};
      margin: 0 0 10px 20px;
    }
  }

  hr {
    width: 100%;
    border-bottom: 1px solid ${COLORS.ebony};
    margin: 27px 0 1px;
  }
`;
