import React, { useState } from 'react';

import { COLORS, MATH } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledProgressBar } from './ProgressBar.styles';

type Props = PermafrostComponent & {
  primary: number;
  secondary?: number;
  style?: React.CSSProperties;
  onHoverDetails?: string | null;
  primaryColor?: string;
  secondaryColor?: string;
  progressBarRowProps?: { 'data-cy': string };
};

export const ProgressBar = (props: Props) => {
  const {
    primary,
    secondary,
    className,
    id,
    style,
    onHoverDetails = null,
    primaryColor = COLORS.robinsEggBlue,
    secondaryColor = COLORS.red,
  } = props;
  const [tooltipData, setTooltipData] = useState<any>(null);
  const [mouseCoordinates, setMouseCoordinates] = useState<[number, number]>([-1, -1]);
  const primaryWidth = (primary: number, primaryColor: string) => {
    if (primary === 100) {
      return {
        width: 'calc(100% + 2px)',
        borderRadius: '5px',
        background: primaryColor,
      };
    } else if (primary === 0) {
      return {
        width: '0px',
      };
    } else {
      return {
        width: `calc(${primary}% + 1px)`,
        background: primaryColor,
      };
    }
  };

  const secondaryWidth = (primary: number, secondary: number, secondaryColor: string) => {
    if (MATH.round10(primary + secondary, -2) >= 100) {
      const reducedSecondary = 100 - primary;
      return {
        width: `calc(${reducedSecondary}% + 2px)`,
        borderRadius: '0 5px 5px 0',
        background: secondaryColor,
      };
    } else if (secondary === 0) {
      return {
        width: '0px',
      };
    } else {
      return {
        width: `calc(${secondary}% + 1px)`,
        background: secondaryColor,
      };
    }
  };

  return (
    <StyledProgressBar
      className={`ProgressBar ${className || ''}`}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      onMouseLeave={() => {
        setTooltipData(null);
        setMouseCoordinates([-1, -1]);
      }}
      onMouseEnter={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setTooltipData(onHoverDetails);
        setMouseCoordinates([e.clientX, e.clientY]);
      }}
      onMouseMove={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.persist();
        requestAnimationFrame(() => setMouseCoordinates([e.clientX, e.clientY]));
      }}
    >
      <div className="progress-container" {...props?.progressBarRowProps}>
        <div className="primary-progress" style={primaryWidth(primary, primaryColor)} />
        {secondary ? (
          <div
            className="secondary-progress"
            style={secondaryWidth(primary, secondary, secondaryColor)}
          />
        ) : null}
      </div>
      {tooltipData && (
        <div
          className="progress-bar-tooltip"
          style={{ top: mouseCoordinates[1], left: mouseCoordinates[0] }}
        >
          {tooltipData}
        </div>
      )}
    </StyledProgressBar>
  );
};
