export function reorderItem<T>(array: T[], sourceIndex: number, destIndex: number): T[] {
  if (sourceIndex === destIndex) {
    return array;
  }

  const items = [...array];
  const [reorderedItem] = items.splice(sourceIndex, 1);

  items.splice(destIndex, 0, reorderedItem);

  return items;
}

export function deleteItem<T>(array: T[], targetIndex: number): T[] {
  const items = [...array];

  items.splice(targetIndex, 1);

  return items;
}

export function updateItem<T>(array: T[], targetIndex: number, updatedItem: T): T[] {
  const items = [...array];

  items.splice(targetIndex, 1, updatedItem);

  return items;
}

// Checks if all values are included in array - returns boolean
export function includesAll<T>(array: T[], values: T[]): boolean {
  return values.every((v) => array.includes(v));
}
