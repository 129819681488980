import React from 'react';
import classNames from 'classnames';

import { Icon, LoadingSpinner } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledTooLarge, StyledGenerateReport } from './GenerateReport.styles';

type Props = PermafrostComponent & {
  tooLarge?: boolean;
  downloadReady: boolean;
  isPolling: boolean;
  actionHandler: () => void;
  disabled?: boolean;
};

export const GenerateReport = (props: Props) => {
  const { actionHandler, className, disabled, downloadReady, id, isPolling, tooLarge } = props;

  if (tooLarge) {
    return (
      <StyledTooLarge className={className} data-cy={props['data-cy']} id={id}>
        <p className="too-large-message">
          There is too much data to generate a report. Please use our client libraries.
        </p>
        <a href="https://docs.indico.io" target="_blank" rel="noreferrer" className="docs-link">
          <span className="LinkList-Text">How to Generate a Large Report</span>
        </a>
      </StyledTooLarge>
    );
  } else {
    return (
      <StyledGenerateReport
        aria-label="Generate and Download Report"
        className={classNames(
          'generate-button',
          className,
          downloadReady ? 'download normal' : 'generate cta'
        )}
        data-cy={props['data-cy']}
        disabled={disabled || isPolling}
        id={id}
        onClick={() => actionHandler()}
      >
        {downloadReady ? (
          <>
            <Icon name="fa-file-download" size={[14]} /> Download Report
          </>
        ) : isPolling ? (
          <>
            <LoadingSpinner /> Generating...
          </>
        ) : (
          <>
            <Icon name="plus" size={[14]} /> Generate Report
          </>
        )}
      </StyledGenerateReport>
    );
  }
};
