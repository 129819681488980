import React from 'react';

import classNames from 'classnames';

import { LoadingSpinner } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledButton } from './Button.styles';

export type ButtonVariant = 'primary' | 'cta' | 'normal' | 'link-style' | 'no-style' | 'outline';

export type ButtonProps = PermafrostComponent & {
  'aria-label'?: string;
  'aria-pressed'?: boolean;
  'aria-selected'?: boolean;
  'data-tip'?: boolean;
  'data-for'?: string;
  busy?: boolean;
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  onClick: (...args: any[]) => void;
  onFocus?: () => void;
  role?: string;
  style?: React.CSSProperties;
  tabindex?: 0 | -1;
  type?: 'submit' | 'button' | 'reset';
  variant?: ButtonVariant;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export function Button(props: ButtonProps): React.ReactElement {
  const {
    busy,
    children,
    className,
    disabled,
    id,
    onClick,
    onFocus,
    role,
    style,
    tabindex,
    type,
    variant = 'default',
    ...rest
  } = props;

  return (
    <StyledButton
      aria-disabled={busy}
      aria-label={props['aria-label']}
      aria-pressed={props['aria-pressed']}
      aria-selected={props['aria-selected']}
      className={classNames(variant, className, {
        blue: variant === 'primary' || variant === 'cta',
        busy,
      })}
      data-cy={props['data-cy']}
      data-for={props['data-for']}
      data-tip={props['data-tip']}
      disabled={disabled}
      id={id}
      onClick={busy ? undefined : onClick}
      onFocus={onFocus}
      role={role}
      style={style}
      tabIndex={tabindex}
      type={type || 'button'}
      {...rest}
    >
      {busy && <LoadingSpinner size="0.9em" style={{ marginRight: '0.5em' }} />}

      {children}
    </StyledButton>
  );
}
