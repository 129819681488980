import { createContext } from 'react';

const UserContext = createContext();

export const UserContextState = {
  user: null,
  loading: true,
};

export const UserReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user || false, loading: action.loading };

    case 'SET_LOADING':
      return { ...state, loading: action.loading };

    default:
      return state;
  }
};

export default UserContext;
