import findIndex from 'lodash/findIndex';

export type Field = {
  clsId: number;
  clsName: string;
  color: string;
  position: number;
  removeLabel?: boolean;
};

// TODO: Eventually, let's reomve all instances of this in favor of the above, if possible.
export type Target = {
  id: number;
  text: string;
  color: string;
  removeLabel?: boolean;
};

export const assignHotKey = (field: Field | Target, index: number, array: any) => {
  if (field.removeLabel) {
    return 0;
  } else if (
    findIndex(array, (field: Field | Target) => field.removeLabel || false) > -1 &&
    index + 1 < 21
  ) {
    return index % 10;
  } else if (index + 1 < 20) {
    return (index + 1) % 10;
  } else {
    return null;
  }
};

/**
 * Takes the pixel coordinate (that is associated with pageSize) and converts it to it's equivalent for canvasHeigth or canvasWidth
 * @param pixelCoordinate - top, bottom, left or right pixel value
 * @param pageDimension - pageSize.height or pageSize.width - the height or width of the document in pixels
 * @param canvasDimension - canvasHeight or canvasWidth - the height or width value of the canvas
 * @returns - coordinate for top, bottom, left or right in canvas height or width
 */
export const pageSizePixelToCanvasCoordinate = (
  pixelCoordinate: number,
  pageDimension: number,
  canvasDimension: number
) => {
  return (pixelCoordinate / pageDimension) * canvasDimension;
};
