import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledValidationText } from './ValidationText.styles';

export type ValidationTextStatus = 'prompt' | 'error';

type Props = PermafrostComponent & {
  isError?: boolean;
  message?: string;
};

/**
 * Minimal utility component designed to display text related to field validation, to act as a
 * helper prompt or an error message for a form control or fieldset. Optionally accepts an `id` to
 * allow this element to act as a description for a control using the `aria-describedby` attribute.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute}
 */
export function ValidationText(props: Props) {
  const { className, id, isError, message } = props;

  return (
    <StyledValidationText
      id={id}
      className={classNames(className, {
        error: isError,
        prompt: !isError,
      })}
      data-cy={props['data-cy']}
      data-testid="validation-text"
    >
      {message}
    </StyledValidationText>
  );
}
