import React from 'react';

import { components } from 'react-select';

import { Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';
import { addCustomProps } from 'Permafrost/components/ui-foundations/dropdowns/utils';

import { StyledMultiCombobox } from './MultiCombobox.styles';

import type { ComboboxProps, ComboboxOption } from '../types';

type Props = PermafrostComponent &
  ComboboxProps & {
    /**
     * MultiValue is a selected option in the input area
     */
    multiValueProps?: {
      'data-cy': string;
    };
    optionProps?: {
      'data-cy': string;
    };
    validationErrors?: string[];
    values?: ComboboxOption[];
    onChange: (selectedOption: ComboboxOption[]) => void;
  };

/**
 * Multiselect combobox component. If selections are not bound to outside state
 * via the `value` prop, you will need to import and use the `useCombobox` hook
 * in order to clear its selected value .
 *
 * @see [useCombobox]{@link import('useCombobox').useCombobox}
 */
export const MultiCombobox = React.forwardRef((props: Props, ref: any) => {
  const {
    className,
    closeMenuOnSelect,
    customOption,
    customOptionLabel,
    customOptionValue,
    defaultValue,
    disabled,
    id,
    loading,
    menuIsOpen,
    multiValueProps = {},
    noOptionsMessage,
    onChange,
    optionProps = {},
    options,
    placeholder,
    validationErrors,
    values,
  } = props;

  const MultiValue = (props: any) => {
    return <components.MultiValue {...props} />;
  };

  const MultiValueRemove = (props: any) => {
    return (
      <components.MultiValueRemove {...props}>
        <Icon name="x-close" ariaLabel="remove" size={[10]} />
      </components.MultiValueRemove>
    );
  };

  // handles a custom option component generator, if passed in
  const Option = (props: any) => {
    if (customOption) {
      return (
        <components.Option {...props} {...optionProps}>
          {customOption(props)}
        </components.Option>
      );
    }

    return <components.Option {...props} {...optionProps} />;
  };

  const ComboboxComponent = () => (
    <StyledMultiCombobox
      aria-label={props['aria-label']}
      aria-labelledby={props['aria-labelledby']}
      ref={ref}
      className={className}
      classNamePrefix="combobox"
      closeMenuOnSelect={closeMenuOnSelect}
      components={{
        MultiValue: addCustomProps(MultiValue, multiValueProps),
        MultiValueRemove,
        Option: addCustomProps(Option, optionProps),
      }}
      defaultValue={defaultValue}
      getOptionLabel={customOptionLabel}
      getOptionValue={customOptionValue}
      id={id}
      isClearable={false}
      isDisabled={disabled}
      isMulti
      isLoading={loading}
      menuIsOpen={menuIsOpen}
      noOptionsMessage={() => noOptionsMessage}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      validationErrors={validationErrors}
      value={values}
    />
  );

  if (props['data-cy']) {
    return (
      <div data-cy={props['data-cy']}>
        <ComboboxComponent />
      </div>
    );
  }

  return <ComboboxComponent />;
});
