import React from 'react';

import { COLORS, RandomLoadingMessage } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledLoadingIndicator, StyledMessageLoadingIndicator } from './LoadingIndicator.styles';

type Props = PermafrostComponent & {
  color?: string;
  overallSize?: string | number;
  rippleSize?: string | number;
  fixedMessage?: string;
  showRandomMessage?: boolean;
};

export function LoadingIndicator(props: Props): React.ReactElement {
  const {
    className,
    color,
    id,
    overallSize = '200px',
    rippleSize = '76px',
    fixedMessage,
    showRandomMessage,
  } = props;

  const loadingIndicator = () => {
    return (
      <StyledLoadingIndicator
        aria-hidden={true}
        className={className}
        data-cy={props['data-cy']}
        id={id}
        $overallSize={returnSizeString(overallSize)}
        $rippleSize={returnSizeString(rippleSize)}
        style={color ? { color } : { color: COLORS.curiousBlue }}
      />
    );
  };

  const renderMessage = () => {
    if (fixedMessage) {
      return <span className="message">{fixedMessage}</span>;
    }

    if (showRandomMessage) {
      return <RandomLoadingMessage />;
    }

    return null;
  };

  if (fixedMessage || showRandomMessage) {
    return (
      <StyledMessageLoadingIndicator $overallSize={returnSizeString(overallSize)}>
        {loadingIndicator()}
        {renderMessage()}
      </StyledMessageLoadingIndicator>
    );
  }

  return loadingIndicator();
}

function returnSizeString(size: string | number): string {
  if (typeof size === 'number') {
    return `${size}px`;
  }

  return size;
}
