import React from 'react';

import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

type CommonProps = PermafrostComponent & {
  children: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
};

export type Props = CommonProps & {
  'aria-label'?: string;
  'aria-labelledby'?: string;
};

/**
 * Simple StyledComponent, used alongside `<WidgetCardHeader>`, and `<WidgetCardContent>`.
 * If header is not used, include an `aria-label` prop, or include an `id` for the element
 * that labels this component, and include that `id` in the `aria-labelledby` prop.
 *
 * `<WidgetCard>`, `<WidgetCardHeader>`, and `<WidgetCardContent>` are all StyledComponents.
 *
 * @example
 * <WidgetCard aria-labelledby="headerText">
 *   <WidgetCardHeader>
 *     <header>Time on Task</header>
 *     <span><Icon name="fa-cog" ariaLabel="settings" /></span>
 *   </WidgetCardHeader>
 *
 *   <WidgetCardContent>
 *     [[ child elements ]]
 *   </WidgetCardContent>
 * </WidgetCard>
 */
export const WidgetCard = styled.article<Props>`
  width: 100%;
  background-color: ${COLORS.clay};
  border: 1px solid ${COLORS.oxfordBlue};
  border-radius: 4px;
  box-shadow: 1px 1px 1px 0 ${COLORS.ebony};
`;

/**
 * StyledComponent to be used inside `<WidgetCard>`
 */
export const WidgetCardHeader = styled.div<Props>`
  display: flex;
  align-items: center;

  width: 100%;
  height: 45px;

  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 5px 15px;

  background-color: ${COLORS.ebony};
`;

/**
 * StyledComponent to be used inside `<WidgetCard>`
 */
export const WidgetCardContent = styled.div<Props>`
  padding: 18px 20px;

  header {
    margin-bottom: 26px;
    font-size: 22px;
    color: ${COLORS.white};
  }
`;
