import React from 'react';

import { PermafrostComponent } from 'Permafrost/types';
import { COLORS } from 'Permafrost/index';

import { StyledFlag } from './Flag.styles';

type Props = PermafrostComponent & {
  text: string;
  flagColor?: string;
  fontColor?: string;
};

export const Flag = (props: Props) => {
  const { id, className, text, flagColor = COLORS.yellowOrange, fontColor = COLORS.black } = props;

  return (
    <StyledFlag
      id={id}
      className={className}
      data-cy={props['data-cy']}
      data-testid="flag-component"
      $flagColor={flagColor}
      $fontColor={fontColor}
    >
      {text}
    </StyledFlag>
  );
};
