import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledShrug } from './Shrug.styles';

type Props = PermafrostComponent & {
  actionLink?: string;
  actionText?: string;
  buttonAction?(): void;
  buttonText?: string;
  message?: string | React.ReactNode;
  messageProps?: { 'data-cy': string };
};

export function Shrug(props: Props): React.ReactElement {
  const { actionLink, actionText, buttonAction, buttonText, className, id, message } = props;
  return (
    <StyledShrug className={className} data-cy={props['data-cy']} id={id}>
      <Icon name="shrug" size={['260px', '84px']} />
      {typeof message === 'string' ? (
        <p className="t-subhead-large" {...props?.messageProps}>
          {message}
        </p>
      ) : (
        message
      )}
      {actionLink && actionText && (
        <Link to={actionLink} className="button blue cta" style={{ marginTop: '20px' }}>
          {actionText}
        </Link>
      )}
      {buttonAction && buttonText && (
        <Button onClick={buttonAction} style={{ marginTop: '20px' }} variant="cta">
          {buttonText}
        </Button>
      )}
    </StyledShrug>
  );
}
