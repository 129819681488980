import styled from 'styled-components';

import { ListTable } from 'Permafrost/index';

export const StyledTeamTable = styled(ListTable)`
  .table-row {
    display: grid;
    grid-template-columns: 1fr 1.2fr 30px;
    padding-top: 10px;

    &:not(.heading) {
      padding-bottom: 10px;
    }
  }
`;
