import { zustandUtils } from '@indico-data/utils';
import { Location } from './types';

export type State = {
  currentLocation: Location;
  previousLocation: Location;
  routeError: string;
  sidebarOpen: boolean;
};

export type Actions = {
  setLocation: (previousLocation: Location, currentLocation: Location) => void;
  setRouteError: (value: string) => void;
  toggleSidebar: () => void;
  resetAppStoreState: () => void;
};

export type StateAndActions = State & Actions;

const initialState = {
  currentLocation: null,
  previousLocation: null,
  routeError: '',
  sidebarOpen: true,
};

export const store = (
  set: (cb: (state: Actions & State) => void) => void,
  get: () => Actions & State
) => ({
  ...initialState,

  setLocation: (previousLocation: Location, currentLocation: Location) =>
    set(() => ({
      previousLocation,
      currentLocation,
    })),

  setRouteError: (value: string) =>
    set(() => ({
      routeError: value,
    })),

  toggleSidebar: () => {
    const current = get().sidebarOpen;
    set(() => ({
      sidebarOpen: !current,
    }));
  },

  resetAppStoreState: () => set(() => initialState),
});

export const useAppStore = zustandUtils.createStore(store);
