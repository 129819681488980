import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledDatePicker = styled.div`
  /************************************
  *  Default react-day-picker styling - this needed to be added because we were unable to import styling as specified by the docs (most likely because we are not using webpack)
  *************************************/

  .rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: #0000ff;
    --rdp-background-color: #e7edff;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */

    margin: 1em;
  }

  /* Hide elements for devices that are not screen readers */
  .rdp-vhidden {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
  }

  /* Buttons */
  .rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: inherit;
    background: none;
    font: inherit;

    -moz-appearance: none;
    -webkit-appearance: none;
  }

  .rdp-button_reset:focus-visible {
    /* Make sure to reset outline only when :focus-visible is supported */
    outline: none;
  }

  .rdp-button {
    border: 2px solid transparent;
  }

  .rdp-button[disabled]:not(.rdp-day_selected) {
    opacity: 0.25;
  }

  .rdp-button:not([disabled]) {
    cursor: pointer;
  }

  .rdp-button:focus-visible:not([disabled]) {
    color: inherit;
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: var(--rdp-background-color);
  }

  .rdp-months {
    display: flex;
  }

  .rdp-month {
    margin: 0 1em;
  }

  .rdp-month:first-child {
    margin-left: 0;
  }

  .rdp-month:last-child {
    margin-right: 0;
  }

  .rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
  }

  .rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
  }

  .rdp-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: left;
  }

  .rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
  }

  .rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
  }

  .rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: currentColor;
    border: 0;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: 140%;
    font-weight: bold;
  }

  .rdp-nav {
    white-space: nowrap;
  }

  .rdp-multiple_months .rdp-caption_start .rdp-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
  }

  /* ---------- */
  /* Dropdowns  */
  /* ---------- */

  .rdp-dropdown_year,
  .rdp-dropdown_month {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
  }

  .rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
    border-radius: 6px;
  }

  .rdp-dropdown_icon {
    margin: 0 0 0 5px;
  }

  .rdp-head {
    border: 0;
  }

  .rdp-head_row,
  .rdp-row {
    height: 100%;
  }

  .rdp-head_cell {
    vertical-align: middle;
    font-size: 0.75em;
    font-weight: 700;
    text-align: center;
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
  }

  .rdp-tbody {
    border: 0;
  }

  .rdp-tfoot {
    margin: 0.5em;
  }

  .rdp-cell {
    width: var(--rdp-cell-size);
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
    text-align: center;
  }

  .rdp-weeknumber {
    font-size: 0.75em;
  }

  .rdp-weeknumber,
  .rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: var(--rdp-cell-size);
    max-width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    margin: 0;
    border: 2px solid transparent;
    border-radius: 100%;
  }

  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    color: white;
    opacity: 1;
    background-color: var(--rdp-accent-color);
  }

  .rdp-day_outside {
    opacity: 0.5;
  }

  .rdp-day_selected:focus-visible {
    /* Since the background is the same use again the outline */
    outline: var(--rdp-outline);
    outline-offset: 2px;
    z-index: 1;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdp-day_range_end.rdp-day_range_start {
    border-radius: 100%;
  }

  .rdp-day_range_middle {
    border-radius: 0;
  }

  /*# sourceMappingURL=style.css.map */

  /******************************
  *   Indico Custom Styling 
  *******************************/

  .datepicker--inputParent {
    position: relative;
    width: 150px;
  }

  .datepicker-label {
    padding: 10px 10px 10px 0;
    color: ${COLORS.white};
    font-size: 14px;
  }

  .datepicker--input {
    width: 150px;
    border: solid ${COLORS.baliHai} 1px;
    border-radius: 4px;
    text-align: center;
    color: ${COLORS.white};

    &::placeholder {
      opacity: 0.5;
    }
  }

  .remove-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .remove-btn svg {
    height: 8px;
    margin-right: 10px;
    width: 8px;
  }

  .remove-btn,
  .remove-btn:hover,
  .remove-btn:focus {
    color: ${COLORS.defaultFontColor};
    background: transparent;
    box-shadow: none;
    font-size: 16px;
    padding: 0 !important;
  }

  .DayPickerInput-Overlay {
    background: ${COLORS.oxfordBlue};
    border-radius: 4px;
    z-index: 999;
    .rdp {
      background: ${COLORS.oxfordBlue};
      z-index: 999;
    }
  }

  .rdp-caption_label {
    font-weight: 500;
    font-size: 1.15em;
  }

  .rdp-button,
  .rdp-day {
    box-shadow: none;
  }

  .rdp-head_cell,
  .rdp-cell {
    border: none;
  }

  .rdp-head_cell {
    color: ${COLORS.mediumGray};
  }

  .rdp-day_today {
    color: ${COLORS.ebony} !important;
  }

  //on hover font color
  .rdp:not(.rdp-day_disabled)
    .rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected):not(.rdp-day_outside):hover {
    color: ${COLORS.mirage};
    background-color: ${COLORS.white};
  }

  .rdp-day_today:not(.rdp-day_disabled):not(.rdp-day_selected):not(.rdp-day_outside) {
    color: ${COLORS.red} !important;
  }

  .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
    position: relative;
    background-color: ${COLORS.curiousBlue};
    color: ${COLORS.white};
  }

  .rdp-day_disabled:not(.rdp-day_today) {
    opacity: 0.3;
  }

  .rdp-day_disabled {
    background-color: inherit !important;
    cursor: auto !important;
  }

  .rdp-day_disabled:hover {
    color: inherit !important;
  }
`;
