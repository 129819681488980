import styled from 'styled-components';

import { ANIMATION, COLORS, TYPOGRAPHY } from 'Permafrost/index';

import {
  fieldInfoCommon,
  inputCommon,
  labelCommon,
  placeholderCommon,
} from '../fieldsCommon.styles';

export const StyledTextField = styled.div`
  position: relative;
  color: ${COLORS.defaultFontColor};
  font-family: ${TYPOGRAPHY.fontFamily.base};

  min-height: 40px;
  margin-top: 35px;

  label {
    pointer-events: none;

    position: absolute;
    top: 6px;

    transition: transform ${ANIMATION.duration} ${ANIMATION.timing};
  }

  input {
    ${inputCommon};

    padding: 5px 0 10px;
    margin-top: 0;

    border: 0 solid currentColor;
    border-bottom-width: 1px;

    &::placeholder,
    &::-webkit-input-placeholder {
      color: inherit;
      opacity: 0;

      transition: opacity ${ANIMATION.duration} ${ANIMATION.timing};
    }

    &:focus,
    &.filled {
      ${placeholderCommon}

      + label {
        ${labelCommon};

        transform: translateY(-30px);
      }
    }
  }
`;

export const StyledFieldInfo = styled.div`
  ${fieldInfoCommon};

  display: flex;

  p {
    margin: 0;
  }

  .count {
    margin-left: auto;
  }

  .isMax {
    color: ${COLORS.white};
  }
`;
