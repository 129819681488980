import styled from 'styled-components';

export const StyledStatusContainer = styled.div`
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
`;

export const StyledLoadingAwareContainer = styled.div`
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
