import styled from 'styled-components';
import { MEDIA_QUERIES } from '@indico-data/permafrost';

export const StyledPageContainer = styled.div`
  .container {
    margin: 0 10px;

    @media ${MEDIA_QUERIES.mediumScreen} {
      margin: 20px 50px;
      padding-bottom: 20px;
    }
  }
`;
