import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledToggle = styled.label`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;
  border: 2px solid ${COLORS.defaultFontColor};
  border-radius: 34px;
  margin-bottom: 0;
  cursor: pointer;

  & input {
    display: none;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .slider {
    position: absolute;
    top: 0;
    left: -1px;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 17px;
    width: 17px;
    left: -1px;
    bottom: -1px;
    background-color: ${COLORS.clay};
    transition: 0.4s;
    border: 2px solid ${COLORS.defaultFontColor};
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: ${COLORS.defaultFontColor};
    width: 100%;
  }

  input:checked + .slider:before {
    transform: translateX(12px);
  }

  p {
    position: relative;
    left: 35px;
    bottom: 2px;
  }
`;
