import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { COLORS, SPACING } from '../../../../index';
import { basicDefaults } from '../commonStyles';
import { CreateableSelectProps } from './CreateableSelect';

type StyledCreateableSelectProps = CreateableSelectProps;

export const StyledCreateableSelect = styled(CreatableSelect)<StyledCreateableSelectProps>`
  ${basicDefaults};

  .combobox__control {
    border-radius: 0;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid ${COLORS.defaultFontColor};
    border-left: none;

    &:hover {
      border-color: ${COLORS.lightFontColor};
    }
  }

  .combobox__control--is-disabled {
    &:hover {
      border-color: ${COLORS.defaultFontColor};
    }
  }

  .combobox__menu {
    background-color: ${COLORS.clay};
    border: 1px solid ${COLORS.mediumGray};
  }

  .combobox__menu-list {
    border-radius: inherit;
    padding-top: 0;
    padding-bottom: 0;
  }

  .combobox__placeholder {
    color: ${COLORS.defaultFontColor};
  }

  .combobox__single-value {
    color: ${COLORS.lightFontColor};
  }

  .swatch {
    display: inline-block;
    vertical-align: top;
    margin-right: ${SPACING.xs};
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
  .inline-icon {
    margin-right: ${SPACING.xs};
  }
  ${(props) =>
    props.hasWordBreak &&
    css`
      word-break: break-word;
    `}
`;
