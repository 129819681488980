import styled from 'styled-components';

export const StyledPercentageRing = styled.div`
  position: relative;

  svg {
    transform: rotate(-90deg);
  }

  div {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: -0.05em;
    margin-left: 0.1em;

    span {
      font-size: 0.6em;
      margin-top: -0.5em;
    }
  }
`;
