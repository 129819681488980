import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledChartToolTip = styled.div`
  font-size: 13px;
  left: -999em;
  top: -999em;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  z-index: 800;
  color: ${COLORS.defaultFontColor};
  background: ${COLORS.oxfordBlue};
  border: 1px solid ${COLORS.baliHai};
  border-radius: 2px;
  padding: 8px 11px;
  transform: translate(-50%, -100%);
  margin-top: -14px;

  &:before,
  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
  }

  &:before {
    border-top: 8px solid ${COLORS.baliHai};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -8px;
    margin-left: -10px;
  }

  &:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    margin-left: -8px;
    border-top: 6px solid ${COLORS.oxfordBlue};
  }

  p {
    font-size: 12px;
    line-height: 1.5;
  }
`;
