import styled from 'styled-components';

import { COLORS, FONT_SIZE } from 'Permafrost/index';

export const StyledSelect = styled.div`
  &.horizontal {
    > * {
      display: inline-block;
    }

    > label {
      margin-right: 12px;
    }
  }

  label {
    line-height: 1.2;
    margin-bottom: 14px;
    color: ${COLORS.lightFontColor};
  }

  .select-container {
    position: relative;
    max-width: 300px;

    select {
      background: transparent;
      border: 1px solid ${COLORS.baliHai};
      width: 100%;
      max-width: 300px;
      color: ${COLORS.lightFontColor};
      font-size: ${FONT_SIZE.base};
      border-radius: 4px;
      padding: 7px 30px 8px 15px;
      appearance: none;
      position: relative;
      z-index: 2;

      &:focus {
        outline: 0;
      }

      &::-ms-expand {
        display: none;
      }
    }

    option {
      color: ${COLORS.black};

      &:not(:checked) {
        color: ${COLORS.black};
      }
    }

    .selectCaret {
      display: inline-block;
      right: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
`;

export const StyledValidationError = styled.div`
  margin-top: 13px;

  p {
    color: ${COLORS.curiousBlue};
  }
`;
