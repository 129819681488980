import React from 'react';

import { ColorProperties } from './color-properties';
import { UtilityClasses } from './utility-classes';
import { Forms } from './forms';
import { Buttons } from './buttons';
import { Layout } from './layout';
import { Lists } from './lists';
import { Media } from './media';
import { Tables } from './tables';
import { Typography } from './typography';
import { MarginPadding } from './margin-padding';

export const GlobalStyles = () => (
  <>
    <ColorProperties />
    <UtilityClasses />
    <Buttons />
    <Forms />
    <Layout />
    <Lists />
    <Media />
    <Tables />
    <Typography />
    <MarginPadding />
  </>
);
