import React, { useCallback } from 'react';

import { Axis } from '@visx/axis';
import { Text } from '@visx/text';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { GridColumns } from '@visx/grid';
import { AnyD3Scale } from '@visx/scale';

import { Datum } from './HorizontalBarChart';

import { tooltipStyles } from './HorizontalBarChart.styles';

type Props = {
  axisProps: { stroke: string; strokeWidth: number };
  color: string;
  data: any[]; // todo: ts really doesn’t like using the actual type here: why??
  tickProps: { fill: string; fontSize?: number };
  verticalLinesToShow: number;
  xScale: AnyD3Scale;
  yAxisKey: string;
  yMax: number;
  yScale: AnyD3Scale;
};

/**
 * Provides left axis and vertical lines for the `HorizontalBarChart` component.
 */
export function MeasurementRows({
  axisProps,
  color,
  data,
  tickProps,
  verticalLinesToShow,
  xScale,
  yAxisKey,
  yMax,
  yScale,
}: Props) {
  const { containerBounds, TooltipInPortal } = useTooltipInPortal();
  const { showTooltip, hideTooltip, tooltipData, tooltipLeft = 0, tooltipTop = 0 } = useTooltip();

  // https://airbnb.io/visx/tooltip
  const handlePointerMove = useCallback(
    (event: any, value: any) => {
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;

      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY - 10,
        tooltipData: value,
      });
    },
    [showTooltip, containerBounds]
  );

  const customTick = (formattedValue: string = '', x: number, y: number) => {
    const { className } = data.find((datum: Datum) => datum[yAxisKey] === formattedValue);

    const maxChars = 20;

    const trimmedValue =
      formattedValue.length <= maxChars
        ? formattedValue
        : formattedValue.substr(0, maxChars).concat('...');

    return (
      <Text
        {...tickProps}
        className={className || ''}
        x={x}
        y={y}
        dx={'-0.65em'}
        dy={-1}
        textAnchor="end"
        verticalAnchor="middle"
        onPointerMove={(e) => handlePointerMove(e, formattedValue)}
        onPointerLeave={() => hideTooltip()}
      >
        {trimmedValue}
      </Text>
    );
  };

  const tickLength = 7;

  return (
    <g style={{ color }}>
      <line x1={0} y1={1} x2={-tickLength} y2={1} {...axisProps} />
      <line x1={0} y1={yMax} x2={-tickLength} y2={yMax} {...axisProps} />

      <TooltipInPortal style={tooltipStyles} left={tooltipLeft} top={tooltipTop}>
        <div className="tooltip">{tooltipData as any}</div>
      </TooltipInPortal>

      <Axis
        {...axisProps}
        axisClassName="left-axis-container"
        orientation="left"
        left={0}
        scale={yScale}
        numTicks={data.length}
        tickLength={tickLength}
        tickStroke="currentColor"
        tickComponent={(tickValues) =>
          customTick(tickValues.formattedValue, tickValues.x, tickValues.y)
        }
      />

      <GridColumns
        scale={xScale}
        height={yMax}
        numTicks={verticalLinesToShow}
        stroke="currentColor"
        strokeOpacity={0.4}
        pointerEvents="none"
      />
    </g>
  );
}
