import { css } from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const basicDefaults = css`
  font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
  color: ${COLORS.lightFontColor};

  &.combobox--is-disabled {
    pointer-events: auto;
    opacity: 0.6;
  }

  // overall component container
  .combobox__control {
    box-shadow: none;
    min-height: unset;
  }

  .combobox__control--is-disabled {
    cursor: not-allowed;
  }

  // container component for the text input
  .combobox__input-container {
    color: ${COLORS.defaultFontColor};
    cursor: text;

    input {
      margin-bottom: 0;
      caret-color: ${COLORS.white};
    }
  }

  // the dropdown list
  .combobox__menu-list {
    border-radius: inherit;
    padding-top: 0;
    padding-bottom: 0;
  }

  // each available item in the dropdown list
  .combobox__option {
    background-color: inherit;

    :hover,
    &--is-focused {
      background-color: ${COLORS.oxfordBlue};
    }
  }

  // normally displays as a thin vertical line before the buttons on the far right
  .combobox__indicator-separator {
    display: none;
  }

  // caret button to open/close menu
  .combobox__dropdown-indicator {
    color: ${COLORS.lightFontColor};

    :hover {
      color: ${COLORS.white};
    }
  }
`;
