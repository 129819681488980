import React from 'react';

import { v4 as uuid } from 'uuid';

import { Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledSelect } from './Select.styles';
import classNames from 'classnames';
import { ComboboxSize, ComboboxVariant } from '../types';

type Props = PermafrostComponent & {
  disabled?: boolean;
  initialText?: string;
  options: { name: string; value: string | number }[];
  size?: ComboboxSize;
  style?: object;
  value?: string | number;
  variant?: ComboboxVariant;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  onClick?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
};

export const Select = (props: Props) => {
  const {
    id,
    className,
    disabled,
    initialText,
    onChange,
    options,
    size = 'medium',
    style = {},
    value,
    variant,
    onClick,
  } = props;

  const selectId = uuid();

  return (
    <StyledSelect className={className} data-cy={props['data-cy']} id={id} style={style}>
      <div
        className={classNames('Select__container', size, variant)}
        onClick={(e) => (onClick ? onClick(e) : null)}
      >
        <select onChange={onChange} value={value} disabled={disabled} id={selectId}>
          {initialText && (
            <option disabled value="">
              {initialText}
            </option>
          )}

          {options.map((o) => {
            const key = uuid();

            return (
              <option key={key} value={o.value}>
                {o.name}
              </option>
            );
          })}
        </select>

        <Icon className="chevron" name="chevron-down" size={size === 'small' ? [10] : [12]} />
      </div>
    </StyledSelect>
  );
};
