import React from 'react';
import { ToastsContext } from './ToastsList';

export const withToast = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Omit<P, 'addToast'>> => (props) => (
  <ToastsContext.Consumer>
    {({ addToast }) => <WrappedComponent addToast={addToast} {...(props as P)} />}
  </ToastsContext.Consumer>
);

export default withToast;
