import styled from 'styled-components';

import { COLORS, TYPOGRAPHY, AbstractRadioGroup } from 'Permafrost/index';

export const StyledFlipper = styled(AbstractRadioGroup)`
  label {
    display: inline-block;
    font-size: ${TYPOGRAPHY.fontSize.base};
    padding: 0.4em 1em 0.5em;
    margin-bottom: 0;

    border-radius: 1em;
    color: ${COLORS.baliHai};

    cursor: pointer;

    &.selected {
      background-color: ${COLORS.oxfordBlue};
    }

    &.selected,
    &:hover {
      color: ${COLORS.white};
    }

    &.focused {
      box-shadow: 0 0 0 1px ${COLORS.clay}, 0 0 0 3px ${COLORS.curiousBlue};
    }
  }

  &.small {
    label {
      font-size: 13px;
      padding: 0.2em 0.8em;
    }
  }
`;
