// todo: styling from the mock does not fully match the current button style implementation.
//  will be updated during permafrost refactor tasks - 4/21 jm

import React, { useImperativeHandle, useRef } from 'react';

import classNames from 'classnames';

import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';

import { AriaButtonProps } from '@react-types/button';

import { Icon, IconName, LoadingSpinner } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { ButtonSize, ButtonVariant } from '../types';

import { StyledIconButton, StyledIconButtonLink } from './IconButton.styles';

type Props = PermafrostComponent & {
  /**
   * Adjusts vertical alignment of the text label, in relation to the icon
   */
  adjustAlignment?: number;
  busy?: boolean;
  iconName: IconName;
  iconSide?: 'start' | 'end';
  isDisabled?: boolean;
  /**
   * If element will be an `<a>`, the href URL
   */
  isLink?: string;
  label?: string;
  size?: ButtonSize;
  style?: React.CSSProperties;
  variant?: ButtonVariant;
} & AriaButtonProps;

/**
 * General utility “button with an icon” component. May also be used for links that _look_
 * like buttons, via the `isLink` property.
 *
 * The button handler must be passed in via the `onPress` property (replaces `onClick`).
 *
 * @see {@link https://react-spectrum.adobe.com/blog/building-a-button-part-1.html}
 */
export const IconButton = React.forwardRef(function (
  props: Props,
  ref: React.Ref<Partial<HTMLButtonElement>>
) {
  const {
    adjustAlignment = 0,
    busy,
    className,
    iconName,
    iconSide = 'start',
    id,
    isDisabled,
    isLink,
    label,
    size = 'normal',
    style,
    variant,
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const elementRef = isLink ? anchorRef : buttonRef;

  const { buttonProps } = useButton(
    { ...props, onPress: busy ? undefined : props.onPress },
    elementRef
  );

  // Exposes select methods on the internal button ref to the parent component
  useImperativeHandle(ref, () => ({
    // Timeout places the focus at the end of the event queue to ensure the button is focusable
    focus: () => setTimeout(() => elementRef.current?.focus(), 0),
    contains: (el: HTMLElement) => !!elementRef.current?.contains(el),
  }));

  const { isFocusVisible, focusProps } = useFocusRing();

  const commonProps = {
    'aria-disabled': busy,
    className: classNames(iconSide, size, variant, className, {
      'focus-visible': isFocusVisible,
      busy,
      disabled: isLink && isDisabled,
    }),
    ...buttonProps,
    ...focusProps,
    'data-cy': props['data-cy'],
    id,
    style,
  };

  const iconElement = busy ? (
    <LoadingSpinner
      size="1em"
      style={{
        marginTop: `${adjustAlignment}px`,
        marginBottom: `${Math.abs(adjustAlignment)}px`,
      }}
    />
  ) : (
    <Icon className={classNames({ 'right-margin': label })} name={iconName} />
  );

  const labelElement = label ? (
    <span style={{ marginTop: `${adjustAlignment}px` }}>{label}</span>
  ) : null;

  if (isLink && !isDisabled) {
    return (
      <StyledIconButtonLink {...commonProps} ref={anchorRef} to={isLink}>
        {iconElement} {labelElement}
      </StyledIconButtonLink>
    );
  }

  return (
    <StyledIconButton {...commonProps} ref={buttonRef}>
      {iconElement} {labelElement}
    </StyledIconButton>
  );
});
