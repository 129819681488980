import * as H from 'history';

export const goBackWithFallback = (history: H.History, altRoute: string) => {
  if (history.length > 1) {
    history.goBack();
  } else {
    history.push(altRoute);
  }
};

export const getBackLinkProps = (history: H.History, altRoute: string) => {
  if (history.length > 1) {
    return {
      onClick: () => history.goBack(),
    };
  }

  return { to: altRoute };
};
