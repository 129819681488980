import React from 'react';
import { v4 as uuid } from 'uuid';

import { Pagination, Accordion, LoadingList, Shrug, SectionBody } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { Header } from './Header';
import { StyledListTable } from './ListTable.styles';

type Props = PermafrostComponent & {
  accordion?: boolean;
  accordionDefaultOpen?: boolean;
  emptyListMessage?: string;
  error?: any;
  errorMessage?: any;
  headerComponent?: React.ReactNode;
  listHeader: React.ReactNode;
  listItems: React.ReactNode[];
  loading?: any;
  pagination?: {
    currentPage: number;
    limit: number;
    pageInfo: {
      startCursor: number;
      endCursor: number;
      hasNextPage: boolean;
      aggregateCount: number;
    };
    getNextPage: (before: number) => void;
    getPage: (after: number, pageOffset: number) => void;
    getPreviousPage: (after: number) => void;
  };
  sortBy?: {
    descending: boolean;
    items: { name: string; value: string }[];
    value: string;
    onSort: (value: string) => void;
    updateDescending: () => void;
  };
  subTitle?: string | React.ReactNode;
  title: string;
  totalCount?: number;
  flagText?: string;
  flagMessage?: string;
};

export const ListTable = (props: Props) => {
  const header = (
    <Header
      selectProps={{ 'data-cy': 'dataset--sort-by-select' }}
      title={props.title}
      headerComponent={props.headerComponent}
      sortBy={props.sortBy}
      aggregateCount={props?.pagination?.pageInfo?.aggregateCount || props.totalCount}
      flagText={props.flagText}
      hasFlagMessage={props.flagMessage ? true : false}
    />
  );

  const RenderList = () => {
    if (props.loading) {
      return <LoadingList />;
    }
    if (props.error) {
      return (
        <SectionBody className="error-shrug">
          <Shrug message={props.errorMessage || 'Sorry, there was an error retrieving this data'} />
        </SectionBody>
      );
    }

    return (
      <>
        {props.listItems.length === 0 ? (
          <SectionBody className="error-shrug">
            <Shrug message={props.emptyListMessage || 'No results found'} />
          </SectionBody>
        ) : (
          <ul className="list-container">
            {props.listItems.map((li) => {
              const key = uuid();
              return (
                <li className="list-item" key={key}>
                  {li}
                </li>
              );
            })}
          </ul>
        )}
        {props.pagination ? (
          <Pagination
            limit={props.pagination.limit}
            pageInfo={props.pagination.pageInfo}
            currentPage={props.pagination.currentPage}
            getPage={props.pagination.getPage}
            getPreviousPage={props.pagination.getPreviousPage}
            getNextPage={props.pagination.getNextPage}
            quantity={props.listItems.length}
          />
        ) : null}
      </>
    );
  };

  const list = (
    <StyledListTable>
      {props.flagMessage ? <p className="ListTable__flag-message">{props.flagMessage}</p> : null}
      {props.subTitle ? (
        <div className="sub-title">
          {typeof props.subTitle === 'string' ? (
            <p style={{ maxWidth: '75%' }}>{props.subTitle}</p>
          ) : (
            props.subTitle
          )}
        </div>
      ) : null}
      <div className="list">
        {!props.error ? <div className="list-header-container">{props.listHeader}</div> : null}
        <RenderList />
      </div>
    </StyledListTable>
  );

  return (
    <StyledListTable className={props.className} data-cy={props['data-cy']} id={props.id}>
      {props.accordion ? (
        <Accordion header={header} content={list} open={props.accordionDefaultOpen} />
      ) : (
        <>
          {header}
          {list}
        </>
      )}
    </StyledListTable>
  );
};

export default ListTable;
