export const UPLOAD_CSV_TYPES = [
  'text/csv',
  'text/x-csv',
  'text/plain',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
  'application/vnd.ms-excel',
];

const UPLOAD_CSV_EXTENSION = 'csv';

export const UPLOAD_PDF_TYPES = ['application/pdf'];

const UPLOAD_PDF_EXTENSION = 'pdf';

const UPLOAD_DOCX_TYPES = [
  'text/doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const UPLOAD_DOCX_EXTENSION = 'docx';

export const UPLOAD_IMAGE_TYPES = [
  'image/jpeg',
  'image/pjpeg',
  'image/jpeg',
  'image/pjpeg',
  'image/jpeg',
  'image/png',
];

export const UPLOAD_IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.jpg-large', '.png'];

export const isFileCSV = (fileType: string, ext = '') => {
  return (
    UPLOAD_CSV_TYPES.indexOf(fileType) >= 0 || ext.toString().toLowerCase() === UPLOAD_CSV_EXTENSION
  );
};

export const isFilePDF = (fileType: string, ext = '') => {
  return (
    UPLOAD_PDF_TYPES.indexOf(fileType) >= 0 || ext.toString().toLowerCase() === UPLOAD_PDF_EXTENSION
  );
};

export const isFileDOC = (fileType: string, ext = '') => {
  return (
    UPLOAD_DOCX_TYPES.indexOf(fileType) >= 0 ||
    ext.toString().toLowerCase() === UPLOAD_DOCX_EXTENSION
  );
};

export const isFileValidImage = (fileType: string, ext = '') => {
  return (
    UPLOAD_IMAGE_TYPES.indexOf(fileType) >= 0 ||
    UPLOAD_IMAGE_EXTENSIONS.indexOf(ext.toString().toLowerCase()) >= 0
  );
};

export const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const formattedFileSize = (fileSize: number) => {
  const sizeDivisor = fileSize < 500000 ? 1024 : 1048576;
  const modifiedSize = Number.parseFloat(String(fileSize / sizeDivisor)).toPrecision(3);
  const formattedSize = `${modifiedSize} ${sizeDivisor === 1024 ? 'Kb' : 'Mb'}`;
  return formattedSize;
};
