import React from 'react';

import { numberUtils } from '@indico-data/utils';
import classNames from 'classnames';

import { BorderSelect, Button, Icon, Flag } from 'Permafrost/index';

import { StyledHeader } from './Header.styles';

type Props = {
  title: string;
  headerComponent?: React.ReactNode;
  sortBy?: {
    value: string;
    items: { name: string; value: string }[];
    descending: boolean;
    updateDescending: () => void;
    onSort: (value: string) => void;
  };
  aggregateCount?: number;
  selectProps?: { 'data-cy': string };
  flagText?: string;
  hasFlagMessage?: boolean;
};

export const Header = (props: Props) => {
  const { title, headerComponent, sortBy, aggregateCount, flagText, hasFlagMessage } = props;
  return (
    <StyledHeader className={classNames({ 'flag-message': hasFlagMessage })}>
      <div className="title-container">
        <h2 className="title">{title}</h2>
        <span className="count">
          {aggregateCount ? numberUtils.numberWithCommas(aggregateCount) : null}
        </span>
        {flagText ? <Flag className="Header__flag" text={flagText} /> : null}
      </div>
      <div className="controls-container">
        {headerComponent ? <div className="header-component">{headerComponent}</div> : null}
        {sortBy ? (
          <div className="sort-by">
            <BorderSelect
              label="Sort By"
              value={sortBy.value}
              options={sortBy.items}
              onChange={(e) => {
                sortBy.onSort(e.target.value);
              }}
              horizontal
              {...props.selectProps}
            />
            <Button
              data-cy="dataset--sort-by-btn"
              onClick={() => sortBy.updateDescending()}
              variant="no-style"
              className="button-sort-direction"
            >
              <Icon
                name="fa-arrow-down"
                ariaLabel="descending"
                size={[16]}
                style={{ transform: sortBy.descending ? '' : 'rotate(180deg)' }}
              />
            </Button>
          </div>
        ) : null}
      </div>
    </StyledHeader>
  );
};
