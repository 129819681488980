import styled from 'styled-components';

import { ANIMATION, COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledTabs = styled.ul`
  position: relative;
  top: -2px;

  display: flex;

  color: ${COLORS.midFontColor};

  border-bottom: 2px solid currentColor;

  li > [role='tab'] {
    text-decoration: none;
    display: block;
    color: inherit;
    line-height: 1.25;

    position: relative;
    top: 2px;

    padding: 16px;
    font-size: ${TYPOGRAPHY.fontSize.base};
    text-transform: uppercase;
    border-bottom: 2px solid currentColor;

    transition: color ${ANIMATION.duration} ${ANIMATION.timing};

    &.fullWidthTabs {
      width: 100%;
      height: 100%;
      white-space: normal;
    }

    &[aria-selected='true'] {
      color: ${COLORS.white};
    }
  }
`;
