import React, { useRef } from 'react';

import classNames from 'classnames';

import { hookUtils } from '@indico-data/utils';

import { Icon } from 'Permafrost/index';

import { StyledContextMenu } from './ChartContextMenu.styles';

type Datum = { label: string; value: number };

type Option = {
  filter?: any;
  icon: React.ReactNode;
  name: string;
  handler: Function;
};

type Props = {
  options: Option[];
  currentDatum: Datum;
  xPos?: number;
  yPos?: number;
  closeMenuHandler(): void;
};

export function ChartContextMenu(props: Props) {
  const { closeMenuHandler, xPos, yPos, options, currentDatum } = props;

  const menuEl = useRef() as React.MutableRefObject<HTMLUListElement>;

  hookUtils.useClickOutside(menuEl, closeMenuHandler);

  const onItemClicked = (itemHandler: Function) => {
    itemHandler(currentDatum);

    props.closeMenuHandler();
  };

  return (
    <StyledContextMenu style={{ top: yPos, left: xPos }} ref={menuEl}>
      {options.map((option, index) => {
        const disabled = option.filter && !option.filter(currentDatum);

        return (
          <li
            key={index}
            className={classNames({
              disabled,
            })}
          >
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                return !disabled && onItemClicked(option.handler);
              }}
            >
              {option.icon ? option.icon : <Icon name="fa-square" />} {option.name}
            </a>
          </li>
        );
      })}
    </StyledContextMenu>
  );
}
