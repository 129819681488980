import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledKabobMenu = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5px;
  position: relative;
  z-index: 3;

  .KabobMenu--kabob-btn {
    box-shadow: none;
    padding: 0 8px !important;
    margin-bottom: 5px;

    svg {
      height: 20px;
      margin: 0;
      width: 20px;
    }

    &.no-background {
      background: transparent !important;
    }
  }

  .KabobMenu--menu {
    border-radius: 2px;
    display: inline-block;
    min-width: 150px;
    position: absolute;
    top: 40px;
    z-index: 4;

    .menu-item {
      height: 36px;

      .menu-item-btn,
      .menu-item-btn:disabled {
        background: transparent !important;
        box-shadow: none;
        color: ${COLORS.white};
        margin: 0;
        width: 100%;
      }

      &:first-child {
        border-radius: 2px 2px 0 0;
      }

      &:last-child {
        border-radius: 0 0 2px 2px;
      }

      .menu-item-btn:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .KabobMenu--kabob-btn,
  .KabobMenu--kabob-btn:focus,
  .KabobMenu--menu,
  .KabobMenu--menu .menu-item.disabled {
    background: ${COLORS.blueBayoux};
    color: ${COLORS.white};
  }

  .KabobMenu--kabob-btn:hover,
  .KabobMenu--menu .menu-item:hover {
    background: ${COLORS.comet};
  }

  &.left-aligned {
    flex-direction: row;
  }
`;
