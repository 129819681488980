import styled from 'styled-components';

const transitionDuration = '200ms';

export const StyledEditableField = styled.span`
  &:hover .pencil-icon {
    opacity: 1;
  }

  [role='textbox'] > * {
    display: inline-block;
  }

  .pencil-icon {
    margin-left: 0.25em;
    margin-right: 3px;
    opacity: 0;

    transition: opacity ${transitionDuration};
  }
`;
