import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledSectionHeader } from './SectionHeader.styles';

type Props = PermafrostComponent & {
  style?: object;
  children?: React.ReactNode;
};

export const SectionHeader = (props: Props) => {
  const { className = '', style = {}, children, id, ...restOfProps } = props;

  return (
    <StyledSectionHeader
      className={classNames('SectionHeader', className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      {...restOfProps}
    >
      {children}
    </StyledSectionHeader>
  );
};