import styled from 'styled-components';

export const StyledToastsList = styled.div`
  & > :nth-of-type(0) {
    margin-top: -55px;
  }

  & > :nth-of-type(1) {
    margin-top: 0px;
  }

  & > :nth-of-type(2) {
    margin-top: 55px;
  }

  & > :nth-of-type(3) {
    margin-top: 110px;
  }

  & > :nth-of-type(4) {
    margin-top: 165px;
  }

  & > :nth-of-type(5) {
    margin-top: 220px;
  }
`;
