import React, { useState } from 'react';

import classNames from 'classnames';

import { clipboardUtils, stringUtils } from '@indico-data/utils';

import { Button, Icon, Tooltip } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledCopyableFilename } from './CopyableFilename.styles';

type Props = PermafrostComponent & {
  filename: string;
  maxNameLength?: number;
};

export function CopyableFilename(props: Props) {
  const { className, filename, id, maxNameLength = 50 } = props;
  const [displayCopySuccess, setDisplayCopySuccess] = useState(false);

  const copyHandler = () => {
    clipboardUtils.copy(filename);
    setDisplayCopySuccess(true);
    setTimeout(() => {
      setDisplayCopySuccess(false);
    }, 2000);
  };

  return (
    <StyledCopyableFilename className={className} data-cy={props['data-cy']}>
      {filename.length > maxNameLength! ? (
        <>
          <Tooltip questionMark={false} for="shortened-name">
            <p>{filename}</p>
          </Tooltip>
          <span data-tip data-for="shortened-name" id={id}>
            {stringUtils.maxLengthWithMiddleEllipsis(filename, maxNameLength!)}
          </span>
        </>
      ) : (
        <span id={id}>{filename}</span>
      )}

      <Button variant="link-style" onClick={() => copyHandler()} data-for="copy-filename" data-tip>
        <Icon
          className={classNames({
            hidden: displayCopySuccess === true,
          })}
          name="fa-clipboard"
          ariaLabel="copy filename"
          size={['16px']}
        />
        <Icon
          className={classNames('copy-success', {
            hidden: displayCopySuccess === false,
          })}
          name="check"
          size={['16px']}
        />
      </Button>
      <Tooltip
        className="copy-success__tooltip"
        questionMark={false}
        place={'bottom'}
        for="copy-filename"
      >
        {displayCopySuccess ? 'copied!' : 'copy filename'}
      </Tooltip>
    </StyledCopyableFilename>
  );
}
