export const LOGIN_SUCCESS = 'You are now logged into your indico account.';
export const LOGIN_FAILED = 'Invalid Email or Password';
export const LOGIN_ERROR = 'An error occurred while attempting to log you in. Please try again.';
export const LOGIN_ERROR_CONFIRMATION_REQUIRED =
  'There was an error attempting to access your account. Please make sure your account is active by using the link in the registration email.';
export const LOGOUT_SUCCESS = 'You are now logged out from your indico account.';
export const LOGOUT_FAILED = 'We were unable to complete the log out';
export const PASSWORD_FORGOT_SUCCESS =
  'Success! Check your email to complete resetting your password.';
export const PASSWORD_FORGOT_FAILED = 'Sorry, We were unable to find that account.';
export const PASSWORD_RESET_SUCCESS = 'Your password has been updated.';
export const PASSWORD_RESET_EXPIRED =
  'Your password reset link has expired. You will need to request a new link.';
export const ACCOUNT_ACTIVATION_EXPIRED =
  'Your account activation link has expired. You will need register again.';
export const PASSWORD_RESET_FAILED = 'Unable to reset your password.';
export const REGISTRATION_SUCCESS =
  'Congrats! Your indico account is almost good to go. Just check your email for your confirmation email.';
export const DUPLICATE_REGISTRATION = 'An account with this email address already exists.';
export const REGISTRATION_FAILED =
  'An error occurred while attempting to register your account. Please try again.';
export const ACCOUNT_LOCKED =
  'You have been locked out of your account due to unsuccessful login attempts. Please contact your site administrator or wait until your lockout expires.';
export const SESSION_LIMIT =
  'You currently have another session open. Please log out before attempting to log in.';
export const BLACKLIST_EMAIL = 'Business email address is required.';
