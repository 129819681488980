const MESSAGES = [
    'Charging the flux capacitor',
    'Spinning up the hamster wheel',
    'Flushing the pipes',
    'Counting all the beans',
    'Take a deep breath and enjoy this beautiful moment',
    'Counting digits in pi (almost done)',
    'Folding time and space',
    'Oiling the gears',
    'Peeling the data fruits',
    'Activating the oscillation overthruster',
    'Gleaming the cube',
    'Reticulating splines',
    'Playing a quick game of squash',
    'Making the sausage',
    'Building the matrix',
    'Drawing fractals',
    'Testing memory (what did you have for breakfast?)',
    'Priming data pumps',
    'Whacking datamoles',
    'Finding Nemo',
    'Running spin cycle',
    'Chasing down a dream',
    'Twiddling the knobs',
    'Assembling lines',
    'Making the donuts',
    'Gathering magic',
    'Rolling stones',
    'Busting all the moves',
    'Augmenting reality',
    'Leveling up',
    'Obviating frequencies',
    'Declaring variables',
    'Boosting thrusters',
    'Blazing the saddles',
    'Flipping some bits',
    'Unclogging the internet',
    'Turning it off and back on again',
    'Entering the ThunderDome',
    'Rolling logs',
    'Dangling particles',
    'Catching moonbeams',
    'Digging for treasure',
    'Reconbulating the conbulator',
    'Machine learning',
    'Magicing',
    'Tuning the forks',
    'Fiddling widgets',
    'Feeding the data monkeys',
    'Regularizing expressions',
    'Ramping up the synergy',
    'Inflating point flotations',
    'Entering orbit around alpha centuri',
    'Squeezing data tubes',
    'Making a list and checking it twice',
    'Unkinking the data tubes',
    'Turning on the data hose',
    'Preparing for liftoff',
    'Replacing the hamsters',
    'Changing the batteries',
    'Waking up our engineers',
    'Contemplating the meaning of life',
    'Downloading fresh dog memes',
    'Springing the chickens'
];

export default MESSAGES;
