import { createGlobalStyle } from 'styled-components';

export const Lists = createGlobalStyle`
  ul,
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  dl {
    margin: 0;
  }

  dt {
    font-weight: 600;
    margin: 0;
  }

  dd {
    margin: 0;
  }
`;
