// todo
//  intent: elsewhere in Permafrost/IPA, this object should only be referenced directly if
//  a global property is being redeclared. If a color value is needed for a CSS rule, a property
//  should be used.
//  my initial thought: a naming convention that associates each “content” color variant with
//  the background color it’s meant to appear on

const brandingColors = {
  cyan: '#0cf',
  green: '#6c4',
  orange: '#fa3',
  indicoBlue: '#0070f5',
};

// master list of all colors in use
export const currentColors = {
  ...brandingColors,
  // todo: examples; not in use
  indicoBlueApp: '#238aff', // *App == closest AA color for this background
  indicoBlueCard: '#469cff', // etc.
  indicoBlueMenu: '#0f7fff',
  indicoBlueWhite: '#0070f0', // *White == paired with pure white (this could be better-named...)
} as const;
