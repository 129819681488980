import styled from 'styled-components';

const commonSpace = '10px';

export const StyledCopyableFilename = styled.div`
  display: flex;
  align-items: center;
  height: 38px; // Keep height the same as file select that replaces this component when more than 1 file included

  span {
    margin-right: ${commonSpace};
  }

  .hidden {
    display: none;
  }

  svg {
    margin-bottom: -1px;
  }

  .copy-success {
    color: green;
  }
`;
