import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

// todo: how to handle/inform user when length is too short?

import classNames from 'classnames';

import { stringUtils } from '@indico-data/utils';

import { Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledEditableField } from './EditableField.styles';

type Props = PermafrostComponent & {
  children: React.ReactNode | string;
  minLength?: number;
  onUpdate?(newValue: string): void;
};

/**
 * Wrapper component which enables text editing in place directly on a child component,
 * element, or plain text.
 */
export function EditableField(props: Props) {
  const { children, className, minLength = 3, onUpdate } = props;

  const [initialValue, setInitialValue] = useState('');

  // used as unique css class name
  const { current: id } = useRef(stringUtils.createRandomString());

  const editableEl = useRef() as MutableRefObject<HTMLSpanElement>;

  const handleBlur = () => {
    const node = editableEl.current.children[0] as HTMLElement;
    const currentValue = node.innerText || editableEl.current.innerText;

    if (minLength && currentValue.length < minLength) {
      // revert to last good value

      if (node) {
        node.textContent = initialValue;
      } else {
        editableEl.current.textContent = initialValue;
      }
    } else if (currentValue !== initialValue) {
      // allow updated value
      setInitialValue(currentValue);

      if (onUpdate) onUpdate(currentValue);
    }
  };

  const handleKeypress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      editableEl.current.blur();
    }
  };

  // Records the initial value for the text, in order to check length and compare to current text.
  // Also ensures the size of the icon is relative to the text inside the editable element (which
  // is the icon’s *sibling*, not ancestor).
  useEffect(() => {
    const style = document.createElement('style');
    const node = editableEl.current.children[0] as HTMLElement;
    document.head.appendChild(style);

    if (node) {
      const { fontSize } = getComputedStyle(node);

      style?.sheet?.insertRule(`.${id} .pencil-icon { font-size: calc(${fontSize} * 0.8) }`);

      setInitialValue(node.innerText);
    } else {
      style?.sheet?.insertRule(`.${id} .pencil-icon { font-size: 0.8em }`);
      setInitialValue(editableEl.current.innerText);
    }
  }, []);

  return (
    <StyledEditableField
      className={classNames('EditableField-container', id, className)}
      data-cy={props['data-cy']}
      id={props.id}
    >
      <span
        role="textbox"
        tabIndex={0}
        contentEditable
        suppressContentEditableWarning={true}
        ref={editableEl}
        onKeyDown={handleKeypress}
        onBlur={handleBlur}
      >
        {children}
      </span>

      <Icon name="fa-pencil-alt" className="pencil-icon" />
    </StyledEditableField>
  );
}
