import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { captureException } from '@sentry/browser';

// App
import { ToastsList } from 'root/components/messaging/ToastsList/ToastsList';
import { MainRouterWithToast as MainRouter } from './MainRouter';

class Router extends Component {
  state = {
    error: null,
    loginError: false,
    redirect: {
      pathname: '',
      search: '',
    },
  };

  componentDidMount() {
    this.props
      .verifyUser()
      .then((user) => {
        if (user) {
          this.props.updateUserContext({ type: 'SET_USER', user, loading: false });
        }
      })
      .catch((err) => {
        this.props.updateUserContext({ type: 'SET_LOADING', loading: false });
        if (err.status !== 401) {
          this.setState({ loginError: true });
        }
      });
  }

  componentDidCatch(error) {
    this.setState({ error });
    captureException(error);
  }

  render() {
    return (
      <BrowserRouter>
        <div className="MainRouter" style={{ height: '100%' }}>
          <ToastsList>
            <MainRouter error={this.state.error} loginError={this.state.loginError} />
          </ToastsList>
        </div>
      </BrowserRouter>
    );
  }
}

export const MainRouterWrapper = Router;
