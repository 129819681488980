import React, { useEffect, useState } from 'react';

import { Pagination } from '../Pagination/Pagination';

type Props = {
  limit: number; // Limit of values returned on a particular page
  resetPaginationOnChange?: any[]; // Accepts any values you want to monitor for changes and reset pagination state
  values: any[]; // Master list of values - total items that need to be paginated, any sorting or filtering should be done before being passed as this prop
  onChange: (updated: any[]) => void; // Returns the paginated subset of values - will return the subset on render & anytime the current page is changed or master set of values is updated
};

export const PaginationWithoutQuery = ({
  limit,
  resetPaginationOnChange,
  values,
  onChange,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState({ start: 0, end: limit });
  const [paginatedValuesLength, setPaginatedValuesLength] = useState(0);

  // Passes back the updated paginated list when currentPage or master values is changed, also sets length of current paginated list to render correct controls
  useEffect(() => {
    const updatedPaginatedValues = values?.slice(cursors.start, cursors.end);
    setPaginatedValuesLength(updatedPaginatedValues?.length);
    onChange(updatedPaginatedValues);
  }, [cursors, currentPage, values]);

  // Resets pagination to page1 when resetPaginationOnChange is updated - intended to be used for filters/sorting on the master list
  useEffect(() => {
    if (!resetPaginationOnChange?.length) {
      return;
    }
    setCurrentPage(1);
    setCursors({ start: 0, end: limit });
  }, resetPaginationOnChange);

  return (
    <Pagination
      currentPage={currentPage}
      limit={limit}
      pageInfo={{
        aggregateCount: values?.length,
        endCursor: cursors.end,
        hasNextPage: cursors.end < values?.length,
        startCursor: cursors.start,
      }}
      quantity={paginatedValuesLength}
      getPage={(startCursor: number, endCursor: number) => {
        setCurrentPage(currentPage + endCursor);
        setCursors({
          start: startCursor + limit * (endCursor - 1),
          end: startCursor + limit * endCursor,
        });
      }}
      getNextPage={(cursor: number) => {
        setCurrentPage(currentPage + 1);
        setCursors({ start: cursor, end: cursor + limit });
      }}
      getPreviousPage={(cursor: number) => {
        setCurrentPage(currentPage - 1);
        setCursors({ start: cursor - limit, end: cursor });
      }}
    />
  );
};
